<template>
    <div>
        <Button
            type="button"
            label="Spresnit parametre"
            icon="fas fa-filter"
            class="w-full mb-2"
            @click="toggleShow()"
            v-if="isMobile" />

        <Card id="filter" :pt="{ body: { class: 'p-2' } }" v-if="isVisible">
            <template #content>
                <div v-if="!isLoading">
                    <form id="product-filter">
                        <div>
                            <Fieldset :legend="$t('word.price')" id="filter-price">
                                <vue-slider
                                    ref="slider"
                                    v-model="price"
                                    v-bind="priceOptions"
                                    :tooltip-formatter="'{value} €'"
                                    :dot-style="dotStyle"
                                    :rail-style="railStyle"
                                    :process-style="processStyle"
                                    :tooltip-style="tooltipStyle"
                                    @drag-end="changeFilter"
                                    class="px-2 pt-6">
                                </vue-slider>
                            </Fieldset>

                            <Fieldset v-for="(attribute, attributeKey) in attributes" :legend="attribute.label" :toggleable="true">
                                <div v-for="(option, optionKey) in attribute.options"
                                     class="filter-tags uk-grid uk-grid-small uk-child-width-1 uk-grid-stack uk-margin"
                                     uk-grid>
                                    <label>
                                        <input type="checkbox"
                                               :value="optionKey"
                                               v-model="values[attributeKey][optionKey]"
                                               @change="changeFilter"
                                               class="uk-checkbox uk-checkbox-danger">
                                        {{ option }}
                                        <div class="color" :style="labelStyle(attributeKey, optionKey)"></div>
                                    </label>
                                </div>
                            </Fieldset>
                        </div>
                    </form>
                </div>
                <div class="text-center" v-else>
                    <Fieldset legend="Cena">
                        <Skeleton height="3rem" class="mb-2"></Skeleton>
                    </Fieldset>

                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>

                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>

                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>
                </div>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button :label="$t('action.cancel')" size="small" @click.prevent="reset" severity="secondary" outlined class="grow-0" />
                    <Button :label="$t('action.filter')" size="small" @click.prevent="filter" class="grow" />
                </div>
            </template>
        </Card>

<!--        <div class="filters-rules uk-card uk-card-default uk-card-body uk-card-body-small uk-margin-top"-->
<!--             v-if="isFiltered">-->
<!--            <strong>{{ $t('word.filters') }}:</strong>-->

<!--            <span v-for="(value, key) in values"-->
<!--                  v-if="activeAttributeLabel(value, key)"-->
<!--                  uk-toggle="target: #filter, #filter-show; cls: ui-active"-->
<!--                  class="uk-badge uk-badge-normal uk-text-capitalize uk-margin-right"-->
<!--                  style="cursor: pointer;">-->
<!--                {{ activeAttributeLabel(value, key) }}-->
<!--            </span>-->

<!--            <Button :label="$t('action.clear')" :size="'small'" @submit.prevent="clear" />-->
<!--        </div>-->
    </div>
</template>

<script>
import { toRaw } from 'vue';
import eventBus from '../eventBus';
import _ from 'lodash';
import VueSlider from 'vue-slider-component'
import 'vue-slider-component/theme/antd.css'
import {RepositoryFactory} from "../repository/RepositoryFactory";
import MobileDetect from "mobile-detect";

const ProductRepository = RepositoryFactory.get('product');

export default {
    components: {
        VueSlider
    },

    props: ['category-id', 'category-slug'],

    computed: {
        isMobile: function () {
            let md = new MobileDetect(window.navigator.userAgent);
            return md.isPhoneSized();
        },
    },

    data() {
        return {
            isVisible: false,

            isFiltered: false,

            choices: {},
            isLoading: false,

            values: {},
            count: 0,

            price: [0, 0],
            priceMin: 0,
            priceMax: 0,

            attributes: {},
            attributeActive: null,

            priceOptions: {
                tooltip: 'always',
            },

            dotStyle: {
                "background-color": "#ed5565",
                "border-color": "#802f38"
            },
            railStyle: {"background-color": "#b2b2b2"},
            processStyle: {"background-color": "#ed5565"},
            tooltipStyle: {"background-color": "#ed5565"},
        }
    },

    mounted() {
        this.isVisible = !this.isMobile;
        this.fetchFilterValues();
    },

    methods: {
        fetchFilterValues: async function () {
            this.isLoading = true;

            const {data} = await ProductRepository.filterChoices(this.categoryId);
            this.choices = data;

            this.loadFilter();

            this.isLoading = false;
        },

        loadFilter: function () {
            if (_.isUndefined(this.choices.price)) {
                return;
            }

            this.price = this.choices.price.options;
            this.priceMin = this.price[0];
            this.priceMax = this.price[1];
            this.priceOptions.min = this.choices.price.options[0];
            this.priceOptions.max = this.choices.price.options[1];
            this.attributes = _.omit(this.choices, ['price']);
            this.attributeActive = _.findKey(this.attributes);

            this.reset();

            let url = _.split((new URL(window.location.href)).pathname, '/');

            _.forEach(_.split(url[3], ';'), (attributes) => {
                if (attributes === "") {
                    return;
                }

                this.isFiltered = true;

                let attribute = _.split(attributes, ':');

                if (attribute[0] === 'price') {
                    this.price = _.split(attribute[1], '-');
                    this.priceMin = this.price[0];
                    this.priceMax = this.price[1];
                } else {
                    let options = {};

                    _.forEach(_.split(attribute[1], ','), (option) => {
                        if (option !== "") {
                            options[option] = true; // before: options[option] = option
                        }
                    });

                    if (!_.isEmpty(options)) {
                        this.values[attribute[0]] = options;
                    }
                }
            });

            // this.loadFilterCount();
        },

        changeFilter: function () {
            this.isFiltered = true;
            // this.loadFilterCount();
        },

        // loadFilterCount: async function () {
        //     let values = _.merge(_.omitBy(this.values, _.isEmpty), {'price': _.join(this.price, '-')});
        //
        //     const {data} = await ProductRepository.filterCount(this.categoryId, values);
        //     this.count = data.count;
        // },

        generateUrl: function () {
            return _.join(_.map(_.omitBy(this.values, _.isEmpty), (options, attribute) => attribute + ":" + (_.isObject(options) ? _.join(_.keys(_.pickBy(options, (option) => option)), ',') : options)), ';');
        },

        filter: function () {
            eventBus.productFilterCriteria = _.omitBy(this.values, _.isEmpty);
        },

        activeAttributeLabel: function (attribute, attributeKey) {
            if (attributeKey !== 'price' && _.isEmpty(attribute)) {
                return false;
            }

            let options;

            if (attributeKey === 'price') {
                if (this.price[0] === 0 && this.price[1] === 0) {
                    return false;
                }

                options = _.join(this.price, '-');
            } else {
                options = _.join(_.map(_.keys(_.pickBy(attribute, (option) => option)), (option) => this.optionLabelText(attributeKey, option)), ', ');
            }

            if (_.isNil(options) || options === "") {
                return false;
            }

            return `${this.attributeLabelText(attributeKey)}: ${options}`;
        },

        reset: function () {
            _.forEach(this.attributes, (attribute, key) => {
                this.values[key] = {};
            });

            this.isFiltered = false;
            this.price = [this.priceMin, this.priceMax];
        },

        clear: function () {
            window.location.href = `/${this.categorySlug}`;
        },

        attributeLabelText: function (attributeKey) {
            if (attributeKey === 'price') {
                return "Cena";
            }

            if (!_.has(this.attributes[attributeKey], 'label')) {
                return;
            }

            return this.attributes[attributeKey].label;
        },

        optionLabelText: function (attributeKey, optionKey) {
            return this.attributes[attributeKey].options[optionKey];
        },

        labelStyle: function (attributeKey, optionKey) {
            if (_.isEmpty(this.attributes[attributeKey].styles)) {
                return;
            }

            return this.attributes[attributeKey].styles[optionKey];
        },

        toggleShow: function () {
            this.isVisible = !this.isVisible;
        }
    }
}
</script>

<style scoped>

</style>