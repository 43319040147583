<template>
    <DataTable :value="purchases" tableStyle="min-width: 50rem" :lazy="true" :loading="isLoading">
        <Column field="hash" header="Objednávka">
            <template #body="slotProps">
                <a :href="'/sledovanie-zasielky/' + slotProps.data.hash">
                    <Button :label="slotProps.data.id" />
                </a>
            </template>
        </Column>
        <Column field="purchase_created_at" header="Dátum">
            <template #body="slotProps">
                {{ moment(slotProps.data.purchase_created_at).format("DD.MM.YYYY") }}
            </template>
        </Column>
        <Column field="total" header="Cena" bodyStyle="text-align: right;">
            <template #body="slotProps">
                {{ parseFloat(slotProps.data.total).toFixed(slotProps.data.currency.round) }} {{ slotProps.data.currency.symbol }}
            </template>
        </Column>
        <Column field="old_status" header="Stav"></Column>
        <Column field="invoices" header="Faktúra">
            <template #body="slotProps">
                <Button :label="invoice.number" v-for="invoice in slotProps.data.invoices" @click="downloadInvoice(invoice)" />
            </template>
        </Column>
        <Column field="images" header="Položky">
            <template #body="slotProps">
                <img class="purchase-item-content-image"
                     style="width: 24px; height: 24px;"
                     :src="product.image.url"
                     v-for="product in slotProps.data.unique_products.slice(0,5)">
            </template>
        </Column>
    </DataTable>
</template>

<script setup>
import {onMounted, ref} from "vue";
import PurchaseRepository from "../../repository/front/purchaseRepository";
import moment from "moment/moment";

const purchases = ref();
const isLoading = ref(false);

const fetchPurchases = async () => {
    isLoading.value = true;
    const {data} = await PurchaseRepository.findAll();
    purchases.value = data;
    isLoading.value = false;
};

const downloadInvoice = (invoice) => {
    window.open(`/moje/faktury/${invoice.number}`, '_blank');
};

onMounted(async () => {
    fetchPurchases();
});
</script>

<style scoped>

</style>