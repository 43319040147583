<template>
    <tr id="price-shipping" class="text-success" style="font-size:18px">
        <td class="text-right" colspan="6">
            {{ $t('word.shippingPriceWithVat') }}
        </td>
        <td class="text-right" style="width: 100px" id="cart-delivery-price">
            {{ parseFloat(shippingPrice).toFixed(currencyRound) }} {{ currencySymbol }}
        </td>
    </tr>
</template>

<script>
import {mapActions, mapGetters} from 'vuex'

export default {
    computed: {
        ...mapGetters([
            'shippingPrice',
            'currencySymbol',
            'currencyRound'
        ])
    }
}
</script>

<style>

</style>