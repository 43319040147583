<template>
    <div>
        <InputSwitch v-model="checked" />
    </div>
</template>

<script setup>
import { ref, watch, onMounted } from 'vue';
import { usePrimeVue } from 'primevue/config';

const PrimeVue = usePrimeVue();

const checked = ref(false);

onMounted(() => {
    const storedChecked = localStorage.getItem('checked');
    if (storedChecked !== null) {
        checked.value = JSON.parse(storedChecked);
    }
});

const options = ref([
    { icon: 'fas fa-moon', value: 'dark' },
    { icon: 'fas fa-sun', value: 'light' },
]);

watch(checked, (val) => {
    localStorage.setItem('checked', JSON.stringify(val));
    let themes = ['aura-light-pink', 'aura-dark-pink'];
    PrimeVue.changeTheme(themes[val?0:1], themes[val?1:0], 'theme-link', () => {});
});
</script>