<template>
    <div class="flex justify-center">
        <Button label="Zobraz si obsah sady, klikni tu!" size="large" @click="visible = true" />
    </div>

    <Dialog v-model:visible="visible" modal header="Sada obsahuje" style="width: 90vw; height: 90vh;">
        <app-product-list :product-parent-id="productId" :paginator="false"></app-product-list>
    </Dialog>
</template>

<script setup>
import { onMounted, ref } from "vue";

const props = defineProps(['productId']);
const visible = ref(false);

onMounted(async () => {

});
</script>

<style scoped>

</style>