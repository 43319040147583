<template>
    <div>
        <Card class="flex flex-col p-2 h-full" :pt="{ body: { class: 'flex flex-col grow py-1 px-0' }, title: { class: 'text-primary' }, footer: { class: 'flex grow flex-col justify-end' } }">
            <template #header>
                <a :href="getLink(item)">
                    <img :alt="item.title" :src="item.image" class="w-full" />
                </a>
            </template>
            <template #title>
                <a :href="getLink(item)" class="block text-primary no-underline text-left">{{ item.title }}</a></template>
            <template #content>
                <div class="line-clamp-4" v-html="item.short"></div>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1 content-end text-left">
                    <a :href="getLink(item)" class="flex-1">
                        <Button label="Čítaj viac" class="w-full" outlined />
                    </a>

                    <app-favorite-blog :blog-id="item.id" style="max-height: 42px"></app-favorite-blog>
                </div>
            </template>
        </Card>
    </div>
</template>

<script setup>
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";
import {onMounted, ref, watch} from "vue";
import AppFavoriteBlog from "./AppFavoriteBlog.vue";
import eventBus from "../../eventBus";
import _ from "lodash";

const BlogRepository = RepositoryFactory.get('blog');

const props = defineProps(['item', 'selected', 'setting', 'favorite', 'zoom']);

const getLink = (item) => {
    if(_.isNil(item.category)) {
        return '';
    }

    return `/navody-inspiracie/${item.category.slug}/${item.slug}`;
};
</script>