<template>
    <Card class="w-full md:w-30rem">
        <template #title v-if="name">{{ name }}</template>
        <template #content>
            <p>{{ description }}</p>

            <FloatLabel class="my-5">
                <InputText id="claimPurchase" class="w-full" v-model="purchase" />
                <label for="claimPurchase">{{ $t('claim.form.purchase') }}</label>
            </FloatLabel>

            <FloatLabel class="my-5 w-full">
                <InputText id="claimDocumentNumber" class="w-full" v-model="documentNumber" />
                <label for="claimDocumentNumber">{{ $t('claim.form.documentNumber') }}</label>
            </FloatLabel>

            <FloatLabel class="my-5 w-full">
                <Textarea id="claimNote" class="w-full" v-model="note" />
                <label for="claimNote">{{ $t('form.note') }}</label>
            </FloatLabel>
        </template>
        <template #footer>
            <Button :label="$t('action.send')" @click="send" :disabled="isLoading" />
        </template>
    </Card>


    <Dialog v-model:visible="visible" modal :style="{ width: '25rem' }">
        <p>{{ message }}</p>

        <div class="flex justify-content-end gap-2">
            <Button type="button" label="Zavrieť" severity="secondary" @click="visible = false"></Button>
        </div>
    </Dialog>
</template>

<script>
import {RepositoryFactory} from "../repository/RepositoryFactory";
import UIkit from "uikit";

const ClaimRepository = RepositoryFactory.get('claim');

export default {
    name: "ClaimForm",

    props: ["type"],

    data() {
        return {
            isLoading: false,
            purchase: null,
            documentNumber: null,
            note: null,
            message: null,

            name: null,
            description: null,
            success: null,
            fail: null,
            visible: false,
        }
    },

    methods: {
        fetchType: async function () {
            this.isLoading = true;

            const {data} = await ClaimRepository.getType(this.type);
            this.name = data.name;
            this.description = data.description;
            this.success = data.success;
            this.fail = data.fail;

            this.isLoading = false;
        },

        send: async function () {
            this.isLoading = true;

            try {
                const {data} = await ClaimRepository.create(this.purchase, this.documentNumber, this.note, this.type);
                this.message = data.message || this.success;

                this.purchase = null;
                this.documentNumber = null;
                this.note = null;
            } catch (e) {
                this.message = e?.response?.data?.detail || this.fail;
            }

            this.visible = true;

            this.isLoading = false;
        },
    },

    mounted() {
        this.fetchType();
    }
}
</script>

<style scoped>

</style>