import CategoryRepository from "./categoryRepository";
import ProductRepository from "./productRepository";
import SecurityRepository from "./securityRepository";
import BannerRepository from "./bannerRepository";
import BlogRepository from "./blogRepository";
import FavoriteRepository from "./favoriteRepository";
import ConsumerRepository from "./consumerRepository";
import ReviewRepository from "./reviewRepository";
import VideoRepository from "./videoRepository";
import VoteRepository from "./voteRepository";

const repositories = {
    category: CategoryRepository,
    product: ProductRepository,
    security: SecurityRepository,
    banner: BannerRepository,
    blog: BlogRepository,
    favorite: FavoriteRepository,
    consumer: ConsumerRepository,
    review: ReviewRepository,
    video: VideoRepository,
    vote: VoteRepository,
};

export const RepositoryFactory = {
    get: name => repositories[name]
};