import _ from 'lodash';

export const isMobile = (store) => {
    return store.isMobile;
};

export const cartQuantity = (store) => {
    return _.reduce(store.cart.items, function(sum, n) {
        return sum + n.quantity;
    }, 0);
};

export const cartSubtotal = (store) => {
    return _.reduce(store.cart.items, function(sum, n) {
        return sum + (n.quantity * n.price_active);
    }, 0);
};

export const cartAlignment = (store) => {
    let price = cartSubtotal(store) + servicePrice(store) + shippingPrice(store);
    return (Math.round(price / 0.05) * 0.05) - price;
    // return ((Math.round(price / 0.05) * 0.05) - price).toFixed(2);
};

export const cartTotal = (store) => {
    return cartSubtotal(store) + servicePrice(store) + shippingPrice(store) + cartAlignment(store);
};

export const findByProductId = (store) => (productId) => {
    return _.find(store.cart.items, function(o) {
        return o.product.id == productId;
    });
};

export const findById = (store) => (id) => {
    return _.find(store.cart.items, function(o) {
        return o.id == id;
    });
};

export const cartItems = (store) => {
    return _.filter(store.cart.items, (item) => item.quantity > 0);
};

export const cartServices = (store) => {
    return store.cart.services;
};

export const shippingCompany = (store) => {
    return store.shipping.company;
};

export const shippingPayment = (store) => {
    return store.shipping.payment;
};

export const shippingItems = (store) => {
    let f = [];

    let items = _.filter(store.shipping.items, function(n) {
        return _.indexOf(f, n.company) === -1 && f.push(n.company)
    });

    return _.orderBy(items, ['sort'], ['asc']);
};

// export const findShippingItemById = (store) => (productId) => {
//     return _.find(shippingItems(), function(o) {
//         return o.product_id == productId;
//     });
// };

export const shippingFreeItems = (store) => {
    return _.filter(store.shipping.items, function(o) {
        return o.price === 0;
    })
};

export const companyItems = (store) => {
    return _.filter(store.shipping.companies, function(c) {
        let shipping = _.find(store.shipping.items, function(s) {
            return s.company === c.id
                && s.amountMin <= cartTotal(store)
                && s.amountMax >= cartTotal(store)
        });

        return !_.isUndefined(shipping);
    });
};

export const paymentItems = (store) => {
    // return _.sortBy(
        return _.filter(store.shipping.payments, function(p) {
            let shipping = _.find(store.shipping.items, function(s) {
                return s.payment === p.id
                    && s.amountMin <= cartTotal(store)
                    && s.amountMax >= cartTotal(store)
            });

            return !_.isUndefined(shipping);
        });
        //     , function(o) {
        //
        // });
};

export const giftItems = (store) => {
    return store.gift.items;
};

export const motivations = (store) => {
    return store.motivations;
};

export const shippingPrice = (store) => {
    let shippingItem = _.sortBy(store.shipping.items, ['sort'], ['asc']);

    shippingItem = _.find(shippingItem, function(item) {
        return item.company === store.shipping.company
            && item.payment === store.shipping.payment
            && item.amountMin <= cartSubtotal(store)
            && item.amountMax > cartSubtotal(store);
    });

    return parseFloat(shippingItem ? shippingItem.price : 0);
};

export const servicePrice = (store) => {
    return _.sumBy(store.cart.services, 'price');
};

export const cartAnimated = (store) => {
    return store.cart.animated;
};

export const cartUpdating = (store) => {
    return store.cart.updating;
};

export const currency = (store) => {
    return store.currency;
};

export const currencySymbol = (store) => {
    return store.currency.symbol;
};

export const currencyRound = (store) => {
    return store.currency.round;
};