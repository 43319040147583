<template>
    <Breadcrumb :home="home" :model="items" style="border: 0; border-radius: 0;" />
</template>

<script setup>
import {onMounted, ref} from "vue";
import CategoryRepository from "../../repository/front/categoryRepository";

const props = defineProps(['categoryId']);

const home = ref({
    icon: 'fas fa-home',
    url: '/',
});

const items = ref([]);
const loading = ref(true);

const transformItem = (item) => {
    return {...item, label: item.name, url: item.path};
};

onMounted(async () => {
    const {data} = await CategoryRepository.breadcrumb(props.categoryId);
    items.value = data.map(transformItem);
    loading.value = false;
});
</script>