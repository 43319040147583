<script>
import { defineComponent, onMounted, onUnmounted, ref } from 'vue';
import { RepositoryFactory } from "../../repository/front/RepositoryFactory";

const VideoRepository = RepositoryFactory.get('video');

export default defineComponent({
    name: "AppVideo",

    props: {
        videoId: [String, null],
        videoUrl: [String, null],
        videoProductId: [String, null]
    },

    setup(props) {
        const player = ref(null);
        const elPlayer = ref(null);
        const chapterActive = ref(null);
        const intervalId = ref(null);
        const chapters = ref([]);
        const isLoading = ref(false);

        const loadYouTubeAPI = () => {
            return new Promise((resolve) => {
                if (window.YT && window.YT.Player) {
                    resolve(window.YT);
                    return;
                }

                const tag = document.createElement('script');
                tag.src = "https://www.youtube.com/iframe_api";
                const firstScriptTag = document.getElementsByTagName('script')[0];
                firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

                window.onYouTubeIframeAPIReady = () => {
                    resolve(window.YT);
                };
            });
        };

        const createPlayer = (YT) => {
            if (!elPlayer.value) return;

            isLoading.value = true;

            player.value = new YT.Player(elPlayer.value, {
                height: '100%',
                width: '100%',
                videoId: getYoutubeVideoId(props.videoUrl),
                events: {
                    'onReady': () => {
                        isLoading.value = false;
                        startTracking();
                    },
                }
            });
        };

        const getCurrentTime = () => {
            return player.value ? player.value.getCurrentTime() : 0;
        };

        const updateActiveChapter = () => {
            const currentTime = getCurrentTime();
            let activeChapter = null;

            for (let i = chapters.value.length - 1; i >= 0; i--) {
                if (currentTime >= chapters.value[i].time) {
                    activeChapter = chapters.value[i].id;
                    break;
                }
            }

            chapterActive.value = activeChapter;
        };

        const startTracking = () => {
            if (intervalId.value) clearInterval(intervalId.value);
            intervalId.value = setInterval(updateActiveChapter, 1000);
        };

        const goToChapter = (time) => {
            if (player.value) {
                player.value.seekTo(time, true);
            }
        };

        const formatTime = (seconds) => {
            if (isNaN(seconds) || seconds < 0) {
                return "Invalid input";
            }

            const mins = Math.floor(seconds / 60);
            const secs = seconds % 60;

            return [mins, secs]
                .map(unit => String(unit).padStart(2, '0'))
                .join(':');
        }

        const getYoutubeVideoId = (url) => {
            let regExp = /^.*((youtu.be\/)|(v\/)|(\/u\/\w\/)|(embed\/)|(watch\?))\??v?=?([^#&?]*).*/;
            let match = url.match(regExp);
            return (match&&match[7].length==11)? match[7] : false;
        }

        const fetchChapters = async () => {
            const {data} = await VideoRepository.findChaptersByUrl(props.videoUrl);
            chapters.value = data;
        };


        onMounted(async () => {
            await fetchChapters();
            const YT = await loadYouTubeAPI();
            createPlayer(YT);
        });

        onUnmounted(() => {
            if (intervalId.value) clearInterval(intervalId.value);
        });

        return { elPlayer, chapters, chapterActive, goToChapter, formatTime, isLoading };
    }
});
</script>

<template>
    <div class="md:flex md:flex-row md:min-w-[438px]">
        <div class="md:basis-3/4" style="aspect-ratio: 2/1;">
            <div ref="elPlayer"></div>
        </div>

        <div class="md:basis-1/4">
            <ScrollPanel class="h-full">
                <template v-for="chapter in chapters" :key="chapter.id">
                    <Card class="m-2 md:mt-1 cursor-pointer transition-all duration-300 hover:bg-red-200"
                          :class="{ 'bg-red-100': chapterActive === chapter.id }"
                          @click="goToChapter(chapter.time)"
                          pt:body:class="py-1 px-2">
                        <template #title>{{ chapter.name }}</template>
                        <template #content>
                            <p class="m-0">
                                {{ chapter.description }}
                            </p>
                            <span class="text-xs bg-gray-200 rounded px-1">{{ formatTime(chapter.time) }}</span>
                        </template>
                    </Card>
                </template>
            </ScrollPanel>
        </div>
    </div>
</template>

<style scoped>
</style>
