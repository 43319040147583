<template>
    <form id="purchase-step-2" @submit.prevent v-focustrap>
        <div class="flex flex-col items-end py-3">
            <Inplace>
                <template #display>
                    <Button :label="$t('cart.action.law')" size="small" outlined />
                </template>
                <template #content>
                    <p class="m-0">{{ $t('cart.message.law') }}</p>
                </template>
            </Inplace>
        </div>

        <Fieldset legend="Nakupuješ na IČO?" class="mb-3" :toggleable="true" :collapsed="true">
            <div class="formgrid grid grid-cols-3 gap-8 pt-4">
                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="username" class="w-full" v-model="companyName" v-focus-next />
                        <label for="username">{{ $t('cart.form.label.companyName') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="username" class="w-full" v-model="companyNumber" v-focus-next />
                        <label for="username">{{ $t('cart.form.label.companyNumber') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="username" class="w-full" v-model="companyVat" v-focus-next />
                        <label for="username">{{ $t('cart.form.label.companyVat') }}</label>
                    </FloatLabel>
                </div>
            </div>
        </Fieldset>

        <Fieldset :legend="$t('cart.form.label.addressDelivery')" class="mb-3" :toggleable="true">
            <div class="formgrid grid grid-cols-3 gap-8 pt-4">
                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-firstName" ref="firstNameRef" class="w-full" v-model="firstName" :invalid="!isValidInput(firstName)" autofocus v-focus-next />
                        <label for="purchase-firstName">{{ $t('cart.form.label.firstName') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-lastName" class="w-full" v-model="lastName" :invalid="!isValidInput(lastName)" v-focus-next />
                        <label for="purchase-lastName">{{ $t('cart.form.label.lastName') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="purchase-street" class="w-full" v-model="street" :invalid="!isValidInput(street) || !containsLettersAndNumbers(street)" v-focus-next />
                        <label for="purchase-street">{{ $t('cart.form.label.street') }}</label>
                        <small v-if="isValidInput(street) && !containsLettersAndNumbers(street)" style="color: #f87171">{{ $t('cart.form.error.street') }}</small>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-2">
                    <FloatLabel>
                        <InputText id="purchase-town" class="w-full" v-model="town" :invalid="!isValidInput(town)" v-focus-next />
                        <label for="purchase-town">{{ $t('cart.form.label.town') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-zip" class="w-full" v-model="zip" :invalid="!isValidInput(zip)" v-focus-next />
                        <label for="purchase-zip">{{ $t('cart.form.label.zip') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <Select id="purchase-country" v-model="country" :options="countries" optionLabel="name" class="w-full" inputId="withoutgrouping" :useGrouping="false" :invalid="!isValidInput(country)" v-focus-next />
                        <label for="purchase-country">{{ $t('cart.form.label.country') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="purchase-contact" v-model="contact" class="w-full" inputId="withoutgrouping" :useGrouping="false" :invalid="!isValidInput(contact)" v-focus-next />
                        <label for="purchase-contact">{{ $t('cart.form.label.contact') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="purchase-email" v-model="email" class="w-full" inputId="withoutgrouping" :useGrouping="false" :invalid="!isValidInput(email)" v-focus-next />
                        <label for="purchase-email">{{ $t('cart.form.label.email') }}</label>
                    </FloatLabel>
                </div>
            </div>
        </Fieldset>

        <Fieldset :legend="$t('cart.form.label.addressInvoice')" class="mb-3" :toggleable="true" :collapsed="true">
            <div class="formgrid grid grid-cols-3 gap-8 pt-4">
                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-invoiceNumber" class="w-full" v-model="invoiceNumber" v-focus-next />
                        <label for="purchase-invoiceNumber">{{ $t('cart.form.label.invoiceNumber') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-invoiceVat" class="w-full" v-model="invoiceVat" v-focus-next />
                        <label for="purchase-invoiceVat">{{ $t('cart.form.label.invoiceVat') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="purchase-invoiceName" class="w-full" v-model="invoiceName" v-focus-next />
                        <label for="purchase-invoiceName">{{ $t('cart.form.label.invoiceName') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <InputText id="purchase-invoiceStreet" class="w-full" v-model="invoiceStreet" v-focus-next />
                        <label for="purchase-invoiceStreet">{{ $t('cart.form.label.street') }}</label>
                        <small v-if="isValidInput(invoiceStreet) && !containsLettersAndNumbers(invoiceStreet)" style="color: #f87171">{{ $t('cart.form.error.street') }}</small>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-2">
                    <FloatLabel>
                        <InputText id="purchase-invoiceTown" class="w-full" v-model="invoiceTown" v-focus-next />
                        <label for="purchase-invoiceTown">{{ $t('cart.form.label.town') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <FloatLabel>
                        <InputText id="purchase-invoiceZip" class="w-full" v-model="invoiceZip" v-focus-next />
                        <label for="purchase-invoiceZip">{{ $t('cart.form.label.zip') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3">
                    <FloatLabel>
                        <Select id="purchase-invoiceCountry" v-model="invoiceCountry" :options="countries" optionLabel="name" class="w-full" inputId="withoutgrouping" :useGrouping="false" v-focus-next />
                        <label for="purchase-invoiceCountry">{{ $t('cart.form.label.country') }}</label>
                    </FloatLabel>
                </div>
            </div>
        </Fieldset>

        <Fieldset legend="Poznámka pre nás" class="mb-3" :toggleable="true" :collapsed="true">
            <div class="formgrid grid grid-cols-3 gap-8 pt-4">
                <div class="field col-span-3">
                    <FloatLabel>
                        <Textarea id="purchase-userName" v-model="userNote" class="w-full" inputId="withoutgrouping" :useGrouping="false" v-focus-next />
                        <label for="purchase-userName">{{ $t('cart.form.label.userNote') }}</label>
                    </FloatLabel>
                </div>
            </div>
        </Fieldset>

        <Fieldset legend="Zľavový kód" class="mb-3" :toggleable="true" :collapsed="true">
            <div class="formgrid grid grid-cols-3 gap-8 pt-4">
                <div class="field col-span-3 md:col-span-2">
                    <FloatLabel>
                        <InputText id="purchase-voucher" v-model="voucher" class="w-full" inputId="withoutgrouping" :useGrouping="false" />
                        <label for="purchase-voucher">{{ $t('cart.form.label.voucher') }}</label>
                    </FloatLabel>
                </div>

                <div class="field col-span-3 md:col-span-1">
                    <Button :label="$t('cart.action.verify')" @click="voucherVerify" class="w-full" />
                </div>
            </div>
            <Message v-if="voucherStatusMessage" severity="info" class="mt-5">{{ voucherStatusMessage }}</Message>
        </Fieldset>

        <div class="text-center">
            <div class="my-3" :class="{'animate__animated animate__headShake': animated && condition !== true}">
                <Checkbox v-model="condition" inputId="condition" name="condition" value="condition" binary :invalid="condition !== true" />
                <label for="condition" class="ml-2" v-html="$t('cart.form.label.condition', {'link': linkCondition})"></label>
            </div>

            <div class="my-3">
                <Checkbox v-model="consentHeureka" inputId="consentHeureka" name="consentHeureka" value="consentHeureka" binary />
                <label for="consentHeureka" class="ml-2"> {{ $t('cart.form.label.consentHeureka') }}</label>
            </div>

            <div class="my-5 text-center">
                <Button id="purchase-submit" :label="$t('cart.action.submit')" size="large" class="uppercase w-full md:w-auto" @click="submitForm" :disabled="isSubmitted" />
                <label for="purchase-submit" class="pt-1 block"> {{ $t('cart.form.label.submit') }}</label>
            </div>
        </div>
    </form>
</template>

<script setup>
import _ from 'lodash';
import {computed, onMounted, ref} from "vue";
import CartRepository from "../../repository/cartRepository";
import {useStore} from "vuex";

const store = useStore();

const countries = ref([
    { name: 'Slovensko', code: 'sk' },
    { name: 'Česko', code: 'cz' },
]);

const isSubmitted = ref(false);
const companyName = ref();
const companyNumber = ref();
const companyVat = ref();
const firstName = ref();
const lastName = ref();
const street = ref();
const town = ref();
const zip = ref();
const country = ref();
const contact = ref();
const email = ref();
const invoiceNumber = ref();
const invoiceVat = ref();
const invoiceName = ref();
const invoiceStreet = ref();
const invoiceTown = ref();
const invoiceZip = ref();
const invoiceCountry = ref();
const userNote = ref();
const voucher = ref();
const condition = ref();
const consentHeureka = ref();
const firstNameRef = ref();
const voucherStatusMessage = ref();
const voucherStatusCode = ref();
const cartTotal = computed(() => store.getters.cartTotal);
const animated = ref(false);

const submitForm = async () => {
    isSubmitted.value = true;
    let hasError = false;
    let keyError = null;

    let payload = new FormData();

    const appendIfNotEmpty = (key, value, required) => {
        if(required === true && (_.isNil(value) || _.isUndefined(value) || value === '')) {
            hasError = true;

            if(keyError === null) {
                keyError = key;
            }
        }

        if(!_.isNil(value)) {
            payload.append(key, value);
        }
    };

    const appendIfContainsLetterAndNumber = (key, value, required) => {
        if(required === true && !containsLettersAndNumbers(value)) {
            hasError = true;

            if(keyError === null) {
                keyError = key;
            }
        }

        if(!_.isNil(value)) {
            payload.append(key, value);
        }
    };

    const appendIfTrue = (key, value, required) => {
        if(required === true && value !== true) {
            hasError = true;

            if(keyError === null) {
                keyError = key;
            }
        } else {
            payload.append(key, value);
        }
    };

    appendIfNotEmpty('companyName', companyName.value);
    appendIfNotEmpty('companyNumber', companyNumber.value);
    appendIfNotEmpty('companyVat', companyVat.value);
    appendIfNotEmpty('firstName', firstName.value, true);
    appendIfNotEmpty('lastName', lastName.value, true);
    appendIfContainsLetterAndNumber('street', street.value, true);
    appendIfNotEmpty('town', town.value, true);
    appendIfNotEmpty('zip', zip.value, true);
    appendIfNotEmpty('country', country.value.code, true);
    appendIfNotEmpty('contact', contact.value, true);
    appendIfNotEmpty('email', email.value, true);
    appendIfNotEmpty('invoiceNumber', invoiceNumber.value);
    appendIfNotEmpty('invoiceVat', invoiceVat.value);
    appendIfNotEmpty('invoiceName', invoiceName.value);
    appendIfNotEmpty('invoiceStreet', invoiceStreet.value);
    appendIfNotEmpty('invoiceTown', invoiceTown.value);
    appendIfNotEmpty('invoiceZip', invoiceZip.value);
    appendIfNotEmpty('invoiceCountry', invoiceCountry.value.code);
    appendIfNotEmpty('userNote', userNote.value);
    appendIfNotEmpty('voucher', voucher.value);
    appendIfTrue('condition', condition.value, true);
    appendIfTrue('consentHeureka', consentHeureka.value);

    if(hasError) {
        animate();
        scrollTo('#purchase-' + keyError);
        let el = document.getElementById('purchase-' + keyError);

        if(el) {
            el.focus();
        }

        isSubmitted.value = false;

        return;
    }

    try {
        const {data} = await CartRepository.submit(payload);

        window.location.href = `/sledovanie-zasielky/${data}`;
    } catch (error) {
        isSubmitted.value = false;
    }
};

const animate = () => {
    animated.value = true;
    setTimeout(() => animated.value = false, 1000);
};

const linkCondition = computed(() => {
    switch (window.defaults.shipping.country) {
        case 'cz':
            return '/obchodni-podminky';
    }

    return '/podmienky';
});

const isValidInput = (val) => {
    return !isSubmitted.value && !_.isNil(val) && !_.isUndefined(val) && val !== '';
};

const hasNumber = (val) => {
    return !isSubmitted.value && !_.isNil(val) && !_.isUndefined(val) && val !== '';
};

const voucherVerify = async () => {
    const {data} = await CartRepository.voucher(voucher.value, cartTotal.value);
    voucherStatusMessage.value = data.message;
    voucherStatusCode.value = data.code;
};

const containsLettersAndNumbers = (str) => {
    const hasLetters = /\p{L}/u.test(str);
    const hasNumbers = /\d/.test(str);
    return hasLetters && hasNumbers;
}

const fetchFavoriteAddress = async () => {
    const {data} = await CartRepository.getFavoriteAddress();

    if (data.delivery) {
        street.value = data.delivery.street;
        town.value = data.delivery.town;
        zip.value = data.delivery.zip;
        firstName.value = data.delivery.first_name;
        lastName.value = data.delivery.last_name;
        companyName.value = data.delivery.company_name;
        companyNumber.value = data.delivery.company_number;
        companyVat.value = data.delivery.company_vat;
    }

    if (data.invoice) {
        invoiceStreet.value = data.invoice.street;
        invoiceTown.value = data.invoice.town;
        invoiceZip.value = data.invoice.zip;
        invoiceName.value = data.invoice.comapany_name;
        invoiceNumber.value = data.invoice.company_number;
        invoiceVat.value = data.invoice.company_vat;
    }

    contact.value = data.phone ? data.phone.raw : null;
    email.value = data.email ? data.email.raw : null;
};

const scrollTo = (elementSelector) => {
    let el = document.querySelector(elementSelector);

    if (!el) {
        return;
    }

    el.scrollIntoView({ behavior: 'smooth' });
};

const hasRole = (role) => {
    return _.includes(window.defaults.loggedRoles, role);
};

onMounted(async () => {
    if (!hasRole('ROLE_WHOLESALE')) {
        countries.value = [_.find(countries.value, {'code': window.defaults.shipping.country})];
    } else {
        fetchFavoriteAddress();
    }

    country.value = countries.value[0];
    invoiceCountry.value = countries.value[0];
});
</script>

<style>

</style>