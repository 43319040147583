import store from '../vuex/store'

export default {
    store,
    bind: function (el, binding, vnode) {
        //

        el.addEventListener('focusout', event => {

        });

        el.addEventListener('blur', event => {

        });
    }
}