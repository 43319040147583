<template>
    <DataView :value="items" :layout="props.layout" v-if="items.length > 0">
        <template #list="slotProps">
            <app-heureka-review-item
                v-for="(item, index) in slotProps.items"
                :key="index"
                :name="item.name"
                :text="item.text"
                :pros="item.pros"
                :cons="item.cons"
                :rating="item.rating"
                :recommends="item.recommends"
                :avatar="item.avatar"
                class="my-2">
            </app-heureka-review-item>
        </template>
        <template #grid="slotProps">
            <div class="md:columns-3 gap-4">
                <div v-for="(item, index) in slotProps.items" class="mb-4 break-inside-avoid-column">
                    <app-heureka-review-item
                        :key="index"
                        :name="item.name"
                        :text="item.text"
                        :pros="item.pros"
                        :cons="item.cons"
                        :rating="item.rating"
                        :recommends="item.recommends"
                        :avatar="item.avatar">
                    </app-heureka-review-item>
                </div>
            </div>
        </template>
    </DataView>
</template>

<script setup>
import {onMounted, ref} from "vue";
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";
import AppHeurekaReviewItem from "./AppHeurekaReviewItem.vue";

const ReviewRepository = RepositoryFactory.get('review');

const props = defineProps({
    limit: {
        type: Number,
        default: 10,
    },
    layout: {
        type: String,
        default: 'list',
    },
});

const items = ref([]);

const fetchItems = async () => {
    const {data} = await ReviewRepository.findBy(null, props.limit);
    items.value = Object.values(data);
};

onMounted(async () => {
    fetchItems();
});
</script>

<style scoped>

</style>