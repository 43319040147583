<template>
    <div>
        <div ref="showmoreContent" :class="{ 'ui-expandable-inverted': true, 'open': isExpanded }" :style="{ height: contentHeight + 'px' }"><slot class="cf"></slot></div>

        <div class="text-center">
            <Button @click.prevent="toggle" :icon="isExpanded ? 'fas fa-chevron-up' : 'fas fa-chevron-down'" severity="danger" text outlined rounded aria-label="Cancel" />
        </div>
    </div>
</template>

<script>
export default {
    name: "ShowMore",

    data() {
        return {
            isExpanded: false,
            contentHeight: 80,
        }
    },

    methods: {
        toggle() {
            this.isExpanded = !this.isExpanded;
            if (this.isExpanded) {
                this.contentHeight = this.$refs.showmoreContent.scrollHeight;
            } else {
                this.contentHeight = 80;
            }
        },
    },

    mounted() {
        if (this.$refs.showmoreContent.scrollHeight < 100) {
            this.isExpanded = true;
            this.contentHeight = 'auto';
        }
    }
}
</script>

<style scoped lang="scss">
.ui-expandable-inverted {
    position: relative;
    overflow: hidden;
    transition: height 0.6s ease;

    &:after,
    &:before {
        height: 64px;
        content: "";
        width: 100%;
        position: absolute;
        z-index: 10;
        transition: 250ms;
        pointer-events: none;
    }

    &:after {
        background: linear-gradient(to bottom, transparent, #fbf9f7);
        bottom: 0;
        left: 0;
        right: 0;
    }

    &.open:after {
        height: 0;
    }
}
</style>