<template>
    <div class="flex flex-wrap justify-around">
        <div class="w-full md:w-[30rem]">
            <h3>{{ $t('word.shipping') }}</h3>
            <Listbox v-model="selectedDelivery" :options="filteredCompanies" optionLabel="name" :metaKeySelection="false" listStyle="max-height: none">
                <template #option="slotProps">
                    <div class="flex items-center w-full" :id="`shipping-company-id-` + slotProps.option.id">
                        <img :alt="slotProps.option.name" :src="slotProps.option.image" class="mr-3 w-[4rem]" />
                        <div>
                            <div class="font-bold">{{ slotProps.option.name }}</div>
                            <div class="text-sm">{{ slotProps.option.delivery_description }}</div>
                            <div class="font-bold text-right" v-if="slotProps.option.id === 9">
                                {{ getShippingPost() }}
                            </div>
                            <div class="font-bold text-right" v-if="slotProps.option.id === 10">
                                {{ packageAddress10 }}
                            </div>
                            <div class="font-bold text-right" v-if="slotProps.option.id === 14">
                                {{ packageAddress14 }}
                            </div>
                        </div>
                    </div>
                </template>
            </Listbox>
        </div>
        <div class="w-full md:w-[30rem]">
            <h3>{{ $t('word.payment') }}</h3>
            <Listbox v-model="selectedPayment" :options="filteredPayments" optionLabel="name" :metaKeySelection="false" listStyle="max-height: none">
                <template #option="slotProps">
                    <div class="flex items-center">
                        <img :alt="slotProps.option.name" :src="slotProps.option.image" class="mr-3 w-[4rem]" />
                        <div>
                            <div class="font-bold">{{ slotProps.option.name }}</div>
                            <div class="text-sm">{{ slotProps.option.description }}</div>
                        </div>
                    </div>
                </template>
            </Listbox>
        </div>
    </div>

<!--    <postage-motivation class="uk-card uk-text-meta uk-text-center uk-margin"></postage-motivation>-->

    <Dialog v-model:visible="visibleModalCompany9" modal header="Vyberte pobočku pošty" :draggable="false" :style="{ width: '25rem' }">
        <div class="py-4">
            <FloatLabel>
                <InputText id="postoffice-search" v-model="postofficeSearch" class="w-full" />
                <label for="postoffice-search">Mesto alebo PSČ</label>
            </FloatLabel>
        </div>

        <template #footer>
            <Button :label="$t('action.close')" text severity="secondary" @click="visibleModalCompany9 = false" autofocus />
        </template>
    </Dialog>
</template>

<script setup>
import _ from 'lodash';
import {ref, computed, watch, onMounted} from 'vue';
import { mapActions, mapGetters, useStore } from 'vuex';
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import PackNoezon from '../../../pack_noezon_v1/widget';

const PackageRepository = RepositoryFactory.get('package');
const SlovakPostRepository = RepositoryFactory.get('slovakPost');
const CartRepository = RepositoryFactory.get('cart');

const store = useStore();
const shippingItems = computed(() => store.getters.shippingItems);
const companyItems = computed(() => store.getters.companyItems);
const paymentItems = computed(() => store.getters.paymentItems);
const shippingCompany = computed(() => store.getters.shippingCompany);
const shippingPayment = computed(() => store.getters.shippingPayment);
const actions = mapActions(['shippingSelect', 'paymentSelect', 'shippingSelectAndSave', 'paymentSelectAndSave']);
const selectedPayment = ref();
const selectedPostOffice = ref();
const selectedDelivery = ref();
const visibleModalCompany9 = ref(false);
const postofficeSearch = ref();
const packageAddress10 = ref();
const packageAddress14 = ref();

const getShippingPost = () => {
    if(!_.isNil(selectedPostOffice.value) && !_.isEmpty(selectedPostOffice.value)) {
        return `${ selectedPostOffice.street } ${ selectedPostOffice.number }, ${ selectedPostOffice.name }`;
    }

    return store.state.shipping.post;
};

const filteredCompanies = computed(() => {
    return _.sortBy(companyItems.value, (ci) => {
        const s = _.find(store.state.shipping.items, (si) => {
            return si.payment == store.state.shipping.payment && si.company == ci.id;
        });
        return _.isUndefined(s) ? 0 : s.sort;
    });
});

const filteredPayments = computed(() => {
    let pi = _.isUndefined(store.state.shipping.company) ? paymentItems.value : _.filter(paymentItems.value, (paymentItem) => {
        return !_.isUndefined(_.find(store.state.shipping.items, (si) => {
            return si.company == store.state.shipping.company && si.payment == paymentItem.id;
        }));
    });

    return _.sortBy(pi, (o) => {
        const s = _.find(store.state.shipping.items, (si) => {
            return si.company == store.state.shipping.company && si.payment == o.id;
        });

        return _.isUndefined(s) ? 0 : s.sort;
    });
});

watch(selectedDelivery, (val, oldVal) => {
    if(_.isNil(val) || _.isNil(val.id)) {
        return;
    }

    if (oldVal && val.id !== oldVal.id) {
        if (val.id === 10) {
            packageAddress14.value = null;
        }
        if (val.id === 14) {
            packageAddress10.value = null;
        }
    }

    visibleModalCompany9.value = val.id === 9;

    store.commit('shippingSetSelect', val.id);
    CartRepository.selectDelivery(val.id);
});

watch(selectedPayment, (val) => {
    if(_.isNil(val) || _.isNil(val.id)) {
        return;
    }

    store.commit('paymentSetSelect', val.id);
    CartRepository.selectPayment(val.id);
});

const findShippingCompanyById = (id) => {
    return _.find(filteredCompanies.value, (shippingCompany) => {
        return shippingCompany.id === id;
    })
};

const findShippingPaymentById = (id) => {
    return _.find(filteredPayments.value, (shippingPayment) => {
        return shippingPayment.id === id;
    })
};

onMounted(() => {
    selectedDelivery.value = findShippingCompanyById(shippingCompany.value);
    selectedPayment.value = findShippingPaymentById(shippingPayment.value);

    if (shippingCompany.value === 10 && store.state.shipping.package_location) {
        packageAddress10.value = store.state.shipping.package_location;
    }
    else if (shippingCompany.value === 14 && store.state.shipping.package_location) {
        packageAddress14.value = store.state.shipping.package_location;
    }

    let packNoezonPacketa = new PackNoezon();

    packNoezonPacketa.init({
        'element': '#shipping-company-id-10',
        'map': true,
        'geolocation': true,
        'callback': (address) => {
            if (address) {
                PackageRepository.select(address.id, 10);
                packageAddress10.value = address.name;
            }
        },
        'selected': selectedDelivery.value === 10 ? store.state.shipping.package_address : null,
        'id': 'packeta',
        'title': 'Packeta',
        'country': window.defaults.shipping.country,
        'company': 1,
    });

    let packNoezonSps = new PackNoezon();

    packNoezonSps.init({
        'element': '#shipping-company-id-14',
        'map': true,
        'geolocation': true,
        'callback': (address) => {
            if (address) {
                PackageRepository.select(address.id, 14);
                packageAddress14.value = address.name;
            }
        },
        'selected': selectedDelivery.value === 14 ? store.state.shipping.package_address : null,
        'id': 'sps',
        'title': 'SPS',
        'country': window.defaults.shipping.country,
        'company': 4,
    });
});
</script>

<style>
.p-listbox-option {
    padding: 0 !important;
}

.p-listbox-option > div {
    padding: .5rem .75rem;
}
</style>

<style src="../../../pack_noezon_v1/ol.css"></style>
<style src="../../../pack_noezon_v1/widget.css"></style>