<script setup>
import {onMounted, ref} from "vue";
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";

const FavoriteRepository = RepositoryFactory.get('favorite');

const props = defineProps(['product-id', 'favorite']);
const isFavorite = ref(false);

const toggle = function () {
    isFavorite.value = !isFavorite.value;

    if (window.defaults.isLogged) {
        if (isFavorite.value) {
            FavoriteRepository.addProduct(props.productId);
        } else {
            FavoriteRepository.removeProduct(props.productId);
        }
    } else {
        const STORAGE_FAVORITE = 'product.favorites';
        let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE)) || [];

        const index = favorites.indexOf(props.productId);
        if (index > -1) {
            favorites.splice(index, 1);
        } else {
            favorites.push(props.productId);
        }

        localStorage.setItem(STORAGE_FAVORITE, JSON.stringify(favorites));
    }
};

const hasInFavorites = async function() {
    if (window.defaults.isLogged) {
        if (!_.isUndefined(props.favorite)) {
            isFavorite.value = props.favorite;
        } else {
            try {
                // const {data} = await FavoriteRepository.hasProduct(props.productId);
                // isFavorite.value = JSON.parse(data);
            } catch (e) {

            }
        }
    }  else {
        const STORAGE_FAVORITE = 'product.favorites';
        let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE)) || [];
        isFavorite.value = favorites.includes(props.productId);
    }
};

onMounted(() => {
    hasInFavorites();
});
</script>

<template>
    <Button
        @click="toggle();"
        :icon="(isFavorite ? 'fa' : 'far') + ' fa-heart'"
        class="ml-1 px-0 w-6 md:w-10"
        style="max-height: 42px"
        variant="text" />
</template>

<style scoped>

</style>