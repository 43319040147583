<template>
    <Card id="form-registration">
        <template #title>Registrácia</template>
        <template #content>
            <FloatLabel class="mt-5">
                <InputText id="username" v-model="email" class="w-full" :invalid="email && !isValidEmail" />
                <label for="username">{{ $t('sign.form.username') }}</label>
                <small class="text-red-600" v-if="email && !isValidEmail">Nesprávny formát</small>
            </FloatLabel>

            <FloatLabel class="mt-5">
                <InputText id="username" v-model="phone" class="w-full" :invalid="phone && !isValidPhone" />
                <label for="username">{{ $t('sign.form.phone') }}</label>
                <small class="text-red-600" v-if="phone && !isValidPhone">Nesprávny formát</small>
            </FloatLabel>

            <FloatLabel class="mt-5">
                <Password v-model="password" :pt="{root: {class: 'w-full'}, 'input': {class: 'w-full'}}" :feedback="false" toggleMask />
                <label for="username">{{ $t('sign.form.password') }}</label>
            </FloatLabel>

            <FloatLabel class="mt-5">
                <Password v-model="passwordRepeat" :pt="{root: {class: 'w-full'}, 'input': {class: 'w-full'}}" :feedback="false" :invalid="!isSamePassword" toggleMask />
                <label for="username">{{ $t('sign.form.passwordRepeat') }}</label>
                <small class="text-red-600" v-if="passwordRepeat && !isSamePassword">Musí sa zhodovať</small>
            </FloatLabel>
        </template>
        <template #footer>
            <div class="flex gap-3 mt-3">
                <Button :label="$t('sign.action.submitUp')" @click.prevent="submitForm" class="w-full" :disabled="!(isValidEmail && isValidPhone && isSamePassword)" />
            </div>
            <template v-if="props.facebook || props.google">
                <div>
                    <Divider />
                </div>
                <div class="flex gap-3 mt-3">
                    <a href="/connect/facebook" class="w-full" v-if="props.facebook">
                        <Button label="Facebook" class="w-full" />
                    </a>
                    <a href="/connect/google" class="w-full" v-if="props.google">
                        <Button label="Google" class="w-full" />
                    </a>
                </div>
            </template>
            <div>
                <Divider />
            </div>
            <div class="flex justify-center">
                <a :href="pathLogin">{{ $t('sign.action.hasAccount') }}</a>
            </div>
        </template>
    </Card>
</template>

<script setup>
import validator from 'validator';
import { ref , computed} from "vue";
import SecurityRepository from "../../repository/front/securityRepository";

const props = defineProps({
    type: {
        type: [String, null],
    },
    facebook: {
        type: Boolean,
        default: true,
    },
    google: {
        type: Boolean,
        default: true,
    },
});
const email = ref();
const phone = ref();
const password = ref();
const passwordRepeat = ref();

const pathLogin = computed(() => {
    let path = '/moje/prihlasenie';

    if (props.type) {
        path += '?type=' + props.type;
    }

    return path;
});

const isSamePassword = computed(() => password.value === passwordRepeat.value);
const isValidEmail = computed(() => email.value && validator.isEmail(email.value));
const isValidPhone = computed(() => phone.value && validator.isMobilePhone(phone.value));

const submitForm = async () => {
    try {
        await SecurityRepository.signUp(email.value, password.value, phone.value, props.type);

        window.location.href = `/moje/prihlasenie`;
    } catch (e) {
        // error.value = this.$t('sign.message.unauthorized');
    }
};
</script>

<style scoped>

</style>