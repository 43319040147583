<script setup>
import {onMounted, ref} from "vue";

const props = defineProps(['productId']);
const visible = ref(false);

onMounted(() => {
    if(new URLSearchParams(window.location.search).has('r')) {
        visible.value = true;
    }
});
</script>

<template>
    <Dialog v-model:visible="visible" class="w-[25rem]" header="Vyberte si jazyk" modal>
        <div class="flex">
            <a class="flex-1 items-center justify-center text-center py-3" :href="'/p/' + props.productId + '/sk'" type="button">
                <span class="fi fi-sk text-8xl shadow-4"></span>
            </a>
            <a class="flex-1 items-center justify-center text-center py-3" :href="'/p/' + props.productId + '/cz'" type="button">
                <span class="fi fi-cz text-8xl shadow-4"></span>
            </a>
        </div>
    </Dialog>
</template>

<style scoped>

</style>