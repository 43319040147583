import Repository from "../Repository";
import Vue from "vue";

const resource = "/api/favorite";

export default {
    addProduct(id) {
        return Repository.put(`${resource}/product/${id}`);
    },

    addProducts(ids) {
        let payload = new FormData();
        payload.append('product_ids', JSON.stringify(ids));

        return Repository.put(`${resource}/product`, payload);
    },

    removeProduct(id) {
        return Repository.delete(`${resource}/product/${id}`);
    },

    hasProduct(id) {
        return Repository.get(`${resource}/product/${id}`);
    },

    hasProducts(ids) {
        let payload = new FormData();
        payload.append('product_ids', JSON.stringify(ids));

        return Repository.post(`${resource}/products`, payload);
    },

    addBlog(id) {
        return Repository.put(`${resource}/blog/${id}`);
    },

    removeBlog(id) {
        return Repository.delete(`${resource}/blog/${id}`);
    },

    hasBlog(id) {
        return Repository.get(`${resource}/blog/${id}`);
    },
}