<template>
    <Card v-if="!isLoading" :id="hash">
<!--        <template #header>-->
<!--            <img alt="user header" src="/images/usercard.png" />-->
<!--        </template>-->
        <template #title>
            <div v-html="title"></div>
        </template>
        <template #subtitle>
            <div v-html="subtitle"></div>
        </template>
        <template #content>
            <InputGroup>
                <InputText placeholder="Email" v-model="email" :disabled="!isActive || isSent" />
                <Button label="Hlasovať" @click="submit" :disabled="!isActive || isSent" class="rounded-r-md" />
            </InputGroup>

            <Message v-if="message" severity="info" class="mt-5">{{ message }}</Message>

            <Message v-if="error" severity="error" class="mt-5">{{ error }}</Message>
        </template>
        <template #footer>
            Hlasovalo {{ sum }}
        </template>
    </Card>

    <Card style="overflow: hidden" v-if="isLoading">
        <ProgressSpinner />
    </Card>
</template>

<script>
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";

const VoteRepository = RepositoryFactory.get('vote');

export default {
    props: ['hash', 'choices'],

    data() {
        return {
            title: null,
            subtitle: null,
            email: null,
            choices: null,
            count: null,
            sum: null,
            isLoading: true,
            message: null,
            error: null,
            isSent: false,
            isActive: true,
        }
    },

    methods: {
        fetch: async function () {
            this.isLoading = true;

            const {data} = await VoteRepository.get(this.hash);
            this.title = data.name;
            this.subtitle = data.description;
            // this.choices = data.choices;
            this.count = data.count;
            this.sum = data.sum;
            this.isActive = data.is_active;

            this.isLoading = false;
        },

        submit: async function () {
            this.isSubmitting = true;

            try {
              const {data} = await VoteRepository.send(this.hash, this.email);
              this.message = 'Ďakujeme, že ste zahlasovali. Aby bol Váš hlas platný, potvrďte súhlas zaslaný mailom.';
              this.isSent = true;
            } catch (e) {
                if (e.response.status === 409) {
                    this.error = 'Tento email už hlasoval.';
                }
            }

            this.isSubmitting = false;
        },
    },

    mounted() {
        this.fetch();
    }
}
</script>

<style scoped>

</style>