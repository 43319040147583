import Repository from "./Repository";

const resource = "/api/review";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },
    findBy(payload, limit) {
        if (!payload) {
            payload = new FormData();
        }

        payload.append('_limit', limit);
        return Repository.post(`${resource}`, payload);
    },
}