import axios from "axios";
import {RepositoryFactory} from "../repository/RepositoryFactory";

const CartRepository = RepositoryFactory.get('cart');

export const cartItemSet = function(store, data) {
    store.commit('cartSetItems', data)
};

export const cartItemUpdate = function (store, data) {
    store.commit('cartItemUpdate2', data)
};

export const cartUpdate =  function (store, data) {
    store.commit('cartUpdating', true); // TODO: musi sa stackovat ak by bezalo viac requestov

    let body = new FormData();
    body.append('id', data.productId);
    body.append('quantity', data.quantity);

    axios.post(window.defaults.cart.url_update, body, {
        headers: {
            'Content-Type': 'application/x-www-form-urlencoded; charset=UTF-8'
        }
    }).then(function(response)  {
        store.commit('cartUpdating', false); // TODO: musi sa stackovat ak by bezalo viac requestov

        giftUpdate(store)
    });
};

export const cartAdd = async (store, cartItem) => {
    cartAnimate(store);

    localStorage.setItem('lastCartAddLocation', window.location.href);

    if (_.isInteger(cartItem.quantity) === false) {
        cartItem.quantity = 1;
    }
    if (cartItem.quantity < 1) {
        cartItem.quantity = 1;
    }
    if (cartItem.quantity > 99) {
        cartItem.quantity = 99;
    }

    const {data} = await CartRepository.add(cartItem.id, cartItem.quantity);

    store.commit('cartSetItems', JSON.parse(data.items));
    // store.commit('cartAddItem', {
    //     'product_id': cartItem.id,
    //     'quantity': cartItem.quantity,
    //     'price': cartItem.price,
    // })
};

export const shippingSelect = (store, data) => {
    store.commit('shippingSetSelect', data);
};

export const shippingSave = (store, data) => {
    let body = new FormData();
    body.append('company', data);

    axios.post(window.defaults.shipping.url_select, body);
};

export const shippingSelectAndSave = (store, data) => {
    shippingSelect(store, data);
    shippingSave(store, data);
};

export const paymentSelect = (store, data) => {
    store.commit('paymentSetSelect', data);
};

export const paymentSave = (store, data) => {
    let body = new FormData();
    body.append('payment', data);

    axios.post(window.defaults.shipping.url_select, body);
};

export const paymentSelectAndSave = (store, data) => {
    paymentSelect(store, data);
    paymentSave(store, data);
};

export const serviceSelect = (store, data) => {
    store.commit('serviceSelect', data);
};

export const serviceSave = (store, data) => {
    // let body = new FormData();
    // body.append('service', data);

    // axios.post(window.defaults.shipping.url_select, body);

    CartRepository.addService(data.id);
};

export const serviceSelectAndSave = (store, data) => {
    serviceSelect(store, data);
    serviceSave(store, data);
};

export const giftUpdate = (store) => {
    axios.get(window.defaults.gift.url_index).then(response => {
        store.commit('giftSetItems', response.body.items);
    });
};

export const cartAnimate = (store) => {
    store.commit('cartAnimated', true);
    setTimeout(() => store.commit('cartAnimated', false), 1000);
};

export const cartSetUpdating = (store, data) => {
    store.commit('cartUpdating', data);
};