import store from '../vuex/store';
import UIkit from 'uikit';

export default {
    store,
    bind: function (el, binding, vnode) {
        let previous;

        el.addEventListener('focus', event => {
            previous = event.target.value;
        });

        el.addEventListener('change', event => {
            UIkit.modal.confirm(vnode.context.$t('cart.flash.countryChange.message', {'country': el.options[el.selectedIndex].text}), {
                labels: {
                    'ok': vnode.context.$t('cart.flash.countryChange.ok'),
                    'cancel': vnode.context.$t('cart.flash.countryChange.cancel')
                }
            }).then(
                // confirmed
                () => {
                    window.location.href = `/country/${el.value}/?r=${encodeURIComponent(window.defaults.cart.url_index)}`;
                },
                // rejected
                () => {
                    let opts = el.options;
                    for (let opt, j = 0; opt = opts[j]; j++) {
                        if (opt.value == previous) {
                            el.options[j].selected = true;
                            break;
                        }
                    }
                }
            );
        });
    }
}