<template>
    <div class="product-cart-wrapper">
        <div class="product-cart">
            <div>
                <div v-if="inCart > 0" class="fadein animation-duration-1000 m-1 flex justify-end">
                    <a :href="link">
                        <Tag :value="$t('word.inCart', { quantity: inCart })"></Tag>
                    </a>
                </div>
            </div>

            <div class="flex flex-nowrap">
                <InputNumber
                    v-model="quantity"
                    inputId="minmax-buttons"
                    mode="decimal"
                    showButtons
                    :min="0"
                    :max="99"
                    inputClass="w-[5rem]" />

                <Button
                    @click="cartAdd({id: productId, quantity: quantity, price: productPrice}); addToCart();"
                    :label="buttonLabel"
                    icon="fa fa-shopping-cart"
                    class="w-full whitespace-nowrap ml-2 px-2" />
            </div>
        </div>
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import store from '../../vuex/store';
import mixin from '../../mixin/mixin';
import AppFavoriteProduct from "./AppFavoriteProduct.vue";

export default {
    store,

    props: ['disabled', 'productId', 'productName', 'productPrice', 'wholesale'],

    mixins: [mixin],

    data() {
        return {
            maxQuantity: 99,
            quantity: 1,
            link: window.defaults.cart.url_index,
            animated: false,
        }
    },

    computed: {
        inCart: function () {
            let item = this.$store.getters.findByProductId(this.productId);
            return (item ? item.quantity : 0);
        },
        buttonLabel: function () {
            if (screen.width > 760) {
                return this.$t('cart.action.add');
            }

            return null;
        },
        ...mapGetters([
            'currency',
        ]),
    },

    watch: {
        quantity: function (val) {
            if(val > this.maxQuantity) {
                this.$nextTick(() => {
                    this.quantity = this.maxQuantity;
                });
            }
            else if(val < 1) {
                this.$nextTick(() => {
                    this.quantity = 1;
                });
            }
        }
    },

    methods: {
        animate: function () {
            this.animated = true;
            setTimeout(() => this.animated = false, 1000);
        },

        addToCart: function () {
            dataLayer.push({
                event: "add_to_cart",
                ecommerce: {
                    currency: this.currency.code,
                    value: this.productPrice * this.quantity,
                    items: [
                        {
                            item_id: "ID" + this.productId,
                            item_name: this.productName,
                            // affiliation: "Google Merchandise Store",
                            // coupon: "SUMMER_FUN",
                            // discount: 2.22,
                            // index: 0,
                            // item_brand: "Google",
                            // item_category: "Apparel",
                            // item_category2: "Adult",
                            // item_category3: "Shirts",
                            // item_category4: "Crew",
                            // item_category5: "Short sleeve",
                            // item_list_id: "related_products",
                            // item_list_name: "Related Products",
                            // item_variant: "green",
                            // location_id: "ChIJIQBpAG2ahYAR_6128GcTUEo",
                            price: this.productPrice,
                            quantity: this.quantity,
                        }
                    ]
                },
                _clear: true,
            });
        },

        ...mapActions([
            'cartAdd',
            'cartUpdate',
        ]),
    },
}
</script>

<style scoped>

</style>