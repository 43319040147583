export default {
    bind: function (el, binding) {
        let elements = el.querySelectorAll('input, select, textarea');
        let skip = true;

        for (let i = 0; i < elements.length; i++) {
            let element = elements[i];

            if (skip && element.name !== binding.value.from) {
                continue;
            }

            skip = false;

            if (!element.value) {
                element.setAttribute('autofocus', '');
                break;
            }
        }
    }
}