<script setup>
import {computed, onBeforeMount, ref} from "vue";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

const CartRepository = RepositoryFactory.get('cart');

const props = defineProps(['purchasePrice']);
const items = ref([]);
const selected = ref();

const fetchGifts = async () => {
    const {data} = await CartRepository.giftMotivations();
    items.value = data;
};

const filteredItems = computed(() => _.filter(items.value, (item) => item.amount_min > props.purchasePrice));

const getPercentage = (val1, val2) => {
    return (100 * val1) / val2;
};

onBeforeMount(async () => {
    await fetchGifts();
});
</script>

<template>
    <div class="grid">
        <div class="col-12 md:col-6 animate__animated"
             v-for="item in filteredItems"
             :key="item.id">
            <Chip class="block md:flex p-2 w-full">
                <div class="font-medium grow">
                    <img :src="item.item.image" class="vertical-align-middle ml-1" v-if="item.item.image"> {{ item.item.value }}
                </div>
                <ProgressBar :value="getPercentage(props.purchasePrice, item.amount_min)"
                             class="w-full md:w-40 md:ml-2 mt-2 md:mt-0">
                    {{ props.purchasePrice }} / {{ item.amount_min }} €
                </ProgressBar>
            </Chip>
        </div>
    </div>
</template>

<style scoped>

</style>