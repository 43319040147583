<template>
    <div>
        <Button
            type="button"
            label="Spresnit parametre"
            icon="fas fa-filter"
            class="w-full mb-2"
            @click="toggleShow()"
            v-if="isMobile" />

        <Card id="filter" :pt="{ body: { class: 'p-2' } }" v-if="isVisible">
            <template #content>
                <div v-if="!isLoading">
                    <form id="blog-filter">
                        <div>
                            <Fieldset v-for="(attribute, attributeKey) in attributes" :legend="attribute.label" :toggleable="true">
                                <div v-for="(option, optionKey) in attribute.options"
                                     class="filter-tags uk-grid uk-grid-small uk-child-width-1 uk-grid-stack uk-margin"
                                     uk-grid>
                                    <label>
                                        <input type="checkbox"
                                               :value="optionKey"
                                               v-model="values[attributeKey][optionKey]"
                                               @change="changeFilter"
                                               class="uk-checkbox uk-checkbox-danger">
                                        {{ option }}
                                        <div class="color" :style="labelStyle(attributeKey, optionKey)"></div>
                                    </label>
                                </div>
                            </Fieldset>
                        </div>
                    </form>
                </div>
                <div class="text-center" v-else>
                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>

                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>

                    <Fieldset legend="...">
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                        <Skeleton class="mb-2"></Skeleton>
                    </Fieldset>
                </div>
            </template>
            <template #footer>
                <div class="flex gap-3 mt-1">
                    <Button :label="$t('action.cancel')" size="small" @click.prevent="reset" severity="secondary" outlined class="grow-0" />
                    <Button :label="$t('action.filter')" size="small" :badge="count" @click.prevent="filter" class="grow" />
                </div>
            </template>
        </Card>
    </div>
</template>

<script>
import eventBus from '../../eventBus';
import _ from 'lodash';
import 'vue-slider-component/theme/antd.css'
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";
import MobileDetect from "mobile-detect";

const BlogRepository = RepositoryFactory.get('blog');

export default {
    props: ['category-id', 'category-slug'],

    computed: {
        isMobile: function () {
            let md = new MobileDetect(window.navigator.userAgent);
            return md.isPhoneSized();
        },
    },

    data() {
        return {
            isVisible: false,

            isFiltered: false,

            choices: {},
            isLoading: false,

            values: {},
            count: 0,

            attributes: {},
            attributeActive: null,
        }
    },

    mounted() {
        this.isVisible = !this.isMobile;
        this.fetchFilterValues();
    },

    methods: {
        fetchFilterValues: async function () {
            this.isLoading = true;

            const {data} = await BlogRepository.filterChoices(this.categoryId);
            this.choices = data;

            this.loadFilter();

            this.isLoading = false;
        },

        loadFilter: function () {
            this.attributes = this.choices;
            this.attributeActive = _.findKey(this.attributes);

            this.reset();

            let url = _.split((new URL(window.location.href)).pathname, '/');

            _.forEach(_.split(url[3], ';'), (attributes) => {
                if (attributes === "") {
                    return;
                }

                this.isFiltered = true;

                let attribute = _.split(attributes, ':');

                let options = {};

                _.forEach(_.split(attribute[1], ','), (option) => {
                    if (option !== "") {
                        options[option] = true; // before: options[option] = option
                    }
                });

                if (!_.isEmpty(options)) {
                    this.values[attribute[0]] = options;
                }
            });

            this.loadFilterCount();
        },

        changeFilter: function () {
            this.isFiltered = true;
            this.loadFilterCount();
        },

        loadFilterCount: async function () {
            let values = _.omitBy(this.values, _.isEmpty);

            const {data} = await BlogRepository.filterCount(this.categoryId, values);
            this.count = data.count;
        },

        generateUrl: function () {
            return _.join(_.map(_.omitBy(this.values, _.isEmpty), (options, attribute) => attribute + ":" + (_.isObject(options) ? _.join(_.keys(_.pickBy(options, (option) => option)), ',') : options)), ';');
        },

        filter: function () {
            eventBus.blogFilterCriteria = _.omitBy(this.values, _.isEmpty);
        },

        activeAttributeLabel: function (attribute, attributeKey) {
            if (_.isEmpty(attribute)) {
                return false;
            }

            let options = _.join(_.map(_.keys(_.pickBy(attribute, (option) => option)), (option) => this.optionLabelText(attributeKey, option)), ', ');

            if (_.isNil(options) || options === "") {
                return false;
            }

            return `${this.attributeLabelText(attributeKey)}: ${options}`;
        },

        reset: function () {
            _.forEach(this.attributes, (attribute, key) => {
                this.values[key] = {};
            });

            this.isFiltered = false;
        },

        clear: function () {
            window.location.href = `/${this.categorySlug}`;
        },

        attributeLabelText: function (attributeKey) {
            if (!_.has(this.attributes[attributeKey], 'label')) {
                return;
            }

            return this.attributes[attributeKey].label;
        },

        optionLabelText: function (attributeKey, optionKey) {
            return this.attributes[attributeKey].options[optionKey];
        },

        labelStyle: function (attributeKey, optionKey) {
            if (_.isEmpty(this.attributes[attributeKey].styles)) {
                return;
            }

            return this.attributes[attributeKey].styles[optionKey];
        },

        toggleShow: function () {
            this.isVisible = !this.isVisible;
        }
    }
}
</script>

<style scoped>

</style>