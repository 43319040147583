<template>
    <div id="cart-navigation-bar" class="flex justify-between flex-wrap">
        <div>
            <div>
                <span class="mr-3">{{ $t('word.shippingPriceWithVat') }}</span>
                <span>{{ parseFloat(shippingPrice).toFixed(currencyRound) }} {{ currencySymbol }}</span>
            </div>
            <div>
                <strong class="mr-3">{{ $t("word.priceTotalWithVat") }}</strong>
                <strong>{{ parseFloat(cartTotal).toFixed(currencyRound) }} {{ currencySymbol }}</strong>
            </div>
        </div>

        <a :href="url">
            <Button icon="fa fa-chevron-right"></Button>
        </a>
    </div>
</template>

<script>
import {mapGetters} from 'vuex';
import MobileDetect from 'mobile-detect';

export default {
    data() {
        return {
            url: window.defaults.purchase.url_index
        }
    },

    computed: {
        isMobile: function () {
            let md = new MobileDetect(window.navigator.userAgent);
            return md.mobile();
        },

        ...mapGetters([
            'shippingPrice',
            'cartTotal',
            'currencySymbol',
            'currencyRound'
        ]),
    },

    methods: {
        excessQuantity: function (item) {
            return item.product_quantity != null && item.product_quantity < item.quantity
        }
    }
}
</script>

<style scoped>
#cart-navigation-bar {
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    background-color: #222;
    color: #999;
    padding: 10px;
}
</style>