import Repository from "./Repository";

const resource = "/api/banner";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    findImages(id) {
        return Repository.get(`${resource}/${id}/images`);
    },
}