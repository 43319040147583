<template>
    <div v-if="purchase" class="pt-2">
        <div class="flex justify-center my-1">
            <a :href="urlDownloadPdf">
                <Button label="Uložiť túto objednávku v pdf" />
            </a>
        </div>

        <div class="flex justify-center my-4">
            <purchase-tracker
                :active="step"
                step1="Prijatie objednávky"
                step2="Odoslané"
                :step2desc="moment(purchase.promise_send_date).format('DD.MM.YYYY')"
                step3="Doručené"
                :step3desc="moment(purchase.promise_delivery_date).format('DD.MM.YYYY')">
            </purchase-tracker>
        </div>

        <Divider />

        <div class="flex justify-center my-4">
            <h2><span class="text-slate-400">{{ moment(purchase.purchase_created_at).format("DD.MM.YYYY HH:mm") }}</span><span class="font-bold ml-4">Prijatá</span></h2>
        </div>

        <Divider />

        <div class="grid grid-cols-1 md:grid-cols-3 gap-4 my-4">
            <div>
                <h3>Spôsob dopravy</h3>
                <p>{{ purchase.delivery_type.name }}</p>
                <p v-if="purchase.delivery_type.id !== 2 && purchase.delivery_type.id !== 11 && purchase.delivery_type.id !== 13 && purchase.package">
                    <template v-if="purchase.package.place">Odberné miesto - {{ purchase.package.place }}<br></template>
                    <template v-if="purchase.package.location">{{ purchase.package.location }}<br></template>
                    <template v-if="purchase.package.barcode"><a href="#" target="_blank">{{ purchase.package.barcode }}</a><br></template>
                    {{ purchase.package.street }}<br>
                    {{ purchase.package.zip }} {{ purchase.package.city }} {{ purchase.package.country_code }}<br>
                </p>

                <h3>Spôsob platby</h3>
                <p>{{ purchase.payment_type.name }}</p>
            </div>
            <div>
                <h3>Dodacia adresa</h3>
                <p>
                    {{ purchase.full_name }}<br>
                    {{ purchase.street }}<br>
                    {{ purchase.zip }}, {{ purchase.town }} ({{ purchase.country.code }})<br>
                    <i class="fa fa-phone" aria-hidden="true"></i> {{ purchase.contact }}<br>
                    <i class="fa fa-envelope" aria-hidden="true"></i> {{ purchase.email }}<br>
                </p>
            </div>
            <div>
                <h3>Fakturačná adresa</h3>
                <p>
                    {{ purchase.company_name ? purchase.company_name : purchase.full_name }}<br>
                    {{ purchase.invoice_street ? purchase.invoice_street : purchase.street }}<br>
                    {{ purchase.invoice_zip ? purchase.invoice_zip : purchase.zip }}, {{ purchase.invoice_town ? purchase.invoice_town : purchase.town }} ({{ purchase.invoice_country ? purchase.invoice_country.code : purchase.country.code }})<br>
                    <template v-if="purchase.company_number">IČO: {{ purchase.company_number }}<br></template>
                    <template v-if="purchase.company_vat">DIČ: {{ purchase.company_vat }}<br></template>
                    <i class="fa fa-phone" aria-hidden="true"></i> {{ purchase.invoice_contact ? purchase.invoice_contact : purchase.contact }}<br>
                    <i class="fa fa-envelope" aria-hidden="true"></i> {{ purchase.invoice_email ? purchase.invoice_email : purchase.email }}<br>
                </p>
            </div>
        </div>

        <Panel header="Poznámka" class="my-4" v-if="purchase.user_note">
            {{ purchase.user_note }}
        </Panel>

<!--        <Panel header="Darčeky" class="my-3" v-if="purchase.gifts.length > 0">-->
<!--            <Badge-->
<!--                v-for="gift in purchase.gifts"-->
<!--                :value="gift.value">-->
<!--            </Badge>-->
<!--        </Panel>-->

        <Panel header="Darčeky" class="my-4" v-if="purchase.gift">
            {{ purchase.gift }}
        </Panel>

        <Panel class="my-3">
            <DataTable v-model:expandedRows="expandedRows" :value="purchaseItems" :size="isMobile() ? 'small' : 'normal'" dataKey="product.id">
                <Column expander class="w-[2rem]" v-if="isMobile()" />
                <Column v-if="!isMobile()">
                    <template #body="slotProps">
                        <img :src="slotProps.data.product.image_medium" :alt="slotProps.data.image" class="w-[3rem] border-round" />
                    </template>
                </Column>
                <Column field="name">
                    <template #body="slotProps">
                        <a :href="slotProps.data.product.url" class="text-primary">{{ slotProps.data.name }}</a>
                    </template>
                </Column>
                <Column field="productId" v-if="!isMobile()">
                    <template #body="slotProps">
                        ID{{ slotProps.data.product.id }}
                    </template>
                </Column>
                <Column field="text" v-if="!isMobile()">
                    <template #body="slotProps">
                        <Badge
                            :value="slotProps.data.text"
                            severity="danger"
                            v-if="slotProps.data.text">
                        </Badge>
                    </template>
                </Column>
                <Column field="price" header="Cena za ks" v-if="!isMobile()">
                    <template #body="slotProps">
                        {{ slotProps.data.price }} {{ purchase.currency.symbol }}
                    </template>
                </Column>
                <Column field="price" header="Ks" v-if="!isMobile()">
                    <template #body="slotProps">
                        {{ slotProps.data.quantity }}
                    </template>
                </Column>
                <Column field="price" header="Cena" class="text-right">
                    <template #body="slotProps" >
                        <div class="whitespace-nowrap text-right">{{ parseFloat(slotProps.data.priceTotal).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                    </template>
                </Column>
                <template #expansion="slotProps">
                    <div class="p-3">
                        <div class="text-right">{{ slotProps.data.quantity }}x {{ slotProps.data.price }} {{ purchase.currency.symbol }}</div>
                        <img :src="slotProps.data.product.image_medium" :alt="slotProps.data.image" class="w-full" />
                    </div>
                </template>
                <template #footer>
                    <div class="text-right">
                        <div class="flex justify-between md:justify-end flex-wrap py-2 font-normal">
                            <div class="md:mr-8">{{ $t("word.priceSubtotalWithVat") }}</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.subtotal).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2 font-normal">
                            <div class="md:mr-8">{{ $t("word.shippingPriceWithVat") }}</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.price_shipping).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2 font-normal" v-if="purchase.price_alignment !== 0">
                            <div class="md:mr-8">{{ $t("word.priceItemsAlignment") }}</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.price_alignment).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2 font-normal" v-if="purchase.voucher_text || purchase.voucher_price !== 0">
                            <div class="md:mr-8">{{ purchase.voucher_text ? purchase.voucher_text : $t("word.priceVoucher") }}</div>
                            <div class="w-[5rem] text-right" v-if="purchase.voucher_price !== 0">{{ parseFloat(purchase.voucher_price).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2">
                            <div class="font-bold md:mr-8">Celkom k úhrade bez DPH</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.total_without_vat).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2">
                            <div class="font-bold md:mr-8">Celkom DPH</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.vat).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                        <div class="flex justify-between md:justify-end flex-wrap py-2">
                            <div class="font-bold md:mr-8">Celkom k úhrade s DPH</div>
                            <div class="w-[5rem] text-right">{{ parseFloat(purchase.total).toFixed(purchase.currency.round) }} {{ purchase.currency.symbol }}</div>
                        </div>
                    </div>
                </template>
            </DataTable>
        </Panel>

        <div class="pb-2">
            <a href="/">
                <Button label="pokračovať v nakupovaní" outlined class="w-full" @click="downloadPdf" />
            </a>
        </div>
    </div>
</template>

<script setup>
import moment from 'moment';
import {onMounted, ref} from "vue";
import PurchaseRepository from "../../repository/front/purchaseRepository";
import MobileDetect from "mobile-detect";

const props = defineProps(['purchaseId', 'purchaseHash', 'step']);
const events = ref(['Prijatie objednavky', 'Odoslane', '2022', '2023']);
const purchase = ref();
const purchaseItems = ref();
const expandedRows = ref({});

const urlDownloadPdf = `/pdf/${props.purchaseHash}`;

const downloadPdf = () => {

};

const groupPurchaseItems = () => {
    const groupedItems = _.groupBy(purchase.value.items, 'product.id');

    purchaseItems.value = _.map(groupedItems, (group) => {
        const totalQuantity = _.sumBy(group, 'quantity');
        const priceTotal = _.sumBy(group, item => item.quantity * item.price);

        return {
            ...group[0],
            quantity: totalQuantity,
            priceTotal
        };
    });
};

const isMobile = () => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
}

const fetchPurchase = async () => {
    const {data} = await PurchaseRepository.get(props.purchaseHash);
    purchase.value = data;

    groupPurchaseItems();
};

onMounted(async () => {
    await fetchPurchase();
});
</script>

<style scoped>

</style>