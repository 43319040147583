<template>
    <DataTable :value="items" stripedRows tableStyle="min-width: 50rem">
        <Column field="image" header="Image"></Column>
        <Column field="payment" header="Payment"></Column>
        <Column v-for="paymentPrice in paymentPrices" :field="paymentPrice.range" :header="paymentPrice.name"></Column>
    </DataTable>
</template>

<script setup>
import { ref, computed } from "vue";

const props = defineProps(['items']);
const paymentPrices = ref([]);
</script>

<style scoped>

</style>