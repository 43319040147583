import Repository from "./Repository";

const resource = "/api/category";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    getTree(categories, level) {
        let tree = [];

        _.forEach(categories, (category) => {
            tree.push({
                'id': category.id,
                'name': `${category.name}`,
                'slug': `${category.slug}`,
                'visible': `${category.visible}`,
                'canonical': category.canonical,
                'class': 'level-' + level,
                '$isDisabled': _.has(category, 'children') && category.children.length > 0
            });

            if (_.has(category, 'children') && category.children.length > 0) {
                tree = tree.concat(this.getTree(category.children, level + 1));
            }
        });

        return tree;
    },

    breadcrumb(id) {
        return Repository.get(`/api/breadcrumb/${id}`);
    },
}