import Repository from "./Repository";

const resource = "/api/vote";

export default {
    get(hash) {
        return Repository.get(`${resource}/${hash}`);
    },

    send(hash, email, choice) {
        let payload = new FormData();
        payload.append('hash', hash);
        payload.append('email', email);
        payload.append('choice', choice);

        return Repository.post(`${resource}/send`, payload);
    },

    chart(group) {
        let payload = new FormData();
        payload.append('group', group);

        return Repository.post(`${resource}/chart`, payload);
    },
}