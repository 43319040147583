<template>
    <Toast />
    <ConfirmPopup></ConfirmPopup>

    <Dialog v-model:visible="isModalDeleteVisible" modal header="Odobrat tovar z kosika?" class="w-[25rem]">
        <div class="flex justify-end gap-2">
            <Button type="button" label="Nie" severity="secondary" @click="isModalDeleteVisible = false; updatingItem.quantity = 1;"></Button>
            <Button type="button" label="Ano" @click="isModalDeleteVisible = false; remove();"></Button>
        </div>
    </Dialog>

    <Panel class="my-3" :pt="{ content: { class: 'p-0' } }">
        <Button severity="secondary" outlined icon="fas fa-minus" label="Skryť fotky a ceny" @click="colapseAll" v-if="isMobile() && isExpandedAll" />
        <Button severity="secondary" outlined icon="fas fa-plus" label="Zbraziť fotky a ceny" @click="expandAll" v-if="isMobile() && !isExpandedAll" />

        <DataTable v-model:expandedRows="expandedRows" :value="items" :size="isMobile() ? 'small' : 'normal'" dataKey="id">
            <Column expander class="w-[2rem]" v-if="isMobile()" />
            <Column v-if="!isMobile()">
                <template #body="slotProps">
                    <img :src="slotProps.data.product.image_medium" :alt="slotProps.data.image" class="w-[3rem] border-round" />
                </template>
            </Column>
            <Column v-if="!isMobile()" field="productId" bodyClass="text-500">
                <template #body="slotProps">
                    ID{{ slotProps.data.product.id }}
                </template>
            </Column>
            <Column field="name"></Column>
            <Column field="excess">
                <template #body="slotProps">
                    <Badge
                        :value="!isMobile() ? $tc('cart.message.excessQuantity', maxQuantity(slotProps.data), {'count': maxQuantity(slotProps.data)}) : maxQuantity(slotProps.data) + 'ks'"
                        severity="danger"
                        v-if="excessQuantity(slotProps.data)">
                    </Badge>
                </template>
            </Column>
            <Column v-if="!isMobile()" field="pricePerPcs" :header="$t('word.pricePerPcs')" headerClass="column-text-right" bodyClass="text-right">
                <template #body="slotProps">
                    {{ parseFloat(slotProps.data.price_active).toFixed(currencyRound) }} {{ currencySymbol }}
                </template>
            </Column>
            <Column field="quantity">
                <template #body="slotProps">
                    <InputNumber
                        v-model="slotProps.data.quantity"
                        inputId="minmax-buttons"
                        mode="decimal"
                        showButtons
                        :min="0"
                        :max="99"
                        inputClass="w-[5rem]"
                        @update:modelValue="update(slotProps.data)" />
                </template>
            </Column>
            <Column v-if="!isMobile()" field="price" :header="$t('word.priceWithVat')" headerClass="column-text-right" bodyClass="text-right">
                <template #body="slotProps">
                    {{ parseFloat(slotProps.data.quantity * slotProps.data.price_active).toFixed(currencyRound) }} {{ currencySymbol }}
                </template>
            </Column>
            <template #expansion="slotProps">
                <div class="p-3">
                    <img :src="slotProps.data.product.image_medium" :alt="slotProps.data.image" class="w-full" />
                </div>
                <div class="flex justify-between flex-wrap">
                    <div class="flex items-center justify-center text-left">
                        {{ slotProps.data.quantity }}x {{ parseFloat(slotProps.data.price_active).toFixed(currencyRound) }} {{ currencySymbol }}
                    </div>
                    <div class="flex items-center justify-center text-center">
                        ID{{ slotProps.data.product.id }}
                    </div>
                    <div class="flex items-center justify-center text-right">
                        {{ parseFloat(slotProps.data.quantity * slotProps.data.price_active).toFixed(currencyRound) }} {{ currencySymbol }}
                    </div>
                </div>
            </template>
            <template #footer>
                <div class="text-right">
                    <div class="flex justify-end flex-wrap">
                        <div class="font-bold">{{ $t("word.priceSubtotalWithVat") }}</div>
                        <div class="w-[6rem]">{{ parseFloat(cartSubtotal).toFixed(currencyRound) }} {{ currencySymbol }}</div>
                    </div>
                </div>
            </template>
        </DataTable>
    </Panel>

    <Panel header="Darčeky, ktoré môžete získať" class="my-3" id="cart-gift-motivation">
        <app-cart-gift-carousel
            :purchase-price="parseFloat(cartSubtotal).toFixed(currencyRound)"
            :currency-round="currencyRound"
            :currency-symbol="currencySymbol">
        </app-cart-gift-carousel>
    </Panel>

    <Panel class="my-3" id="cart-shipping">
        <app-cart-shipping></app-cart-shipping>
    </Panel>

    <Panel class="text-right my-3">
        <div class="flex justify-end flex-wrap">
            <div class="font-bold">Suma za dopravu s DPH</div><div class="w-[6rem]">{{ parseFloat(shippingPrice).toFixed(currencyRound) }} {{ currencySymbol }}</div>
        </div>

        <Divider />

        <div class="flex justify-end flex-wrap">
            <div class="font-bold">Centové dorovnanie</div><div class="w-[6rem]">{{ parseFloat(cartAlignment).toFixed(currencyRound) }} {{ currencySymbol }}</div>
        </div>

        <Divider />

        <div class="flex justify-end flex-wrap">
            <div class="font-bold">Celkom k úhrade</div><span class="w-[7rem]">{{ parseFloat(cartTotal).toFixed(currencyRound) }} {{ currencySymbol }}</span>
        </div>
    </Panel>
</template>

<script setup>
import _ from 'lodash';
import {ref, computed} from "vue";
import { useStore } from 'vuex';
import { useConfirm } from "primevue/useconfirm";
import { useToast } from "primevue/usetoast";
import MobileDetect from "mobile-detect";
import {giftUpdate} from "../../vuex/actions";
import CartRepository from "../../repository/cartRepository";

const store = useStore();

const items = computed({
    get: () => store.getters.cartItems,
    set: (val) => store.dispatch('cartItemUpdate', {
        id: updatingItem.id,
        quantity: updatingItem.quantity,
    })
});
const expandedRows = ref({});
const currencyRound = computed(() => store.getters.currencyRound);
const currencySymbol = computed(() => store.getters.currencySymbol);
const cartSubtotal = computed(() => store.getters.cartSubtotal);
const shippingPrice = computed(() => store.getters.shippingPrice);
const cartAlignment = computed(() => store.getters.cartAlignment);
const cartTotal = computed(() => store.getters.cartTotal);

const confirm = useConfirm();
const toast = useToast();

const isModalDeleteVisible = ref(false);
const updatingItem = ref();
const updatingItemId = ref();

const isMobile = () => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
}

const scrollToElement = (elementSelector) => {
    document.querySelector(elementSelector).scrollIntoView({ behavior: 'smooth' });
};

const isExpandedAll = computed(() => {
    return items.value.every((item) => expandedRows.value[item.id] === true);
});

const expandAll = () => {
    expandedRows.value = items.value.reduce((acc, p) => (acc[p.id] = true) && acc, {});
};

const colapseAll = () => {
    expandedRows.value = [];
};

const update = (data) => {
    updatingItem.value = data;
    updatingItemId.value = data.product.id;

    if (_.isNaN(data.quantity) || data.quantity <= 0) {
        // confirmDelete();
        isModalDeleteVisible.value = true;
    } else {
        CartRepository.update(data.product.id, data.quantity);
    }
}

const remove = () => {
    items.value = items.value.filter(item => item.id !== updatingItem.id);
    CartRepository.update(updatingItemId.value, 0);
}

const excessQuantity = (item) => {
    if (item.manufactured === true || item.manufactured === '1') {
        return false
    }

    if (item.product_quantity !== null && item.product_quantity < item.quantity) {
        return true;
    }

    if (item.product_cart_limit !== null && item.product_cart_limit < item.quantity) {
        return true;
    }

    return false;
}

const maxQuantity = (item) => _.min([item.product_quantity, item.product_cart_limit])
</script>