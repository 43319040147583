<template>
    <Carousel :items-to-show="itemsToShow" :wrap-around="true" v-if="isMobile || props.slide">
        <Slide v-for="item in items" :key="item.id">
            <app-product-grid-item
                :item="item"
                class="w-full h-full">
            </app-product-grid-item>
        </Slide>

        <template #addons>
            <Navigation />
        </template>
    </Carousel>
    <div class="grid grid-cols-4 gap-4" v-else>
        <app-product-grid-item
            v-for="item in items"
            :item="item">
        </app-product-grid-item>
    </div>
</template>

<script setup>
import { ref, computed } from "vue";
import { Carousel, Slide, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';
import MobileDetect from "mobile-detect";

const props = defineProps({
    items: {
        type: [String, Array, Object, null],
    },
    slide: {
        type: Boolean,
        default: false,
    },
});

const itemsToShow = computed(() => {
    return isMobile.value ? 1.4 : 4;
});

const isMobile = computed(() => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.isPhoneSized();
});
</script>

<style>
.carousel {
    text-align: initial;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    display: flex;
}

.carousel__slide {
    padding: 0 5px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
}
</style>