<template>
    <div class="card" v-if="!isLoading">
        <InputText type="text" v-model="search" v-if="props.search" />

        <DataView
            :value="filteredItems"
            :lazy="props.lazy"
            :first="first"
            :rows="rows"
            :total-records="totalRecords"
            :layout="layout"
            :paginator="paginator"
            :always-show-paginator="false"
            @page="onPage($event)">
            <template #header v-if="props.switcher">
                <div class="flex justify-end">
                    <SelectButton v-model="layout" :options="options" :allowEmpty="false">
                        <template #option="{ option }">
                            <i :class="[option === 'list' ? 'fa-solid fa-table-list' : 'fa-solid fa-table-cells']"></i>
                        </template>
                    </SelectButton>
                </div>
            </template>

            <template #list="slotProps">
                <div class="grid gap-3">
                    <div v-for="(item, index) in slotProps.items" :key="index">
                        <app-product-list-item
                            :id="elementIdProduct(item.id)"
                            :selected="item.id === props.scrollTo"
                            :item="item"
                            :setting="setting"
                            :favorite="isFavorite(item.id)"
                            :zoom="productParentId !== undefined"
                            :visible-code="props.visibleCode"
                            v-if="!isLoading">
                        </app-product-list-item>
                        <Skeleton class="h-40" v-else></Skeleton>
                    </div>
                </div>
            </template>

            <template #grid="slotProps">
                <div class="flex flex-wrap my-4 justify-center">
                    <div v-for="(item, index) in slotProps.items" :key="index" class="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
                        <app-product-grid-item
                            :id="elementIdProduct(item.id)"
                            class="h-full p-1"
                            :selected="item.id === props.scrollTo"
                            :item="item"
                            :setting="setting"
                            :favorite="isFavorite(item.id)"
                            :zoom="productParentId !== undefined"
                            :visible-code="props.visibleCode"
                            v-if="!isLoading">
                        </app-product-grid-item>
                        <Skeleton class="h-[30rem]" v-else></Skeleton>
                    </div>
                </div>
            </template>

            <template #empty="slotProps">
                <div class="grid grid-cols-2 lg:grid-cols-3" v-if="isLoading">
                    <div v-for="index in rows"
                         :key="index"
                         class="p-1 md:p-2">
                        <Skeleton class="h-96"></Skeleton>
                    </div>
                </div>
                <div class="p-5 text-center" v-else>
                    <i class="far fa-meh fa-5x mb-3"></i><br>
                    <span class="text-3xl">Nenašli sme žiadne produkty</span>
                </div>
            </template>
        </DataView>
    </div>
</template>

<script setup>
import {onMounted, ref, watch} from "vue";
import eventBus from '../../eventBus';
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";
import _ from "lodash";

const ProductRepository = RepositoryFactory.get('product');
const FavoriteRepository = RepositoryFactory.get('favorite');

const props = defineProps({
    productId: Array,
    items: {
        type: [String, Array, Object, null],
    },
    productParentId: [Number, null],
    categoryId: Number,
    scrollTo: {
        type: [String, Number],
    },
    page: {
        type: Number,
        default: 1,
    },
    limit: {
        type: Number,
        default: 12,
    },
    paginator: {
        type: Boolean,
        default: true,
    },
    switcher: {
        type: Boolean,
        default: true,
    },
    totalRecords: {
        type: Number,
        default: 0,
    },
    search: {
        type: Boolean,
        default: false,
    },
    productLocalstorage: {
        type: String,
    },
    visibleCode: {
        type: Boolean,
        default: false,
    },
    paginationUrl: {
        type: [String, null],
    },
    lazy: {
        type: Boolean,
        default: true,
    },
});

const items = ref([]);
const filteredItems = ref([]);
const rows = ref(props.limit ? props.limit : 100);
const first = ref((props.page - 1) * rows.value);
const totalRecords = ref(props.totalRecords ? props.totalRecords : 0);
const layout = ref('grid');
const setting = ref();
const paginator = ref(props.paginator);
const paginatorTemplate = ref({
    '768px': 'PrevPageLink CurrentPageReport NextPageLink',
    default: 'FirstPageLink PrevPageLink PageLinks NextPageLink LastPageLink RowsPerPageDropdown',
});
const filterCriteria = ref({});
const isLoading = ref(true);
const search = ref();
const favorites = ref([]);
const options = ref(['list', 'grid']);

watch(() => eventBus.productFilterCriteria, async (data) => {
    filterCriteria.value = data;
    await fetchData();
    await fetchFavorite();

    isLoading.value = false;
});

watch(layout, (newLayout) => {
    layoutSave(newLayout);
});

watch(search, (newSearch) => {
    filteredItems.value = items.value.filter((item) =>
        item.name
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(newSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
        ||
        item.id == newSearch
    );
});

const layoutSave = (newLayout) => {
    if(props.categoryId) {
        let layouts = JSON.parse(localStorage.getItem('category.layouts')) || {};
        layouts[props.categoryId] = newLayout;
        localStorage.setItem('category.layouts', JSON.stringify(layouts));
    }
    else if(props.productParentId) {
        let layouts = JSON.parse(localStorage.getItem('product.layouts')) || {};
        layouts[props.productParentId] = newLayout;
        localStorage.setItem('product.layouts', JSON.stringify(layouts));
    }
};

const layoutLoad = () => {
    if(props.categoryId) {
        let layouts = JSON.parse(localStorage.getItem('category.layouts')) || {};
        layout.value = layouts[props.categoryId] || 'grid';
    }
    else if(props.productParentId) {
        let layouts = JSON.parse(localStorage.getItem('product.layouts')) || {};
        layout.value = layouts[props.productParentId] || 'grid';
    }
};

const layoutHas = () => {
    if (props.categoryId) {
        let layouts = JSON.parse(localStorage.getItem('category.layouts')) || {};
        return layouts.hasOwnProperty(props.categoryId);
    }
    else if (props.productParentId) {
        let layouts = JSON.parse(localStorage.getItem('product.layouts')) || {};
        return layouts.hasOwnProperty(props.productParentId);
    }
    return false;
};

const fetchData = async () => {
    isLoading.value = true;

    let payload = new FormData();

    if(!_.isNil(props.productId)) {
        payload.append('product_id', props.productId);
    }

    else if(props.productLocalstorage) {
        if (!_.isNil(localStorage.getItem(props.productLocalstorage))) {
            payload.append('product_id', JSON.parse(localStorage.getItem(props.productLocalstorage)));
        } else {
            isLoading.value = false;
            return;
        }
    }

    if(!_.isNil(props.productParentId)) {
        payload.append('product_parent_id', props.productParentId);
    }

    if(!_.isNil(props.categoryId)) {
        payload.append('category_id', props.categoryId);
    }

    if(!_.isEmpty(filterCriteria.value)) {
        payload.append('filter_criteria', JSON.stringify(filterCriteria.value));
    }

    payload.append('_offset', first.value);
    payload.append('_limit', rows.value);

    const {data} = await ProductRepository.findBy(payload);

    items.value = data.items;
    filteredItems.value = data.items;
    totalRecords.value = data.total;

    if(!_.isNil(props.productParentId)) {
        if(!layoutHas()) {
            layout.value = data.variant_view || 'grid';
        }

        setting.value = data.variant_view_setting || null;
    }
};

const fetchFavorite = async () => {
    let ids = getVisibleIds();
    const {data} = await FavoriteRepository.hasProducts(ids);
    favorites.value = data;
};

const isFavorite = (id) => favorites.value?.[id] || false;

const getVisibleIds = () => {
    return _.map(items.value, 'id');
};

const onPage = async (event) => {
    first.value = event.first;

    if (props.lazy) {
        await fetchData();
        await fetchFavorite();
    }

    if (_.isNil(props.productParentId)) {
        let page = (first.value / rows.value) + 1;
        changePageNumber(page);
    }

    isLoading.value = false;
};

const elementIdProduct = (productId) => {
    return `product-${productId}`;
};

const scrollToElement = (elementSelector) => {
    try {
        let el = document.querySelector(elementSelector);

        if (el) {
            el.scrollIntoView({behavior: 'smooth'});
        }
    } catch (e) {

    }
};

const changePageNumber = (newPageNumber) => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const pathSegments = props.paginationUrl ? [props.paginationUrl] : pathname.split('/').filter(segment => segment !== '');

    if (pathSegments.length >= 2) {
        pathSegments[1] = newPageNumber;
    } else {
        pathSegments.push(newPageNumber);
    }

    url.pathname = '/' + pathSegments.join('/');
    window.history.pushState({}, '', url);
}

const normalizeItems = (data) => {
    return Array.isArray(data) ? data : Object.values(data);
};

onMounted(async () => {
    layoutLoad();

    if(_.isEmpty(props.items)) {
        await fetchData();
    } else {
        items.value = normalizeItems(props.items);
        filteredItems.value = normalizeItems(props.items);
    }

    await fetchFavorite();

    isLoading.value = false;

    // filteredItems.value = items.value;

    if (!_.isNil(props.scrollTo)) {
        setTimeout(() => {
            scrollToElement('#' + elementIdProduct(props.scrollTo));
        }, 800);
    }
});
</script>

<style scoped>

</style>