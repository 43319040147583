<template>
    <div class="bs-wizard w-full grid grid-flow-col auto-cols-fr">
        <div v-if="step1" class="bs-wizard-step" :class="[active > 0 ? 'complete' : '']">
            <div class="text-center bs-wizard-stepnum">
                <i class="fa fa-handshake-o md:hidden" aria-hidden="true"></i>
                <span class="hidden md:block">{{ step1 }}</span>
            </div>
            <div class="progress">
                <div class="progress-bar"></div>
            </div>
            <a href="#" class="bs-wizard-dot"></a>
            <div class="bs-wizard-info text-center">{{ step1desc }}</div>
        </div>

        <div v-if="step2" class="bs-wizard-step" :class="[active > 1 ? 'complete' : 'disabled']">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum">
                <i class="fa fa-credit-card md:hidden" aria-hidden="true"></i>
                <span class="hidden md:block">{{ step2 }}</span>
            </div>
            <div class="progress">
                <div class="progress-bar"></div>
            </div>
            <a href="#" class="bs-wizard-dot"></a>
            <div class="bs-wizard-info text-center">{{ step2desc }}</div>
        </div>

        <div v-if="step3" class="bs-wizard-step" :class="[active > 2 ? 'complete' : 'disabled']">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum">
                <i class="fa fa-truck fa-flip-horizontal md:hidden" aria-hidden="true"></i>
                <span class="hidden md:block">{{ step3 }}</span>
            </div>
            <div class="progress">
                <div class="progress-bar"></div>
            </div>
            <a href="#" class="bs-wizard-dot"></a>
            <div class="bs-wizard-info text-center">{{ step3desc }}</div>
        </div>

        <div v-if="step4" class="bs-wizard-step" :class="[active > 3 ? 'complete' : 'disabled']">
            <!-- active -->
            <div class="text-center bs-wizard-stepnum">
                <i class="fa fa-shopping-bag md:hidden" aria-hidden="true"></i>
                <span class="hidden md:block">{{ step4 }}</span>
            </div>
            <div class="progress">
                <div class="progress-bar"></div>
            </div>
            <a href="#" class="bs-wizard-dot"></a>
            <div class="bs-wizard-info text-center">{{ step4desc }}</div>
        </div>
    </div>
</template>

<script>
export default {
    props: [
        'active',
        'step1',
        'step2',
        'step3',
        'step4',
        'step1desc',
        'step2desc',
        'step3desc',
        'step4desc',
        'steps'
    ],
    computed: {
        count,
    }
};

function count() {
    let c = 0;
    for (let i = 1; i <= 4; i++) {
        if (this['step' + i]) c++;
    }
    return c;
}
</script>

<style scoped>
.container {
    margin-left: -15px;
    margin-right: -15px;
}

.container:after {
    clear: both;
}

.container:before,
.container:after {
    content: " ";
    display: table;
}

.col-xs-3 {
    width: 25%;
}

.text-center {
    text-align: center;
}

.progress-bar {
    float: left;
    width: 0;
    height: 100%;
    font-size: 12px;
    line-height: 20px;
    color: #fff;
    text-align: center;
    background-color: #428bca;
    -webkit-transition: width .6s ease;
    transition: width .6s ease;
}

.progress {
    overflow: hidden;
    height: 20px;
    margin-bottom: 20px;
    background-color: #ddd;
    border-radius: 4px;
}

/*Form Wizard*/
.bs-wizard {
    padding: 0 0 10px 0;
}

.bs-wizard > .bs-wizard-step {
    float: left;
    padding: 0;
    position: relative;
}

.bs-wizard > .bs-wizard-step + .bs-wizard-step {
}

.bs-wizard > .bs-wizard-step .bs-wizard-stepnum {
    color: #595959;
    font-size: 16px;
    margin-bottom: 5px;
    height: 22px;
}

.bs-wizard > .bs-wizard-step .bs-wizard-info {
    color: #999;
    font-size: 14px;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot {
    position: absolute;
    width: 30px;
    height: 30px;
    display: block;
    background: #ee395b;
    top: 46px;
    left: 50%;
    margin-top: -15px;
    margin-left: -15px;
    border-radius: 50%;
}

.bs-wizard > .bs-wizard-step > .bs-wizard-dot:after {
    content: ' ';
    width: 14px;
    height: 14px;
    background: #cb0047;
    border-radius: 50px;
    position: absolute;
    top: 8px;
    left: 8px;
}

.bs-wizard > .bs-wizard-step > .progress {
    position: relative;
    border-radius: 0px;
    height: 8px;
    margin: 20px 0;
}

.bs-wizard > .bs-wizard-step > .progress > .progress-bar {
    width: 0px;
    background: #ee395b;
}

.bs-wizard > .bs-wizard-step.complete > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.active > .progress > .progress-bar {
    width: 50%;
}

.bs-wizard > .bs-wizard-step:first-child.active > .progress > .progress-bar {
    width: 0%;
}

.bs-wizard > .bs-wizard-step:last-child.active > .progress > .progress-bar {
    width: 100%;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot {
    background-color: #ddd;
}

.bs-wizard > .bs-wizard-step.disabled > .bs-wizard-dot:after {
    opacity: 0;
}

.bs-wizard > .bs-wizard-step:first-child > .progress {
    left: 50%;
    width: 50%;
}

.bs-wizard > .bs-wizard-step:last-child > .progress {
    width: 50%;
}

.bs-wizard > .bs-wizard-step.disabled a.bs-wizard-dot {
    pointer-events: none;
}

/*END Form Wizard*/
</style>