<template>
    <div class="card block md:hidden">
        <Carousel :items-to-show="1.4" :wrap-around="true">
            <Slide v-for="item in items" :key="item.id">
                <app-blog-grid-item
                    :item="item"
                    class="w-full h-full">
                </app-blog-grid-item>
            </Slide>

            <template #addons>
                <Navigation />
            </template>
        </Carousel>

<!--        <Carousel-->
<!--                :value="items"-->
<!--                :numScroll="1"-->
<!--                :circular="true"-->
<!--                :responsiveOptions="responsiveOptions"-->
<!--                :pt="{-->
<!--                    previousButtonIcon: {class: 'w-[3rem] h-[3rem]'},-->
<!--                    nextButtonIcon: {class: 'w-[3rem] h-[3rem]'},-->
<!--                }">-->
<!--            <template #item="slotProps">-->
<!--                <app-product-grid-item-->
<!--                    :item="slotProps.data"-->
<!--                    class="max-w-[30rem] m-2">-->
<!--                </app-product-grid-item>-->
<!--            </template>-->
<!--        </Carousel>-->
    </div>
    <div class="hidden md:block">
        <div class="flex flex-wrap my-4 justify-center">
            <div v-for="item in items" class="w-1/2 md:w-1/3 lg:w-1/3 xl:w-1/4">
                <app-blog-grid-item
                    :item="item"
                    class="h-full p-1">
                </app-blog-grid-item>
            </div>
        </div>
    </div>
</template>

<script setup>
import { ref } from "vue";
import { Carousel, Slide, Pagination, Navigation } from 'vue3-carousel';
import 'vue3-carousel/dist/carousel.css';

const props = defineProps(['items']);
const layout = ref('grid');
const responsiveOptions = ref([
    {
        breakpoint: '1400px',
        numVisible: 4,
        numScroll: 1
    },
    {
        breakpoint: '1199px',
        numVisible: 3,
        numScroll: 1
    },
    {
        breakpoint: '767px',
        numVisible: 2,
        numScroll: 1
    },
    {
        breakpoint: '575px',
        numVisible: 1,
        numScroll: 1
    },
]);
</script>

<style>
/*
.p-carousel-container {
    position: relative;
}

.p-carousel-prev,
.p-carousel-next {
    position: absolute;
    z-index: 10;
}

.p-carousel-prev {
    left: 0;
}

.p-carousel-next {
    right: 0;
}
*/

.carousel {
    text-align: initial;
}

.carousel__item {
    min-height: 200px;
    width: 100%;
    display: flex;
}

.carousel__slide {
    padding: 0 5px;
}

.carousel__prev,
.carousel__next {
    box-sizing: content-box;
}
</style>