<script setup>
import { ref, onMounted, onUnmounted } from 'vue';
import { useAttrs } from 'vue';

const loaded = ref(false);
const imgRef = ref(null);
const attrs = useAttrs();

let observer = null;

const onIntersect = (entries) => {
    if (entries[0].intersectionRatio > 0.3) {
        loaded.value = true;
        if (observer) observer.disconnect();
    }
};

onMounted(() => {
    observer = new IntersectionObserver(onIntersect, {
        root: null,
        rootMargin: '0px',
        threshold: 0.3,
    });

    if (imgRef.value) {
        observer.observe(imgRef.value);
    }
});

onUnmounted(() => {
    if (observer) observer.disconnect();
});
</script>

<template>
    <div ref="imgRef">
        <img
            v-if="loaded"
            v-bind="attrs"
        />
        <Skeleton v-else class="w-full"></Skeleton>
    </div>
</template>
