<template>
    <div>
        <div class="flex flex-col sm:flex-row sm:items-center p-2 bg-white gap-3 border border-slate-200 rounded relative"
             :class="{ 'shadow-lg shadow-slate-400': props.selected, 'shadow-slate-50': !props.selected }">
            <div class="absolute" style="right: .5rem; top: .5rem">
                <Tag :value="item.availability_text" :severity="item.availability_severity"></Tag>
                <Tag :value="item.quantity" :severity="item.availability_severity" class="ml-1" v-if="item.type === 'single' && item.quantity !== 0"></Tag>
                <template v-for="itemTag in item.tags_public">
                    <Tag :value="itemTag.c_value" severity="info" class="ml-1" v-if="itemTag.c_type === 'ribbon'"></Tag>
                </template>
            </div>
            <div class="w-[5rem] md:w-[10rem]" v-if="isVisible('image')">
                <a :href="item.url" v-if="!zoom">
                    <app-lazy-image
                        :src="imageSrc"
                        :alt="item.name"
                        class="block xl:block mx-auto border-round w-full"
                        placeholder="/images/default.jpg" />
                </a>
                <Image v-else class="block xl:block mx-auto border-round w-full" :src="imageSrc" :alt="item.name" preview />
            </div>
            <div class="flex flex-col md:flex-row justify-between md:items-center flex-1 gap-4">
                <div class="flex flex-row md:flex-col justify-between items-start gap-2">
                    <div>
                        <span v-if="isVisible('id')" class="text-slate-500 text-xs">ID{{ item.id }}</span>
                        <span v-if="isVisible('code') && props.visibleCode" class="text-secondary text-xs">{{ item.code_public }}</span>
                        <div v-if="isVisible('name')" class="text-lg font-medium mt-2">
                            <a :href="item.url" class="text-lg font-medium mt-1 no-underline text-color-secondary" v-if="!zoom">{{ item.name }}</a>
                            <span class="text-lg font-medium mt-1 no-underline text-color-secondary" v-else>{{ item.name }}</span>
                        </div>
                        <div v-if="isVisible('tag')">
                            <div v-for="tag in item.tags">
                                <span v-if="!setting || setting.tag === true || setting.tag === tag.c_type" class="mr-3">{{ tag.c_value }}</span>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="flex justify-between items-center gap-5">
                    <div class="whitespace-nowrap">
                        <span class="text-lg font-semibold text-color-secondary line-through mr-3" v-if="item.priceOld">{{ item.priceOld }} {{ item.currencySymbol }}</span>
                        <span class="text-xl font-semibold text-primary" :class="{'price-not-b2b': item?.price_is_b2c === true}" v-tooltip.bottom="item?.price_is_b2c === true ? 'Maloobchodná cena' : ''">{{ item.price }} {{ item.currencySymbol }}</span>
                    </div>
                    <div class="flex justify-end flex-wrap-reverse md:flex-row gap-2">
                        <app-cart-button
                            :product-id="item.id"
                            :product-name="item.name"
                            :product-price="item.price"
                            class="w-full md:w-auto"
                            v-if="item.visible === 2 && (item.type === 'single' || item.type === 'set')">
                            <i class="fa fa-shopping-cart"></i>
                        </app-cart-button>

                        <app-watchdog
                            :product="item.id"
                            :price="item.price"
                            cart="true"
                            v-else-if="item.type === 'single' || item.type === 'set'">
                        </app-watchdog>

                        <a :href="item.url" v-if="item.type === 'variant'" class="w-full">
                            <Button label="Varianty" :badge="''+item.children_count" :disabled="item.inventoryStatus === 'OUTOFSTOCK'" class="w-full whitespace-nowrap"></Button>
                        </a>

                        <app-favorite-product
                            :product-id="item.id"
                            :favorite="props.favorite">
                        </app-favorite-product>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AppFavoriteProduct from "./AppFavoriteProduct.vue";
import {ref, computed} from "vue";

const props = defineProps({
    item: Object,
    selected: {
        type: Boolean,
        default: false,
    },
    setting: {
        type: [Object, null],
    },
    favorite: {
        type: Boolean,
        default: false,
    },
    zoom: {
        type: Boolean,
        default: false,
    },
    visibleCode: {
        type: Boolean,
        default: false,
    },
});

const isVisible = (key) => {
    if (_.isNil(props.setting)) {
        return true;
    }

    if(_.isNil(props.setting[key])) {
        return true;
    }

    return props.setting[key];
};

const imageSrc = computed(() => `/data/product/image/medium/sk/${props.item.image_filename}`);
</script>

<style scoped>
a {
    color:  var(--text-color);
}

a:hover {
    color: var(--primary-color);
}

.price-not-b2b {
    background-color: yellow;
}
</style>