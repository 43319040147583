import Repository from "./Repository";

const resource = "/api/security";

export default {
    signIn(username, password, type) {
        let payload = new FormData();
        payload.append('username', username);
        payload.append('password', password);

        if(type) {
            payload.append('type', type);
        }

        return Repository.post(`${resource}/signin`, payload, {
            'headers': {
                'Content-Type': 'application/json'
            }
        });
    },

    signUp(username, password, phone, type) {
        let payload = new FormData();
        payload.append('username', username);
        payload.append('password', password);
        payload.append('phone', phone);

        if(type) {
            payload.append('type', type);
        }

        return Repository.post(`${resource}/signup`, payload);
    },
}