import Repository from "./Repository";

const resource = "/api/video";

export default {
    findChaptersByProduct(productId) {
        let payload = new FormData();
        payload.append('product_id', JSON.stringify(productId));

        return Repository.post(`${resource}/chapter`, payload);
    },

    findChaptersByUrl(url) {
        let payload = new FormData();
        payload.append('url', url);

        return Repository.post(`${resource}/chapter`, payload);
    },
}