<script setup>
import {computed, onBeforeMount, ref, watch} from "vue";
import { RepositoryFactory } from "../../repository/RepositoryFactory";
import {Carousel, Navigation, Slide} from "vue3-carousel";
import MobileDetect from "mobile-detect";
import eventBus from "../../eventBus";

const CartRepository = RepositoryFactory.get('cart');

const props = defineProps(['purchasePrice', 'currencyRound', 'currencySymbol']);
const items = ref([]);
const selectedItems = ref([]);
const selected = ref();
const isLoading = ref(true);

const fetchGifts = async () => {
    const {data} = await CartRepository.giftMotivations();
    items.value = data;
};

const fetchSelectedGifts = async () => {
    const {data} = await CartRepository.selectedGifts();
    selectedItems.value = data;
};

const filteredItems = computed(() => _.filter(items.value, (item) => item.amount_max > props.purchasePrice));
const availableItems = computed(() => _.filter(items.value, (item) => item.amount_min < props.purchasePrice && item.amount_max > props.purchasePrice));
const sortedItems = computed(() => _.sortBy(filteredItems.value, (item) => item.amount_min));

const itemsToShow = computed(() => {
    return isMobile.value ? 1.4 : 4;
});

const isMobile = computed(() => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.isPhoneSized();
});

const selectGift = async (itemId) => {
    await CartRepository.selectGift(itemId);
};

const getSelected = () => {
    return _.head(selectedItems.value);
};

const findSelected = (itemId) => {
    return _.find(selectedItems.value, {'id': itemId});
};

const isSelected = (itemId) => {
    let item = findSelected(itemId);
    return !_.isNil(item);
};

watch(() => eventBus.giftSelect, async (giftId) => {
    selected.value = giftId;
    selectGift(giftId);
});

watch(() => props.purchasePrice, async () => {
    if (!_.isNil(selected.value) && _.filter(availableItems.value, (item) => item.id === selected.value).length === 0) {
        selected.value = null;
        await CartRepository.selectGift();
    }
});

onBeforeMount(async () => {
    isLoading.value = true;
    await fetchSelectedGifts();
    await fetchGifts();
    selected.value = getSelected()?.id;
    isLoading.value = false;
});
</script>

<template>
    <Carousel :items-to-show="itemsToShow" snapAlign="start" v-if="!isLoading && sortedItems.length > 0">
        <Slide v-for="item in sortedItems" :key="item.id">
            <app-cart-gift-carousel-item
                :item="item"
                :purchase-price="props.purchasePrice"
                :currency-round="props.currencyRound"
                :currency-symbol="props.currencySymbol"
                :selected="isSelected(item.item.id)"
                class="w-full h-full">
            </app-cart-gift-carousel-item>
        </Slide>

        <template #addons>
            <Navigation />
        </template>
    </Carousel>
    <div class="card flex justify-center" v-else-if="isLoading">
        <ProgressSpinner />
    </div>
</template>

<style scoped>

</style>