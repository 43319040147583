import _ from "lodash";

export const cartSetQuantity = (state, payload) => {
    state.cart.quantity = payload;
};

export const cartSetTotal = (state, payload) => {
    state.cart.total = payload;
};

export const cartSetItems = (state, payload) => {
    state.cart.items = null;
    state.cart.items = payload;
};

export const cartAddItem = (state, data) => {
    let item = _.find(state.cart.items, {'product_id': parseInt(data.product_id)});
    // let item = _.find(state.cart.items, function(o) {
    //     return o.product.id == data.product_id;
    // });

    if(item) {
        item.quantity = data.quantity;
    } else {
        item = {
            product_id: data.product_id,
            product: {'id': data.product_id},
            quantity: data.quantity,
            price: data.price
        };
    }

    const items = Array.from(state.cart.items);
    items.push(item);
    state.cart.items = null;
    state.cart.items = items;
};

export const cartItemUpdate2 = (state, data) => {
    let item = _.find(state.cart.items, {'id': data.id});

    if(data.quantity > 0) {
        item.quantity = data.quantity;
    } else {
        state.cart.items = _.filter(state.cart.items, function(currentObject) {
            return currentObject.id !== data.id;
        });
    }
};

export const shippingSetItems = (state, payload) => {
    state.shipping.items = payload;
};

export const shippingSetSelect = (state, data) => {
    state.shipping.company = data;
};

export const paymentSetItems = (state, payload) => {
    state.shipping.payments = payload;
};

export const paymentSetSelect = (state, data) => {
    state.shipping.payment = data;
};

export const serviceSelect = (state, data) => {
    _.remove(state.cart.services, (item) => {
        return item.group === data.group;
    });

    state.cart.services.push(data);
};

export const giftSetItems = (state, data) => {
    state.gift.items = data;
};

export const cartAnimated = (state, data) => {
    state.cart.animated = data;
};

export const cartUpdating = (state, data) => {
    state.cart.updating = data;
};