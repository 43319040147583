<template>
    <div class="layout-header-social hidden md:block">
        <section class="flex justify-end flex-wrap" v-if="isLogged">
            <a href="/moje/konto" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" :label="loggedUsername" icon="fas fa-user" text />
            </a>
            <a href="/moje/udaje" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Údaje" icon="fas fa-address-book" text />
            </a>
            <a href="/moje/objednavky" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Objednávky" icon="fas fa-receipt" text />
            </a>
            <a href="/moje/oblubene" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Obľúbené" icon="fas fa-heart" text />
            </a>
            <a href="/moje/raketak" class="flex items-center justify-center no-underline" v-if="hasRole('ROLE_STORY')">
                <Button type="button" size="small" label="Rakeťák" icon="fas fa-book-reader" text />
            </a>
            <a href="/moje/influencer-kupony" class="flex items-center justify-center no-underline" v-if="hasRole('ROLE_INFLUENCER')">
                <Button type="button" size="small" label="Influencer" icon="fas fa-comments-dollar" text />
            </a>
            <a href="/moje/body" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Body" :badge="pointCount" icon="fas fa-coins" text />
            </a>
            <a href="/moje/odhlasenie" class="flex items-center justify-center no-underline ml-5">
                <Button type="button" size="small" label="Odhlásiť" icon="fas fa-sign-out-alt" text />
            </a>
        </section>
        <section class="flex justify-end flex-wrap" v-else>
            <a href="/oblubene" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Obľúbené" icon="fas fa-heart" text />
            </a>
            <a href="/registracia" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Registrovať" icon="fas fa-user-plus" text />
            </a>
            <a href="/moje/prihlasenie" class="flex items-center justify-center no-underline">
                <Button type="button" size="small" label="Prihlásiť" icon="fas fa-sign-in-alt" text />
            </a>
<!--            <app-theme-switcher class="flex items-center justify-center ml-3"></app-theme-switcher>-->
        </section>
    </div>

    <div class="layout-header">
        <section class="flex justify-between flex-nowrap gap-4">
            <div class="flex items-center justify-center md:hidden">
                <Button icon="fas fa-bars" @click="sidebarVisible = true" />
            </div>

            <div class="flex items-center justify-center my-2 md:my-3">
                <a class="layout-header-logo" href="/">
                    <img class="h-[2rem] md:h-[3rem]" src="/images/logo4.png" alt="noezon" />
                </a>
            </div>
            <div class="items-center justify-center my-3 md:mx-8 flex-auto hidden md:flex">
                <form action="/hladaj" method="POST" class="w-full">
                    <InputGroup>
                        <InputText :placeholder="$t('word.search')" class="header-search" name="q" pt:root:name="q" size="large" />
                        <Button :label="$t('action.search')" icon="fas fa-search" size="large" type="submit" />
                    </InputGroup>
                </form>
            </div>
            <div class="flex items-center justify-center my-2 md:my-3">
                <header-cart></header-cart>
            </div>
        </section>
    </div>

    <div class="layout-header layout-header-menu hidden md:block" v-if="!isLoadingItems">
        <section class="relative">
            <app-menu-top :items="items"></app-menu-top>
        </section>
    </div>

    <Drawer v-model:visible="sidebarVisible">
        <app-menu-panel :items="items" :current="props.categoryId"></app-menu-panel>
    </Drawer>
</template>

<script setup>
import {onBeforeMount, ref} from "vue";
import CategoryRepository from "../../repository/front/categoryRepository";
import ConsumerRepository from "../../repository/front/consumerRepository";
import _ from "lodash";

const props = defineProps(['categoryId', 'categoryParentId']);
const sidebarVisible = ref(false);
const isLogged = ref(false);
const loggedUsername = ref(null);
const items = ref([]);
const isLoadingItems = ref(true);
const pointCount = ref(0);

const fetchCountPoint = async () => {
    let count = 0;

    try {
        const {data} = await ConsumerRepository.countPoint();
        count = data;
    } catch (e) {

    }

    pointCount.value = '' + count;
};

const hasRole = (role) => {
    return _.includes(window.defaults.loggedRoles, role);
};

onBeforeMount(async () => {
    isLogged.value = window.defaults.isLogged;
    loggedUsername.value = window.defaults.loggedUsername;

    isLoadingItems.value = true;
    const {data} = await CategoryRepository.findAll();
    items.value = _.sortBy(data, ['position']);

    if (isLogged.value) {
        await fetchCountPoint();
    }

    isLoadingItems.value = false;
});
</script>

<style>
</style>