<template>
    <Card id="form-registration">
        <template #title>{{ $t('sign.title.in') }}</template>
        <template #content>
            <FloatLabel class="mt-5">
                <InputText id="username" v-model="email" class="w-full" />
                <label for="username">{{ $t('sign.form.username') }}</label>
            </FloatLabel>

            <FloatLabel class="mt-5">
                <Password v-model="password" :pt="{root: {class: 'w-full'}, 'input': {class: 'w-full'}}" :feedback="false" toggleMask @keydown.enter="submitForm" />
                <label for="username">{{ $t('sign.form.password') }}</label>
            </FloatLabel>
        </template>
        <template #footer>
            <div class="flex gap-3 mt-3">
                <a href="#" class="w-full">
                    <Button :label="$t('sign.action.submitIn')" @click.prevent="submitForm" class="w-full" />
                </a>
            </div>
            <div>
                <Divider />
            </div>
            <div class="flex gap-3 mt-3">
                <a href="/connect/facebook" class="w-full" v-if="props.facebook">
                    <Button label="Facebook" class="w-full" />
                </a>
                <a href="/connect/google" class="w-full" v-if="props.google">
                    <Button label="Google" class="w-full" />
                </a>
            </div>
            <div>
                <Divider />
            </div>
            <div class="flex justify-center">
                <a href="/obnovenie">{{ $t('sign.action.recovery') }}</a>
            </div>
            <div class="flex justify-center mt-3">
                <a href="/registracia">{{ $t('sign.action.missingAccount') }}</a>
            </div>
        </template>
    </Card>

    <Message severity="error" v-if="error" class="mt-3">{{ error }}</Message>
</template>

<script setup>
import { ref } from "vue";
import SecurityRepository from "../../repository/front/securityRepository";
import { useI18n } from 'vue-i18n';
const { t } = useI18n();

const props = defineProps({
    facebook: {
        type: Boolean,
        default: true,
    },
    google: {
        type: Boolean,
        default: true,
    },
});
const email = ref();
const phone = ref();
const password = ref();
const passwordRepeat = ref();
const error = ref();

const submitForm = async () => {
    try {
        const queryString = window.location.search;
        const urlParams = new URLSearchParams(queryString);
        const type = urlParams.get('type');

        try {
            await SecurityRepository.signIn(email.value, password.value, type);
        } catch (e) {
            error.value = e.response?.data?.message || t('sign.message.unauthorized');
            return;
        }

        window.location.href = `/moje/konto`;
    } catch (e) {
        error.value = t('sign.message.unauthorized');
    }
};
</script>

<style scoped>

</style>