import Repository from "./Repository";

const resource = "/api/purchase";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(hash) {
        return Repository.get(`${resource}/${hash}`);
    },
}