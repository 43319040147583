<template>
    <div v-if="loading">
        <Skeleton style="max-width: 640px; height: 350px;" />
    </div>
    <Galleria
        :value="items"
        :responsiveOptions="responsiveOptions"
        :numVisible="5"
        containerStyle="max-width: 640px;"
        :showThumbnails="false"
        :showIndicators="true"
        :changeItemOnIndicatorHover="true"
        :showIndicatorsOnItem="true"
        indicatorsPosition="bottom"
        :showItemNavigators="true"
        :showItemNavigatorsOnHover="true"
        v-else>
        <template #item="slotProps">
            <img :src="slotProps.item.itemImageSrc" :alt="slotProps.item.alt" style="width: 100%; height: 100%;" />
        </template>
    </Galleria>
</template>

<script setup>
import {ref, computed, onMounted} from "vue";
import ProductRepository from "../../repository/front/productRepository";
import MobileDetect from "mobile-detect";

const props = defineProps(['productId']);
const items = ref([]);
const loading = ref(true);

const transformItem = (item) => {
    return {...item, itemImageSrc: item.thumbnail_url, thumbnailImageSrc: item.thumbnail_url, src: item.text};
};

const isMobile = () => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
}

onMounted(async () => {
    const {data} = await ProductRepository.findImages(props.productId);
    items.value = data.map(transformItem);
    loading.value = false;
});

const responsiveOptions = ref([
    {
        breakpoint: '1300px',
        numVisible: 4,
    },
    {
        breakpoint: '575px',
        numVisible: 2,
    }
]);
</script>

<style lang="scss">
.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators {
    background: transparent;

}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator.p-highlight button {
    background: var(--primary-color);
}

.p-galleria.p-galleria-indicator-onitem .p-galleria-indicators .p-galleria-indicator button {
    background: rgba(0, 0, 0, 0.2);
}

.p-galleria .p-galleria-item-nav:not(.p-disabled) {
    color: var(--primary-color);
}

.p-galleria .p-galleria-item-nav:hover {
    color: var(--primary-color-hover);
}
</style>