<template>
    <div v-if="giftItems !== null" class="uk-alert-danger" uk-alert>
        <strong>{{ $t('message.voucherGiftWarning') }}:</strong> {{ giftItems }}
    </div>
</template>

<script>
import {mapGetters} from 'vuex'

export default {
    props: ['id'],
    computed: {
        ...mapGetters([
            'giftItems',
        ])
    }
}
</script>

<style scoped>
.gift-items {
    display: inline-flex;
    margin: 0;
}

.gift-items li {
    list-style-type: none;
    background-color: transparent !important;
    border: none !important;
}

.gift-items li:before {
    content: ", ";
    padding: 0;
}

.gift-items li:first-child:before {
    content: "";
}
</style>