<template>
    <Button
        severity="danger"
        v-clipboard:copy="copyText"
        @click="animate()"
        :class="{'animate__animated animate__fadeOut': animated}"><slot></slot></Button>
</template>

<script>
export default {
    props: ['copy', 'target', 'notification'],

    data() {
        return {
            animated: false,
            copyText: null
        }
    },

    methods: {
        animate: function () {
            this.animated = true;
            setTimeout(() => this.animated = false, 1000);

            if (this.notification) {
                // UIkit.notification(this.$t('flash.copied'), 'success');
            }
        }
    },

    created() {
        if (!this.copy && this.target) {
            this.copyText = $(this.target).val();
        } else {
            this.copyText = this.copy;
        }
    }
}
</script>

<style scoped="true">

</style>