<template>
    <div class="flex flex-col gap-8">
        <FloatLabel>
            <InputText id="tracker_finder_purchase" class="w-full" v-model="purchase" />
            <label for="tracker_finder_purchase">Číslo objednávky</label>
        </FloatLabel>

        <FloatLabel>
            <InputText id="tracker_finder_phone" class="w-full" v-model="phone" />
            <label for="tracker_finder_phone">Telefónne číslo</label>
        </FloatLabel>

        <Button type="submit" label="Nájsť zásielku" severity="Danger" class="w-full" @click.prevent="submit" />

        <Message severity="error" closable v-if="error">{{ error }}</Message>
    </div>
</template>

<script>
import {RepositoryFactory} from "../repository/RepositoryFactory";

const TrackerFinderRepository = RepositoryFactory.get('trackerFinder');

export default {
    data() {
        return {
            purchase: null,
            phone: null,
            error: null,
        }
    },

    methods: {
        submit: async function () {
            this.error = null;

            try {
                const {data} = await TrackerFinderRepository.find(this.purchase, this.phone);

                window.location.href += '/' + data.order;
            } catch (e) {
                this.error = e.response.data.message;
            }
        },
    },
}
</script>

<style scoped>

</style>