<template>
    <div id="header-cart" class="block md:hidden">
        <a :href="url" class="uk-button button-mobile">
            <div>
                <i class="fa fa-shopping-cart" :class="{'animate__animated animate__rubberBand': cartAnimated}"></i>
                <div class="counter">{{ cartQuantity }}</div>
            </div>
        </a>
    </div>

    <div id="header-cart" class="hidden md:block" :class="{'animate__animated animate__rubberBand': cartAnimated}">
        <Button
            size="large"
            :label="$t('word.headerInCart', {
                quantity: cartQuantity,
                price: cartSubtotal.toFixed(currencyRound),
                currencySymbol: currencySymbol
            })"
            icon="fa fa-shopping-cart"
            v-if="cartQuantity > 0"
            @click="open" />

        <Button size="large" :label="$t('word.cartEmpty')" icon="fa fa-shopping-cart" disabled v-else />
    </div>
</template>

<script>
import {mapActions, mapGetters} from 'vuex';
import store from '../vuex/store';
import axios from 'axios';

export default {
    store,

    props: {
        wholesale: {
            type: Boolean,
            default: false
        }
    },

    data() {
        return {
            url: window.defaults.cart.url_index,
        }
    },

    computed: {
        ...mapGetters([
            'cartQuantity',
            'cartSubtotal',
            'isMobile',
            'cartAnimated',
            'currencySymbol',
            'currencyRound'
        ])
    },

    created() {
        this.getStatus();
    },

    methods: {
        open: function () {
            window.location.href = this.url;
        },

        getStatus: function () {
            axios.get(`/api/cart/status/${this.wholesale}`)
                .then((response) => {
                    this.cartItemSet(response.data);
                });
        },

        ...mapActions([
            'cartItemSet',
            'cartAnimate',
        ]),
    }
}
</script>

<style scoped>
#header-cart {
    //display: table;
    float: right;
    white-space: nowrap;
}

.cart-button {
    display: table-cell;
    height: 100%;
    vertical-align: middle;
    background-color: var(--primary-color);
    color: var(--primary-color-text);
}

.button-mobile {
    color: #fff;
    font-size: 20pt;
}

.button-mobile > div {
    position: relative;
}

.button-mobile:hover {
    color: #fff;
}

.counter {
    position: absolute;
    top: -5px;
    right: -10px;
    background-color: #e83a3a;
    color: #ffffff;
    border-radius: 22px;
    width: 22px;
    height: 22px;
    line-height: 22px;
    font-size: 0.875rem;
    text-align: center;
}
</style>
