<script setup>
import eventBus from '../../eventBus';
import {computed, ref, watch} from "vue";
import { RepositoryFactory } from "../../repository/RepositoryFactory";

const CartRepository = RepositoryFactory.get('cart');

const props = defineProps(['item', 'purchasePrice', 'currencyRound', 'currencySymbol', 'selected']);

const isSelected = ref(props.selected ?? false);

const imageSrc = (item) => {
    return `/data/gift/image/${item.item.image}`;
};

const getPercentage = (val1, val2) => {
    return (100 * val1) / val2;
};

const getRemains = (val1, val2) => {
    return parseFloat(val2 - val1).toFixed(props.currencyRound.value);
};

const select = () => {
    eventBus.giftSelect = props.item.id;
};

const isAvailable = computed(() => props.item.amount_min < props.purchasePrice && props.item.amount_max > props.purchasePrice);

const remains = computed(() => props.item.amount_min < props.purchasePrice && props.item.amount_max > props.purchasePrice);

watch(() => eventBus.giftSelect, async (giftId) => {
    isSelected.value = props.item.id === giftId;
});

watch(() => props.purchasePrice, async () => {
    if (!isAvailable.value) {
        isSelected.value = false;
    }
});
</script>

<template>
    <div class="p-2 border rounded flex flex-col w-full h-full" style="min-height: 300px" :class="{ 'shadow-5': isSelected, 'bg-red-50': isSelected, 'bg-white': !isSelected, 'opacity-75': !isAvailable }">
        <div class="bg-slate-50 flex justify-center border-round p-2 relative">
            <Image class="border-round w-full" :src="imageSrc(item)" :alt="item.item.value" style="max-width: 300px" preview v-if="item.item.image" />
        </div>
        <div class="flex grow flex-col pt-4">
            <div class="text-lg font-medium mb-3">
                {{ item.item.value }}
            </div>
            <div class="flex grow flex-col justify-end">
                <div class="text-gray-800 mb-2">
                    {{ $t('cart.message.giftMotivation', {'min': item.amount_min, 'currencySymbol': props.currencySymbol, 'price': item.item.price}) }}
                </div>
                <Button :label="$t('cart.action.selectedGift')" class="w-full whitespace-nowrap" disabled v-if="isSelected"></Button>
                <Button :label="$t('cart.action.selectGift')" class="w-full whitespace-nowrap" outlined @click="select" v-else-if="isAvailable"></Button>
                <ProgressBar
                    :value="getPercentage(props.purchasePrice, item.amount_min)"
                    class="w-full"
                    style="height: 37px;"
                    v-else>
                    {{ $t('cart.message.giftMotivationRemains', {'price': getRemains(props.purchasePrice, item.amount_min), 'currencySymbol': props.currencySymbol}) }}
                </ProgressBar>
            </div>
        </div>
    </div>
</template>

<style scoped>

</style>