export default {
    mounted(el) {
        el.addEventListener('keydown', (event) => {
            if (event.key === 'Enter') {
                event.preventDefault();
                const form = el.closest('form');
                if (form) {
                    const focusableElements = Array.from(form.querySelectorAll('input, textarea, select, [tabindex]:not([tabindex="-1"])'))
                        .filter(element => element.tagName !== 'FIELDSET' && element.tagName !== 'LEGEND' && element.tagName !== 'A');
                    const index = focusableElements.indexOf(el);
                    if (index > -1 && index < focusableElements.length - 1) {
                        focusableElements[index + 1].focus();
                    }
                }
            }
        });
    }
};
