<script setup>
import {onMounted, ref} from "vue";
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";

const FavoriteRepository = RepositoryFactory.get('favorite');

const props = defineProps(['blog-id']);

const isFavorite = ref(false);

const toggle = function () {
    isFavorite.value = !isFavorite.value;

    if (window.defaults.isLogged) {
        if (isFavorite.value) {
            FavoriteRepository.addBlog(props.blogId);
        } else {
            FavoriteRepository.removeBlog(props.blogId);
        }
    } else {
        const STORAGE_FAVORITE = 'blog.favorites';
        let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE)) || [];

        const index = favorites.indexOf(props.blogId);
        if (index > -1) {
            favorites.splice(index, 1);
        } else {
            favorites.push(props.blogId);
        }

        localStorage.setItem(STORAGE_FAVORITE, JSON.stringify(favorites));
    }
};

const hasInFavorites = async function() {
    if(window.defaults.isLogged) {
        const {data} = await FavoriteRepository.hasBlog(props.blogId);
        isFavorite.value = JSON.parse(data);
    } else {
        const STORAGE_FAVORITE = 'blog.favorites';
        let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE)) || [];
        isFavorite.value = favorites.includes(props.blogId);
    }
};

onMounted(() => {
    hasInFavorites();
});
</script>

<template>
    <Button
        @click="toggle();"
        :icon="(isFavorite ? 'fa' : 'far') + ' fa-heart'"
        class="ml-2 px-3"
        outlined />
</template>

<style scoped>

</style>