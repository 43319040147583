<template>
    <Panel>
        <template #header>
            <div class="flex items-center gap-2">
                <Avatar :image="props.avatar" :label="nameInitials" :icon="!nameInitials ? 'fas fa-user' : null" :style="'background-color: ' + randomColor" />
                <span class="font-bold">{{ props.name }}</span>
            </div>
        </template>
        <p class="py-1 text-gray-600">
            <span v-if="props.recommends">Odporúča obchod</span>
            <span v-else>Neodporúča obchod</span>
        </p>
        <p class="py-1">
            <Rating v-model="props.rating" readonly disabled :cancel="false" />
        </p>
        <p class="py-1" v-if="props.text">
            {{ props.text }}
        </p>
        <p class="py-1 md:flex md:gap-4" v-if="(pros && pros.length > 0) || (cons && cons.length > 0)">
            <ul class="md:flex-1 list-none text-green-600 px-0" v-if="pros && pros.length > 0">
                <li v-for="pro in pros"><i class="fas fa-plus-circle"></i> {{ pro }}</li>
            </ul>
            <ul class="md:flex-1 list-none text-red-600 px-0" v-if="cons && cons.length > 0">
                <li v-for="con in cons"><i class="fas fa-minus-circle"></i> {{ con }}</li>
            </ul>
        </p>
    </Panel>
</template>

<script setup>
import {computed} from "vue";

const props = defineProps({
    name: {
        type: String,
    },
    text: {
        type: String,
    },
    pros: {
        type: [Array, String, null],
    },
    cons: {
        type: [Array, String, null],
    },
    rating: {
        type: Number,
    },
    recommends: {
        type: Boolean,
    },
    avatar: {
        type: String,
    },
});

const pros = computed(() => _.isArray(props.pros) ? props.pros : [props.pros]);
const cons = computed(() => _.isArray(props.cons) ? props.cons : [props.cons]);

const nameInitials = computed(() => {
    if(_.isNil(props.name)) {
        return null;
    }

    let initials = [...props.name.matchAll(new RegExp(/(\p{L}{1})\p{L}+/, 'gu'))] || [];

    return (
        (initials.shift()?.[1] || '') + (initials.pop()?.[1] || '')
    ).toUpperCase();
});

const randomColor = computed(() => {
    return "hsl(" + 360 * Math.random() + ',' +
        (25 + 70 * Math.random()) + '%,' +
        (85 + 10 * Math.random()) + '%)'
});
</script>

<style scoped>

</style>