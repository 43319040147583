import Repository from "./Repository";

const resource = "/api/blog";

export default {
    findAll() {
        return Repository.get(`${resource}`);
    },

    findBy(payload) {
        return Repository.post(`${resource}`, payload);
    },

    get(id) {
        return Repository.get(`${resource}/${id}`);
    },

    favorite(id) {
        // TODO
    },

    unfavorite(id) {
        // TODO
    },

    filterChoices(categoryId) {
        let payload = new FormData();
        payload.append('category_id', categoryId);

        return Repository.post(`${resource}/filter`, payload);
    },

    filterCount(categoryId, filterData) {
        let payload = new FormData();
        payload.append('category', categoryId);
        payload.append('filter', JSON.stringify(filterData));
        payload.append('_json', true);

        return Repository.post(`${resource}/filter-count`, payload);
    },
}