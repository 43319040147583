<template>
    <Card v-if="!isLoading">
        <template #title>
            <div v-html="title"></div>
        </template>
        <template #content>
            <DataTable :value="votes" sortField="sum" :sortOrder="-1">
                <Column field="name" header="Nazov" sortable class="w-3/4"></Column>
                <Column field="sum" header="Hlasy" sortable class="w-1/4"></Column>
            </DataTable>
        </template>
    </Card>

    <Card style="overflow: hidden" v-if="isLoading">
        <ProgressSpinner />
    </Card>
</template>

<script>
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";

const VoteRepository = RepositoryFactory.get('vote');

export default {
    props: ['title', 'group'],

    data() {
        return {
            isLoading: true,
            votes: [],
        }
    },

    methods: {
        fetch: async function () {
            this.isLoading = true;

            const {data} = await VoteRepository.chart(this.group);
            this.votes = data;

            this.isLoading = false;
        },
    },

    mounted() {
        this.fetch();
    }
}
</script>

<style scoped>

</style>