<template>
    <InputText type="text" v-model="search" class="mb-5" v-if="props.search" />

    <DataView
        :value="filteredItems"
        :lazy="false"
        :first="first"
        :rows="rows"
        :total-records="totalRecords"
        :layout="layout"
        :paginator="paginator"
        @page="onPage($event)">
        <template #grid="slotProps">
            <div class="flex flex-wrap my-4 justify-center">
                <div v-for="(item, index) in slotProps.items" :key="index" class="w-1/2 md:w-1/2 lg:w-1/3 xl:w-1/4">
                    <app-blog-grid-item
                        :item="item"
                        :selected="false"
                        :setting="null"
                        :favorite="false"
                        :zoom="false"
                        class="h-full p-1">
                    </app-blog-grid-item>
                </div>
            </div>
        </template>
    </DataView>
</template>

<script setup>
import {RepositoryFactory} from "../../repository/front/RepositoryFactory";
import {onMounted, ref, watch} from "vue";
import eventBus from "../../eventBus";
import _ from "lodash";

const BlogRepository = RepositoryFactory.get('blog');

const props = defineProps({
    blogId: Array,
    items: {
        type: [String, Array, Object, null],
    },
    page: {
        type: Number,
        default: 1,
    },
    limit: {
        type: Number,
        default: 12,
    },
    paginator: {
        type: Boolean,
        default: true,
    },
    totalRecords: {
        type: Number,
        default: 0,
    },
    search: {
        type: Boolean,
        default: false,
    },
});

const items = ref([]);
const filteredItems = ref([]);
const filterCriteria = ref({});
const search = ref();
const rows = ref(props.limit ? props.limit : 100);
const first = ref((props.page - 1) * rows.value);
const totalRecords = ref(props.totalRecords ? props.totalRecords : 0);
const layout = ref('grid');
const paginator = ref(props.paginator);
const isLoading = ref(false);

watch(() => eventBus.blogFilterCriteria, async (data) => {
    filterCriteria.value = data;
    await fetchData();
});

watch(search, (newSearch) => {
    filteredItems.value = items.value.filter((item) =>
        item.title
            .normalize('NFD')
            .replace(/[\u0300-\u036f]/g, "")
            .toLowerCase()
            .includes(newSearch.normalize('NFD').replace(/[\u0300-\u036f]/g, "").toLowerCase())
        ||
        item.id == newSearch
    );
});

const fetchData = async () => {
    let payload = new FormData();

    if(!_.isNil(props.blogId)) {
        payload.append('blog_id', props.blogId);
    }

    if(!_.isEmpty(filterCriteria.value)) {
        payload.append('filter_criteria', JSON.stringify(filterCriteria.value));
    }

    const {data} = await BlogRepository.findBy(payload);

    items.value = data.items;
    filteredItems.value = data.items;
};

const onPage = async (event) => {
    window.scrollTo({top: 0, behavior: 'smooth'});
    first.value = event.first;

    // let page = (first.value / rows.value) + 1;
    // changePageNumber(page);

    // first.value = event.first;
    // await fetchData();
    // await fetchFavorite();
    //
    // if (_.isNil(props.productParentId)) {
    //     let page = (first.value / rows.value) + 1;
    //     changePageNumber(page);
    // }
    //
    // isLoading.value = false;
};

const changePageNumber = (newPageNumber) => {
    const url = new URL(window.location.href);
    const pathname = url.pathname;
    const pathSegments = pathname.split('/').filter(segment => segment !== '');

    if (pathSegments.length >= 2) {
        pathSegments[1] = newPageNumber;
    } else {
        pathSegments.push(newPageNumber);
    }

    url.pathname = '/' + pathSegments.join('/');
    window.history.pushState({}, '', url);
}

onMounted(async () => {
    if(_.isEmpty(props.items) && (!_.isNil(props.blogId) || !_.isEmpty(filterCriteria.value))) {
        await fetchData();
    } else {
        items.value = props.items;
        filteredItems.value = props.items;
    }
});
</script>