<template>
    <div>
        <Button severity="danger" class="white-space-nowrap" @click="log">
            <i class="fas fa-qrcode pr-2"></i> QR platba
        </Button>

        <Dialog v-model:visible="isVisible" modal :header="bankName" :style="{ width: '30rem' }">
            <div class="text-center">
                <div>{{ bankNumber }} <strong>SWIFT:</strong> {{ bankSwift }}</div>
                <img :src="qr">
            </div>

            <div class="flex justify-content-end gap-2">
                <Button type="button" label="Zavrieť" severity="secondary" @click="isVisible = false"></Button>
            </div>
        </Dialog>
    </div>
</template>

<script>
import {RepositoryFactory} from "../repository/RepositoryFactory";

const CartRepository = RepositoryFactory.get('cart');

export default {
    props: ['bankNumber', 'bankName', 'bankSwift', 'purchaseId', 'qr'],

    data() {
        return {
            isVisible: false,
        }
    },

    methods: {
        log: function () {
            this.isVisible = true;
            CartRepository.log(this.purchaseId, 'payment', 'Zvoleny sposob platby QR - ' + this.bankName, 0);
        }
    },
}
</script>

<style scoped="true">

</style>