<template>
    <div class="absolute left-0 ml-2 w-full">
        <MegaMenu :model="productCategories" class="hidden sm:flex" breakpoint="576px" :pt="{ menuitem: { class: '' }, submenu: { class: 'flex flex-wrap flex-col max-h-[30rem] overflow-scroll' }, submenuHeader: { class: 'hidden' } }">
            <template #item="{ item }">
                <a :href="'/' + item.slug" @click.prevent v-if="item.root" v-ripple class="flex cursor-pointer px-3 py-2 overflow-hidden relative font-semibold uppercase">
                    <span>{{ item.name }}</span>
                </a>
                <div v-else-if="item.visible_children && item.visible_children.length > 0" class="cursor-pointer">
                    <a :href="'/' + item.slug" class="block">
                        <Button :label="item.name" severity="secondary" text class="w-[15rem] text-900 text-left justify-start" pt:label:class="font-bold" />
                    </a>

                    <ul style="list-style: none;" class="p-0">
                        <li v-for="subItem in item.visible_children">
                            <a :href="'/' + subItem.slug" class="block">
                                <Button :label="subItem.name" size="small" severity="secondary" text class="w-[15rem] text-left justify-start" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <a :href="'/' + item.slug">
                        <Button :label="item.name" size="small" severity="secondary" text class="w-[15rem] text-left justify-start" pt:label:class="font-bold" />
                    </a>
                </div>
            </template>
        </MegaMenu>
    </div>
    <div class="absolute right-0 mr-2">
        <MegaMenu :model="blogCategories" class="hidden sm:flex" breakpoint="576px" :pt="{ overlay: { class: 'w-[35rem] left-auto right-0' }, menuitem: { class: '' }, submenu: { class: 'flex flex-wrap flex-col max-h-[30rem] overflow-scroll' }, submenuHeader: { class: 'hidden' } }">
            <template #item="{ item }">
                <a :href="'/' + item.slug" @click.prevent v-if="item.root" v-ripple class="flex cursor-pointer px-3 py-2 overflow-hidden relative font-semibold uppercase">
                    <span>{{ item.name }}</span>
                </a>
                <div v-else-if="item.visible_children && item.visible_children.length > 0" class="cursor-pointer">
                    <a :href="item.path" class="block">
                        <Button :label="item.name" severity="secondary" text class="w-[15rem] text-900 text-left justify-start" pt:label:class="font-bold" />
                    </a>

                    <ul style="list-style: none;" class="p-0">
                        <li v-for="subItem in item.visible_children">
                            <a :href="subItem.path" class="block">
                                <Button :label="subItem.name" size="small" severity="secondary" text class="w-[15rem] text-left justify-start" />
                            </a>
                        </li>
                    </ul>
                </div>
                <div v-else>
                    <a :href="item.path">
                        <Button :label="item.name" size="small" severity="secondary" text class="w-[15rem] text-left justify-start" />
                    </a>
                </div>
            </template>
        </MegaMenu>
    </div>
</template>

<script setup>
import {ref, onBeforeMount} from "vue";
import _ from "lodash";

const props = defineProps(['items']);

const productCategories = ref([]);
const blogCategories = ref([]);

const transform = (items) => {
    items.forEach((item) => {
        if(item.visible_children.length > 0) {
            if (item.items === undefined) {
                item.items = [];
            }
            // item.items.push([{'items': item.visible_children}]);
            item.items.push([{'items': _.filter(item.visible_children, {'visible_menu': true, 'visible_menu_temp': true})}]);
        }
    });

    return _.sortBy(items, ['position']);
};

const sortRecursive = (data) => {
    const sortedData = _.sortBy(data, 'position');

    _.forEach(sortedData, (item) => {
        if (item.visible_children && item.visible_children.length > 0) {
            item.visible_children = sortRecursive(item.visible_children);
        }
    });

    return sortedData;
}

onBeforeMount(async () => {
    let sortedData = sortRecursive(props.items);

    productCategories.value = transform(_.filter(sortedData, {'type':'product'}));
    blogCategories.value = transform(_.filter(sortedData, {'type':'blog'}));
});
</script>