<template>
    <div>
        <Button @click="modalOpen()" icon="fas fa-eye" :label="text" severity="contrast" class="ml-2 px-2" />

        <Dialog v-model:visible="isOpen" modal :header="$t('watchdog.message.setWatchdog')" :style="{ 'width': '90vw', 'max-width': '40rem' }">
            <span class="p-text-secondary block mb-5">{{ $t('watchdog.message.inform') }}</span>
            <div class="flex items-center gap-3 mb-3">
                <FloatLabel class="w-full">
                    <InputText id="username" class="w-full" v-model="email" />
                    <label for="username">Email</label>
                </FloatLabel>
            </div>
            <div class="flex items-center gap-3 mb-3">
                <Checkbox v-model="isAvailable" inputId="isAvailable" :binary="true" class="flex" />
                <label for="isAvailable" class="flex font-semibold w-[20rem]">{{ $t('watchdog.message.availableMoreThan') }}</label>
                <InputText v-model="availableValue" class="w-full" autocomplete="off" />
            </div>
            <div class="flex items-center gap-3 mb-5">
                <Checkbox v-model="isPrice" inputId="isPrice" :binary="true" />
                <label for="isPrice" class="font-semibold w-[20rem]">{{ $t('watchdog.message.priceLessThan') }}</label>
                <InputText v-model="priceValue" class="w-full" autocomplete="off" />
            </div>
            <div class="mb-5" v-if="error">
                <Message severity="error">
                    {{ error }}
                </Message>
            </div>
            <div class="uk-modal-body" v-if="isSent && !error">
                <Message severity="error">
                    {{ $t('watchdog.message.informByEmail') }}
                </Message>
                <h3 class="uk-text-success">{{ $t('watchdog.message.watchdogSetup') }}</h3>
                <p>{{ $t('watchdog.message.informByEmail') }}</p>
            </div>
            <div class="flex justify-end gap-2">
                <Button type="button" :label="$t('action.close')" severity="secondary" @click="close"></Button>
                <Button type="button" :label="$t('action.accept')" @click="send"></Button>
            </div>
        </Dialog>

        <Toast />
    </div>
</template>

<script>
import {RepositoryFactory} from "../repository/RepositoryFactory";

const WatchdogRepository = RepositoryFactory.get('watchdog');

export default {
    props: ['product', 'price', 'mini', 'cart', 'text'],

    data() {
        return {
            email: null,
            isAvailable: false,
            availableValue: 1,
            isPrice: false,
            priceValue: this.price,
            isSent: false,
            error: null,
            isOpen: false,
        }
    },

    methods: {
        modalOpen: function () {
            this.isOpen = true;
        },

        fixDecimal: function () {
            this.priceValue = this.priceValue.replace(',', '.');
        },

        send: async function () {
            if (!this.isAvailable && !this.isPrice) {
                this.error = "Vyberte o čom chcete byt informovaný";
                return;
            }

            if (this.isPrice && !_.isNil(this.priceValue) && (parseFloat(this.price) < parseFloat(this.priceValue))) {
                this.error = "Cena je nižšia ako vyžadujete";
                return;
            }

            if (_.isNil(this.email)) {
                this.error = "Nezadali ste email";
                return;
            }

            let payload = new FormData();
            payload.append('product', this.product);
            payload.append('email', this.email);
            payload.append('available', this.isAvailable);
            payload.append('available_value', this.availableValue);
            payload.append('price', this.isPrice);
            payload.append('price_value', this.priceValue);

            try {
                await WatchdogRepository.save(payload);
                this.error = null;
                this.isSent = true;

                this.isOpen = false;
                this.$toast.add({ severity: 'success', summary: this.$t('watchdog.message.watchdogSetup'), detail: this.$t('watchdog.message.informByEmail'), life: 3000 });
            } catch (error) {
                this.isSent = false;
                this.error = error.response.data.message;
            }
        },

        close: function () {
            this.isOpen = false;
        },
    }
}
</script>

<style scoped="true">
    .watchdog-cart-button {
        background-color: #555555;
        color: #fff;
        padding: 0 15px;
        margin-right: 20px;
    }

    .watchdog-cart-button:hover {
        background-color: #777777;
    }
</style>