<template>
    <div>
        <div class="rounded flex flex-col w-full h-full bg-white border border-slate-200" :class="{ 'shadow shadow-lg shadow-slate-400': props.selected }">
            <div class="flex justify-center rounded relative">
                <div class="mx-auto relative md:pb-4">
                    <a :href="item.url" v-if="!zoom">
                        <app-lazy-image
                            :src="imageSrc"
                            :alt="item.name"
                            class="rounded w-full"
                            placeholder="/images/default.jpg" />
                    </a>
                    <Image v-else class="border-round w-full" :src="imageSrc" :alt="item.name" style="max-width: 300px" preview />
                    <div class="absolute top-2 left-2">
                        <Tag :value="item.availability_text" :severity="item.availability_severity" v-if="item.availability_text"></Tag>
                        <Tag :value="item.quantity" :severity="item.availability_severity" class="ml-1" v-if="item.type === 'single' && item.quantity !== 0 && item.quantity !== ''"></Tag>
                        <template v-for="itemTag in item.tags_public">
                            <Tag :value="itemTag.c_value" severity="info" class="ml-1" v-if="itemTag.c_type === 'ribbon'"></Tag>
                        </template>
                    </div>
                    <Tag icon="fa-brands fa-youtube" severity="danger" v-tooltip.bottom="'Video'" class="absolute" style="right: 4px; top: 4px" v-if="item.has_videos === true"></Tag>
                    <div class="absolute bottom-0 left-2 text-slate-500 text-xs">ID{{ item.id }}</div>
                    <div v-if="props.visibleCode" class="md:absolute md:bottom-0 right-2" style="font-size: 8pt;">{{ item.code_public }}</div>
                </div>
            </div>
            <div class="flex grow flex-col pt-4 p-2">
                <div class="flex flex-row justify-between items-start gap-2">
                    <div>
                        <a :href="item.url" class="text-lg font-semibold mt-1 no-underline leading-none !text-black" v-if="!zoom">{{ item.name }}</a>
                        <span class="text-lg font-semibold mt-1 no-underline leading-none !text-black" v-else>{{ item.name }}</span>
                    </div>
                </div>
                <div class="flex grow flex-col justify-end">
                    <div class="my-3">
                        <span class="text-2xl font-semibold text-primary" :class="{'price-not-b2b': item?.price_is_b2c === true}" v-tooltip.bottom="item?.price_is_b2c === true ? 'Maloobchodná cena' : ''">{{ item.price }} {{ item.currencySymbol }}</span>
                        <span class="text-lg font-semibold text-color-secondary line-through ml-3" v-if="item.priceOld">{{ item.priceOld }} {{ item.currencySymbol }}</span>
                    </div>
                    <div class="flex content-end justify-end flex-nowrap">
                        <app-cart-button
                            :product-id="item.id"
                            :product-name="item.name"
                            :product-price="item.price"
                            class="flex-1"
                            v-if="item.visible === 2 && (item.type === 'single' || item.type === 'set')">
                            <i class="fa fa-shopping-cart"></i>
                        </app-cart-button>

                        <app-watchdog
                            :product="item.id"
                            :price="item.price"
                            cart="true"
                            v-else-if="item.type === 'single' || item.type === 'set'">
                        </app-watchdog>

                        <a :href="item.url" v-if="item.type === 'variant'" class="w-full">
                            <Button label="Varianty" :badge="''+item.children_count" :disabled="item.inventoryStatus === 'OUTOFSTOCK'" class="w-full whitespace-nowrap"></Button>
                        </a>

                        <div class="flex content-end flex-wrap">
                            <app-favorite-product
                                :product-id="item.id"
                                :favorite="props.favorite">
                            </app-favorite-product>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script setup>
import AppFavoriteProduct from "./AppFavoriteProduct.vue";
import {computed, ref} from "vue";

const props = defineProps({
    item: Object,
    selected: {
        type: Boolean,
        default: false,
    },
    setting: {
        type: [Object, null],
    },
    favorite: {
        type: Boolean,
        default: false,
    },
    zoom: {
        type: Boolean,
        default: false,
    },
    visibleCode: {
        type: Boolean,
        default: false,
    },
});

const imageSrc = computed(() => `/data/product/image/medium/sk/${props.item.image_filename}`);
</script>

<style scoped>
a {
    color:  var(--text-color);
}

a:hover {
    color: var(--primary-color);
}

.price-not-b2b {
    background-color: yellow;
}
</style>