<script setup>
import {onBeforeMount, ref} from "vue";
import {RepositoryFactory} from "../../repository/RepositoryFactory";

const CartRepository = RepositoryFactory.get('cart');

const items = ref([]);
const selected = ref();

const fetchGifts = async () => {
    const {data} = await CartRepository.gifts();
    items.value = data;
};

onBeforeMount(async () => {
    await fetchGifts();
});
</script>

<template>
    <div className="flex flex-wrap gap-3">
        <div className="flex items-center" v-for="item in items">
            <!--            <RadioButton v-model="selected" :inputId="'cart-gift-' + item.id" name="cart-gift" :value="item.id" />-->
            <!--            <label :for="'cart-gift-' + item.id" class="ml-2">{{ item.value }}</label>-->
            <Chip :label="item.value"/>
        </div>
    </div>
</template>

<style scoped>

</style>