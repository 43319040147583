import { createStore } from 'vuex'
import * as actions from './actions';
import * as mutations from './mutations';
import * as getters from './getters';
import createMutationsSharer from 'vuex-shared-mutations';
import MobileDetect from 'mobile-detect';

// const app = createApp({});
//
// Vue.use(Vuex);

let md = new MobileDetect(window.navigator.userAgent);

const state = {
    currency: window.defaults.currency,
    currencySymbol: window.defaults.currencySymbol,
    cart: {
        quantity: window.defaults.cart.quantity,
        total: window.defaults.cart.total,
        items: window.defaults.cart.items,
        services: window.defaults.cart.services,
        animated: false,
        updating: false
    },
    shipping: {
        items: window.defaults.shipping.items,
        companies: window.defaults.shipping.companies,
        payments: window.defaults.shipping.payments,
        price: 0,
        payment: window.defaults.shipping.payment,
        company: window.defaults.shipping.company,
        post: window.defaults.shipping.post,
        package_address: window.defaults.shipping.package_address,
        package_location: window.defaults.shipping.package_location,
    },
    gift: {
        items: null
    },
    motivations: window.defaults.motivations,
    isMobile: md.mobile()
};

export default createStore({
    state,
    getters,
    mutations,
    actions,
    // plugins: [createMutationsSharer({
    //     predicate: [
    //         'cartSetItems',
    //     ]
    // })],
    ready(){

    }
})