<template>
    <Galleria
        :value="visibleItems"
        :numVisible="5"
        :showThumbnails="false"
        :showIndicators="true"
        :changeItemOnIndicatorHover="true"
        :showIndicatorsOnItem="true"
        indicatorsPosition="bottom"
        :circular="true"
        :autoPlay="true"
        mask-class="w-full"
        container-class="w-full"
        class="w-full"
        v-if="!isLoading">
        <template #item="slotProps">
            <a :href="slotProps.item.url" class="promotion-click w-full h-full" :data-promotion-ecommerce="slotProps.item.ecommerce">
                <img :src="'data/files/' + slotProps.item.file.path" :alt="slotProps.item.file.name" class="w-full h-full" />
            </a>
        </template>
    </Galleria>
    <Skeleton class="w-full h-80 md:h-96" v-else></Skeleton>
</template>

<script setup>
import {ref, computed, onMounted} from "vue";
import BannerRepository from "../../repository/front/bannerRepository";
import MobileDetect from "mobile-detect";

// const props = defineProps(['productId']);
const items = ref([]);
const visibleItems = ref([]);
const isLoading = ref(false);

const isMobile = () => {
    let md = new MobileDetect(window.navigator.userAgent);
    return md.mobile();
};

const responsiveOptions = ref([
    {
        breakpoint: '1300px',
        numVisible: 4,
    },
    {
        breakpoint: '575px',
        numVisible: 2,
    }
]);

const fetchBanners = async () => {
    isLoading.value = true;
    const {data} = await BannerRepository.findAll();
    items.value = data;
    visibleItems.value = _.filter(data, {'size': isMobile() ? 'mobile' : 'pc'});
    isLoading.value = false;
};

onMounted(async () => {
    fetchBanners();
});
</script>