<template>
    <div class="flex flex-col h-full">
        <form action="/hladaj" method="POST" class="w-full mb-5">
            <InputGroup>
                <InputText :placeholder="$t('word.search')" name="q" pt:root:name="q" size="small" />
                <Button icon="fas fa-search" size="small" type="submit" />
            </InputGroup>
        </form>

        <div class="flex justify-end content-center items-center" v-if="currentCategory">
            <Button icon="fas fa-home" class="flex mr-3" outlined aria-label="Noezon" @click="goBackTop" />
            <Button icon="fas fa-arrow-left" class="flex mr-3" outlined aria-label="Späť" @click="goBack" />
            <h3 class="flex grow">{{ currentCategory.name }}</h3>
        </div>

        <ul>
            <li v-for="category in currentCategories" :key="category.id">
                <Button :label="category.name" outlined @click="selectCategory(category.id)" class="w-full text-left justify-between" icon="fas fa-arrow-right" iconPos="right" v-if="category.visible_children.length > 0" />

                <a :href="category.path" v-else>
                    <Button :label="category.name" outlined class="w-full text-left justify-between" :raised="category.id == current" />
                </a>
            </li>
        </ul>

        <div class="mt-auto">
            <Divider />

            <div v-if="isLogged">
                <a href="/moje/konto" class="flex items-center justify-center no-underline mt-1">
                    <Button type="button" size="small" :label="loggedUsername" icon="fas fa-user" outlined class="w-full" />
                </a>

                <a href="/moje/odhlasenie" class="flex items-center justify-center no-underline mt-1">
                    <Button type="button" size="small" label="Odhlásiť" text class="w-full" />
                </a>
            </div>
            <div v-else>
                <a href="/oblubene" class="flex items-center justify-center no-underline mb-1">
                    <Button type="button" size="small" label="Obľúbené" icon="fas fa-heart" outlined class="w-full" />
                </a>

                <a href="/moje/prihlasenie">
                    <Button label="Prihlásiť sa" outlined severity="primary" class="w-full" />
                </a>
            </div>

        </div>
    </div>
</template>

<script>
import { ref, computed, watch, onMounted } from 'vue';
import _ from "lodash";

export default {
    props: {
        items: {
            type: Array,
            required: true,
        },
        current: {
            type: String,
            required: true,
        },
    },

    setup(props) {
        const currentCategoryId = ref(props.current);
        const parentStack = ref([]);
        const isLogged = ref(false);
        const loggedUsername = ref(null);

        let sortedData = sortRecursive(props.items);

        const currentCategory = computed(() => {
            return findCategory(sortedData, currentCategoryId.value);
        });

        const currentCategories = computed(() => {
            if (currentCategoryId.value === null) {
                return sortedData;
            }
            const category = findCategory(sortedData, currentCategoryId.value);
            return category ? category.visible_children : props.items;
        });

        watch(() => props.current, (newVal) => {
            currentCategoryId.value = newVal;
            parentStack.value = [];
        });

        function sortRecursive (data) {
            const sortedData = _.sortBy(data, 'position');

            _.forEach(sortedData, (item) => {
                if (item.visible_children && item.visible_children.length > 0) {
                    item.visible_children = sortRecursive(item.visible_children);
                }
            });

            return sortedData;
        }

        function findCategory(categories, id) {
            if (id === null) {
                return null;
            }
            for (const category of categories) {
                if (category.id === id) {
                    return category;
                } else if (category.visible_children && category.visible_children.length) {
                    const found = findCategory(category.visible_children, id);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        }

        function selectCategory(id) {
            const selected = findCategory(sortedData, id);
            if (selected) {
                parentStack.value.push(currentCategoryId.value);
                currentCategoryId.value = id;
            }
        }

        function goBack() {
            if (parentStack.value.length) {
                currentCategoryId.value = parentStack.value.pop();
            } else {
                currentCategoryId.value = null;
            }
        }

        function goBackTop() {
            parentStack.value = [];
            currentCategoryId.value = null;
        }

        function initializeParentStack(currentId) {
            parentStack.value = [];
            let tempId = currentId;
            while (tempId) {
                const parent = findParentCategory(sortedData, tempId);
                if (parent) {
                    parentStack.value.unshift(parent.id);
                    tempId = parent.id;
                } else {
                    // Ak nenájde rodiča, sme na nultej úrovni
                    tempId = null;
                }
            }
        }

        function findParentCategory(categories, childId) {
            for (const category of categories) {
                if (category.visible_children && category.visible_children.some(child => child.id === childId)) {
                    return category;
                } else if (category.visible_children && category.visible_children.length) {
                    const found = findParentCategory(category.visible_children, childId);
                    if (found) {
                        return found;
                    }
                }
            }
            return null;
        }

        onMounted(() => {
            initializeParentStack(props.current || null);

            isLogged.value = window.defaults.isLogged;
            loggedUsername.value = window.defaults.loggedUsername;

            if(currentCategories.value.length === 0) {
                goBack();
            }
        });

        return {
            currentCategory,
            currentCategories,
            selectCategory,
            goBack,
            goBackTop,
            parentStack,
            isLogged,
            loggedUsername,
        };
    },
};
</script>

<style scoped>
ul {
    list-style-type: none;
    padding: 0;
}

li {
    margin: 0.5rem 0;
}
</style>
