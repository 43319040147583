import Vue, {createApp, onMounted, provide} from 'vue';
import PrimeVue from 'primevue/config';
import Aura from '@primevue/themes/aura';
import store from './vuex/store';
import { createI18n } from 'vue-i18n';
// import 'primeflex/primeflex.css';
import AutoComplete from 'primevue/autocomplete';
import Accordion from 'primevue/accordion';
import AccordionHeader from 'primevue/accordionheader';
import AccordionContent from 'primevue/accordioncontent';
import AnimateOnScroll from 'primevue/animateonscroll';
import Avatar from 'primevue/avatar';
import AvatarGroup from 'primevue/avatargroup';
import Badge from 'primevue/badge';
import BlockUI from 'primevue/blockui';
import Button from 'primevue/button';
import ButtonGroup from 'primevue/buttongroup';
import Breadcrumb from 'primevue/breadcrumb';
import DatePicker from 'primevue/datepicker';
import Card from 'primevue/card';
import CascadeSelect from 'primevue/cascadeselect';
import Carousel from 'primevue/carousel';
import Checkbox from 'primevue/checkbox';
import Chip from 'primevue/chip';
import ColorPicker from 'primevue/colorpicker';
import Column from 'primevue/column';
import ColumnGroup from 'primevue/columngroup';
import ConfirmDialog from 'primevue/confirmdialog';
import ConfirmPopup from 'primevue/confirmpopup';
import ConfirmationService from 'primevue/confirmationservice';
import ContextMenu from 'primevue/contextmenu';
import DataTable from 'primevue/datatable';
import DataView from 'primevue/dataview';
import DeferredContent from 'primevue/deferredcontent';
import Dialog from 'primevue/dialog';
import DialogService from 'primevue/dialogservice'
import Divider from 'primevue/divider';
import Dock from 'primevue/dock';
import Select from 'primevue/select';
import DynamicDialog from 'primevue/dynamicdialog';
import Fieldset from 'primevue/fieldset';
import FileUpload from 'primevue/fileupload';
import FloatLabel from 'primevue/floatlabel';
import FocusTrap from 'primevue/focustrap';
import Galleria from 'primevue/galleria';
import IconField from 'primevue/iconfield';
import InputIcon from 'primevue/inputicon';
import Image from 'primevue/image';
import Inplace from 'primevue/inplace';
import InputGroup from 'primevue/inputgroup';
import InputGroupAddon from 'primevue/inputgroupaddon';
import InputMask from 'primevue/inputmask';
import InputNumber from 'primevue/inputnumber';
import InputOtp from 'primevue/inputotp';
import ToggleSwitch from 'primevue/toggleswitch';
import InputText from 'primevue/inputtext';
import Knob from 'primevue/knob';
import Listbox from 'primevue/listbox';
import MegaMenu from 'primevue/megamenu';
import Menu from 'primevue/menu';
import Menubar from 'primevue/menubar';
import Message from 'primevue/message';
import MeterGroup from 'primevue/metergroup';
import MultiSelect from 'primevue/multiselect';
import OrderList from 'primevue/orderlist';
import OrganizationChart from 'primevue/organizationchart';
import Popover from 'primevue/popover';
import Paginator from 'primevue/paginator';
import Panel from 'primevue/panel';
import PanelMenu from 'primevue/panelmenu';
import Password from 'primevue/password';
import PickList from 'primevue/picklist';
import ProgressBar from 'primevue/progressbar';
import ProgressSpinner from 'primevue/progressspinner';
import Rating from 'primevue/rating';
import RadioButton from 'primevue/radiobutton';
import Ripple from 'primevue/ripple';
import Row from 'primevue/row';
import SelectButton from 'primevue/selectbutton';
import ScrollPanel from 'primevue/scrollpanel';
import ScrollTop from 'primevue/scrolltop';
import Skeleton from 'primevue/skeleton';
import Slider from 'primevue/slider';
import Drawer from 'primevue/drawer';
import SpeedDial from 'primevue/speeddial';
import SplitButton from 'primevue/splitbutton';
import Splitter from 'primevue/splitter';
import SplitterPanel from 'primevue/splitterpanel';
import Stepper from 'primevue/stepper';
import StyleClass from 'primevue/styleclass';
import TieredMenu from 'primevue/tieredmenu';
import Textarea from 'primevue/textarea';
import Toast from 'primevue/toast';
import ToastService from 'primevue/toastservice';
import Toolbar from 'primevue/toolbar';
import TabPanel from 'primevue/tabpanel';
import Tag from 'primevue/tag';
import Terminal from 'primevue/terminal';
import Timeline from 'primevue/timeline';
import ToggleButton from 'primevue/togglebutton';
import Tooltip from 'primevue/tooltip';
import Tree from 'primevue/tree';
import TreeSelect from 'primevue/treeselect';
import TreeTable from 'primevue/treetable';
import VirtualScroller from 'primevue/virtualscroller';
import AppHeader from './component/front/AppHeader.vue';
import AppMenuTop from './component/front/AppMenuTop.vue';
import AppMenuPanel from './component/front/AppMenuPanel.vue';
import AppFooter from './component/front/AppFooter.vue';
import AppProductCarousel from './component/front/AppProductCarousel.vue';
import AppProductList from './component/front/AppProductList.vue';
import AppProductListItem from './component/front/AppProductListItem.vue';
import AppProductGridItem from './component/front/AppProductGridItem.vue';
import AppCartGiftCarousel from './component/front/AppCartGiftCarousel.vue';
import AppCartGiftCarouselItem from './component/front/AppCartGiftCarouselItem.vue';
import AppBlogList from './component/front/AppBlogList.vue';
import AppBlogCarousel from './component/front/AppBlogCarousel.vue';
import AppBlogGridItem from './component/front/AppBlogGridItem.vue';
import AppBlogFilter from './component/front/AppBlogFilter.vue';
import AppBreadcrumb from './component/front/AppBreadcrumb.vue';
import AppThemeSwitcher from './component/front/AppThemeSwitcher.vue';
import AppCartButton from './component/front/AppCartButton.vue';
import AppCartTable from './component/front/AppCartTable.vue';
import AppCartForm from './component/front/AppCartForm.vue';
import AppCartShipping from './component/front/AppCartShipping.vue';
import AppCartShippingPrice from './component/front/AppCartShippingPrice.vue';
import AppCartNavigation from './component/front/AppCartNavigation.vue';
import AppCartGift from './component/front/AppCartGift.vue';
import AppCartGiftMotivation from './component/front/AppCartGiftMotivation.vue';
import AppProductGallery from './component/front/AppProductGallery.vue';
import AppShippingTable from './component/front/AppShippingTable.vue';
import AppVoucherGift from './component/front/AppVoucherGift.vue';
import AppSignUpForm from './component/front/AppSignUpForm.vue';
import AppSignInForm from './component/front/AppSignInForm.vue';
import AppPurchaseTracker from './component/front/AppPurchaseTracker.vue';
import AppProductSetContains from './component/front/AppProductSetContains.vue';
import AppPurchaseTable from './component/front/AppPurchaseTable.vue';
import AppBanner from './component/front/AppBanner.vue';
import PurchaseTracker from './component/PurchaseTracker.vue';
import TrackerFinder from './component/TrackerFinder.vue';
import ShowMore from './component/ShowMore.vue';
import HeaderCart from './component/HeaderCart.vue';
import ProductFilter from './component/ProductFilter.vue';
import AppProductLanguageSwitcher from './component/front/AppProductLanguageSwitcher.vue';
import AppHeurekaReviewList from "./component/front/AppHeurekaReviewList.vue";
import AppHeurekaReviewItem from "./component/front/AppHeurekaReviewItem.vue";
import AppLazyImage from "./component/front/AppLazyImage.vue";
import AppVideo from "./component/front/AppVideo.vue";
import AppVote from './component/front/AppVote.vue';
import AppVoteChart from './component/front/AppVoteChart.vue';
import Watchdog from './component/Watchdog.vue';
import ClaimForm from './component/ClaimForm.vue';
import Remember from "./directive/Remember";
import FocusEmpty from "./directive/FocusEmpty";
import FocusNext from "./directive/FocusNext";
import Scroll from "./directive/Scroll";
// import Expendable from "./directive/Expendable";
import CountryChange from "./directive/CountryChange";
import ViesChecker from "./directive/ViesChecker";
import LazyLoad from './directive/LazyLoad';
import MobileDetect from "mobile-detect";
import ErrorBag from "./errorBag";
import OpenKeyboard from "./directive/OpenKeyboard";
import 'flag-icons/css/flag-icons.min.css';
import {RepositoryFactory} from "./repository/front/RepositoryFactory";
import AjaxAction from "./directive/AjaxAction";
import Repository from "./repository/Repository";
import 'animate.css';
import { VueClipboard } from '@soerenmartius/vue3-clipboard'
import {definePreset} from "@primevue/themes";

const FavoriteRepository = RepositoryFactory.get('favorite');

// const { defineField, handleSubmit, errors } = useForm({
    // validationSchema: {
    //     field: required,
    // },
// });

const app = createApp({
    setup() {
        provide('scrollTo', scrollTo);
    },

    methods: {
        scrollTo (elementSelector) {
            document.querySelector(elementSelector).scrollIntoView({ behavior: 'smooth' });
        },

        validateBeforeSubmit () {
            // return handleSubmit(values => {
            //     // Submit to API
            // });
        },

        saveFavoriteProductsToDatabase () {
            const STORAGE_FAVORITE_PRODUCT = 'product.favorites';
            let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE_PRODUCT)) || [];

            _.forEach(favorites, (productId) => {
                FavoriteRepository.addProduct(productId);
            });
        },

        saveFavoriteBlogsToDatabase () {
            const STORAGE_FAVORITE_BLOG = 'blog.favorites';
            let favorites = JSON.parse(localStorage.getItem(STORAGE_FAVORITE_BLOG)) || [];

            _.forEach(favorites, (isFavorite, blogId) => {
                FavoriteRepository.addBlog(blogId);
            });
        },

        clearAllUserData() {
            var localStorageData = {};
            for (var i = 0; i < localStorage.length; i++) {
                var key = localStorage.key(i);
                var value = localStorage.getItem(key);
                localStorageData[key] = value;
            }

            let payload = new FormData;
            payload.append('localStorage', JSON.stringify(localStorageData));

            Repository.post('api/clear', payload)
                .then(function (response) {
                    localStorage.clear();

                    setTimeout(function() {
                        window.location.href = '/';
                    }, 3000);
                })
                .catch(function (error) {
                    // Handle errors
                });
        },

        focusTo() {},
        filledCompany() {},
        filledInvoice() {},
    },

    computed: {
        isMobile: function () {
            let md = new MobileDetect(window.navigator.userAgent);
            return md.mobile();
        },
    },

    mounted() {
        if (window.defaults.isLogged) {
            this.saveFavoriteProductsToDatabase();
            this.saveFavoriteBlogsToDatabase();
        }

        if (window.location.pathname === '/obnova') {
            this.clearAllUserData();
        }
    },
});

app.config.globalProperties.$errors = new ErrorBag();

const NoezonPreset = definePreset(Aura, {
    primitive: {
        borderRadius: {
            none: "0",
            xs: "2px",
            sm: "4px",
            md: "6px",
            lg: "8px",
            xl: "12px"
        },
        emerald: {
            50: "#ecfdf5",
            100: "#d1fae5",
            200: "#a7f3d0",
            300: "#6ee7b7",
            400: "#34d399",
            500: "#10b981",
            600: "#059669",
            700: "#047857",
            800: "#065f46",
            900: "#064e3b",
            950: "#022c22"
        },
        green: {
            50: "#f0fdf4",
            100: "#dcfce7",
            200: "#bbf7d0",
            300: "#86efac",
            400: "#4ade80",
            500: "#22c55e",
            600: "#16a34a",
            700: "#15803d",
            800: "#166534",
            900: "#14532d",
            950: "#052e16"
        },
        lime: {
            50: "#f7fee7",
            100: "#ecfccb",
            200: "#d9f99d",
            300: "#bef264",
            400: "#a3e635",
            500: "#84cc16",
            600: "#65a30d",
            700: "#4d7c0f",
            800: "#3f6212",
            900: "#365314",
            950: "#1a2e05"
        },
        red: {
            50: "#fef2f2",
            100: "#fee2e2",
            200: "#fecaca",
            300: "#fca5a5",
            400: "#f87171",
            500: "#ef4444",
            600: "#dc2626",
            700: "#b91c1c",
            800: "#991b1b",
            900: "#7f1d1d",
            950: "#450a0a"
        },
        orange: {
            50: "#fff7ed",
            100: "#ffedd5",
            200: "#fed7aa",
            300: "#fdba74",
            400: "#fb923c",
            500: "#f97316",
            600: "#ea580c",
            700: "#c2410c",
            800: "#9a3412",
            900: "#7c2d12",
            950: "#431407"
        },
        amber: {
            50: "#fffbeb",
            100: "#fef3c7",
            200: "#fde68a",
            300: "#fcd34d",
            400: "#fbbf24",
            500: "#f59e0b",
            600: "#d97706",
            700: "#b45309",
            800: "#92400e",
            900: "#78350f",
            950: "#451a03"
        },
        yellow: {
            50: "#fefce8",
            100: "#fef9c3",
            200: "#fef08a",
            300: "#fde047",
            400: "#facc15",
            500: "#eab308",
            600: "#ca8a04",
            700: "#a16207",
            800: "#854d0e",
            900: "#713f12",
            950: "#422006"
        },
        teal: {
            50: "#f0fdfa",
            100: "#ccfbf1",
            200: "#99f6e4",
            300: "#5eead4",
            400: "#2dd4bf",
            500: "#14b8a6",
            600: "#0d9488",
            700: "#0f766e",
            800: "#115e59",
            900: "#134e4a",
            950: "#042f2e"
        },
        cyan: {
            50: "#ecfeff",
            100: "#cffafe",
            200: "#a5f3fc",
            300: "#67e8f9",
            400: "#22d3ee",
            500: "#06b6d4",
            600: "#0891b2",
            700: "#0e7490",
            800: "#155e75",
            900: "#164e63",
            950: "#083344"
        },
        sky: {
            50: "#f0f9ff",
            100: "#e0f2fe",
            200: "#bae6fd",
            300: "#7dd3fc",
            400: "#38bdf8",
            500: "#0ea5e9",
            600: "#0284c7",
            700: "#0369a1",
            800: "#075985",
            900: "#0c4a6e",
            950: "#082f49"
        },
        blue: {
            50: "#eff6ff",
            100: "#dbeafe",
            200: "#bfdbfe",
            300: "#93c5fd",
            400: "#60a5fa",
            500: "#3b82f6",
            600: "#2563eb",
            700: "#1d4ed8",
            800: "#1e40af",
            900: "#1e3a8a",
            950: "#172554"
        },
        indigo: {
            50: "#eef2ff",
            100: "#e0e7ff",
            200: "#c7d2fe",
            300: "#a5b4fc",
            400: "#818cf8",
            500: "#6366f1",
            600: "#4f46e5",
            700: "#4338ca",
            800: "#3730a3",
            900: "#312e81",
            950: "#1e1b4b"
        },
        violet: {
            50: "#f5f3ff",
            100: "#ede9fe",
            200: "#ddd6fe",
            300: "#c4b5fd",
            400: "#a78bfa",
            500: "#8b5cf6",
            600: "#7c3aed",
            700: "#6d28d9",
            800: "#5b21b6",
            900: "#4c1d95",
            950: "#2e1065"
        },
        purple: {
            50: "#faf5ff",
            100: "#f3e8ff",
            200: "#e9d5ff",
            300: "#d8b4fe",
            400: "#c084fc",
            500: "#a855f7",
            600: "#9333ea",
            700: "#7e22ce",
            800: "#6b21a8",
            900: "#581c87",
            950: "#3b0764"
        },
        fuchsia: {
            50: "#fdf4ff",
            100: "#fae8ff",
            200: "#f5d0fe",
            300: "#f0abfc",
            400: "#e879f9",
            500: "#d946ef",
            600: "#c026d3",
            700: "#a21caf",
            800: "#86198f",
            900: "#701a75",
            950: "#4a044e"
        },
        pink: {
            50: "#fdf2f8",
            100: "#fce7f3",
            200: "#fbcfe8",
            300: "#f9a8d4",
            400: "#f472b6",
            500: "#ec4899",
            600: "#db2777",
            700: "#be185d",
            800: "#9d174d",
            900: "#831843",
            950: "#500724"
        },
        rose: {
            50: "#fff1f2",
            100: "#ffe4e6",
            200: "#fecdd3",
            300: "#fda4af",
            400: "#fb7185",
            500: "#f43f5e",
            600: "#e11d48",
            700: "#be123c",
            800: "#9f1239",
            900: "#881337",
            950: "#4c0519"
        },
        slate: {
            50: "#f8fafc",
            100: "#f1f5f9",
            200: "#e2e8f0",
            300: "#cbd5e1",
            400: "#94a3b8",
            500: "#64748b",
            600: "#475569",
            700: "#334155",
            800: "#1e293b",
            900: "#0f172a",
            950: "#020617"
        },
        gray: {
            50: "#f9fafb",
            100: "#f3f4f6",
            200: "#e5e7eb",
            300: "#d1d5db",
            400: "#9ca3af",
            500: "#6b7280",
            600: "#4b5563",
            700: "#374151",
            800: "#1f2937",
            900: "#111827",
            950: "#030712"
        },
        zinc: {
            50: "#fafafa",
            100: "#f4f4f5",
            200: "#e4e4e7",
            300: "#d4d4d8",
            400: "#a1a1aa",
            500: "#71717a",
            600: "#52525b",
            700: "#3f3f46",
            800: "#27272a",
            900: "#18181b",
            950: "#09090b"
        },
        neutral: {
            50: "#fafafa",
            100: "#f5f5f5",
            200: "#e5e5e5",
            300: "#d4d4d4",
            400: "#a3a3a3",
            500: "#737373",
            600: "#525252",
            700: "#404040",
            800: "#262626",
            900: "#171717",
            950: "#0a0a0a"
        },
        stone: {
            50: "#fafaf9",
            100: "#f5f5f4",
            200: "#e7e5e4",
            300: "#d6d3d1",
            400: "#a8a29e",
            500: "#78716c",
            600: "#57534e",
            700: "#44403c",
            800: "#292524",
            900: "#1c1917",
            950: "#0c0a09"
        }
    },
    semantic: {
        transitionDuration: "0.2s",
        focusRing: {
            width: "1px",
            style: "solid",
            color: "{primary.color}",
            offset: "2px",
            shadow: "none"
        },
        disabledOpacity: "0.6",
        iconSize: "1rem",
        anchorGutter: "2px",
        primary: {
            50: "#fef5f5",
            100: "#f9d0d0",
            200: "#f5aaaa",
            300: "#f18585",
            400: "#ec5f5f",
            500: "#e83a3a",
            600: "#c53131",
            700: "#a22929",
            800: "#802020",
            900: "#5d1717",
            950: "#3a0f0f"
        },
        formField: {
            paddingX: "0.75rem",
            paddingY: "0.5rem",
            sm: {
                fontSize: "0.875rem",
                paddingX: "0.625rem",
                paddingY: "0.375rem"
            },
            lg: {
                fontSize: "1.125rem",
                paddingX: "0.875rem",
                paddingY: "0.625rem"
            },
            borderRadius: "{border.radius.md}",
            focusRing: {
                width: "0",
                style: "none",
                color: "transparent",
                offset: "0",
                shadow: "none"
            },
            transitionDuration: "{transition.duration}"
        },
        list: {
            padding: "0.25rem 0.25rem",
            gap: "2px",
            header: {
                padding: "0.5rem 1rem 0.25rem 1rem"
            },
            option: {
                padding: "0.5rem 0.75rem",
                borderRadius: "{border.radius.sm}"
            },
            optionGroup: {
                padding: "0.5rem 0.75rem",
                fontWeight: "600"
            }
        },
        content: {
            borderRadius: "{border.radius.md}"
        },
        mask: {
            transitionDuration: "0.15s"
        },
        navigation: {
            list: {
                padding: "0.25rem 0.25rem",
                gap: "2px"
            },
            item: {
                padding: "0.5rem 0.75rem",
                borderRadius: "{border.radius.sm}",
                gap: "0.5rem"
            },
            submenuLabel: {
                padding: "0.5rem 0.75rem",
                fontWeight: "600"
            },
            submenuIcon: {
                size: "0.875rem"
            }
        },
        overlay: {
            select: {
                borderRadius: "{border.radius.md}",
                shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
            },
            popover: {
                borderRadius: "{border.radius.md}",
                padding: "0.75rem",
                shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
            },
            modal: {
                borderRadius: "{border.radius.xl}",
                padding: "1.25rem",
                shadow: "0 20px 25px -5px rgba(0, 0, 0, 0.1), 0 8px 10px -6px rgba(0, 0, 0, 0.1)"
            },
            navigation: {
                shadow: "0 4px 6px -1px rgba(0, 0, 0, 0.1), 0 2px 4px -2px rgba(0, 0, 0, 0.1)"
            }
        },
        colorScheme: {
            light: {
                surface: {
                    0: "#ffffff",
                    50: "#f8fafc",
                    100: "#f1f5f9",
                    200: "#e2e8f0",
                    300: "#cbd5e1",
                    400: "#94a3b8",
                    500: "#64748b",
                    600: "#475569",
                    700: "#334155",
                    800: "#1e293b",
                    900: "#0f172a",
                    950: "#020617"
                },
                primary: {
                    color: "{primary.500}",
                    contrastColor: "#ffffff",
                    hoverColor: "{primary.600}",
                    activeColor: "{primary.700}"
                },
                highlight: {
                    background: "{primary.50}",
                    focusBackground: "{primary.100}",
                    color: "{primary.700}",
                    focusColor: "{primary.800}"
                },
                mask: {
                    background: "rgba(0,0,0,0.4)",
                    color: "{surface.200}"
                },
                formField: {
                    background: "{surface.0}",
                    disabledBackground: "{surface.200}",
                    filledBackground: "{surface.50}",
                    filledHoverBackground: "{surface.50}",
                    filledFocusBackground: "{surface.50}",
                    borderColor: "{surface.300}",
                    hoverBorderColor: "{surface.400}",
                    focusBorderColor: "{primary.color}",
                    invalidBorderColor: "{red.400}",
                    color: "{surface.700}",
                    disabledColor: "{surface.500}",
                    placeholderColor: "{surface.500}",
                    invalidPlaceholderColor: "{red.600}",
                    floatLabelColor: "{surface.500}",
                    floatLabelFocusColor: "{primary.600}",
                    floatLabelActiveColor: "{surface.500}",
                    floatLabelInvalidColor: "{form.field.invalid.placeholder.color}",
                    iconColor: "{surface.400}",
                    shadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05)"
                },
                text: {
                    color: "{surface.700}",
                    hoverColor: "{surface.800}",
                    mutedColor: "{surface.500}",
                    hoverMutedColor: "{surface.600}"
                },
                content: {
                    background: "{surface.0}",
                    hoverBackground: "{surface.100}",
                    borderColor: "{surface.200}",
                    color: "{text.color}",
                    hoverColor: "{text.hover.color}"
                },
                overlay: {
                    select: {
                        background: "{surface.0}",
                        borderColor: "{surface.200}",
                        color: "{text.color}"
                    },
                    popover: {
                        background: "{surface.0}",
                        borderColor: "{surface.200}",
                        color: "{text.color}"
                    },
                    modal: {
                        background: "{surface.0}",
                        borderColor: "{surface.200}",
                        color: "{text.color}"
                    }
                },
                list: {
                    option: {
                        focusBackground: "{surface.100}",
                        selectedBackground: "{highlight.background}",
                        selectedFocusBackground: "{highlight.focus.background}",
                        color: "{text.color}",
                        focusColor: "{text.hover.color}",
                        selectedColor: "{highlight.color}",
                        selectedFocusColor: "{highlight.focus.color}",
                        icon: {
                            color: "{surface.400}",
                            focusColor: "{surface.500}"
                        }
                    },
                    optionGroup: {
                        background: "transparent",
                        color: "{text.muted.color}"
                    }
                },
                navigation: {
                    item: {
                        focusBackground: "{surface.100}",
                        activeBackground: "{surface.100}",
                        color: "{text.color}",
                        focusColor: "{text.hover.color}",
                        activeColor: "{text.hover.color}",
                        icon: {
                            color: "{surface.400}",
                            focusColor: "{surface.500}",
                            activeColor: "{surface.500}"
                        }
                    },
                    submenuLabel: {
                        background: "transparent",
                        color: "{text.muted.color}"
                    },
                    submenuIcon: {
                        color: "{surface.400}",
                        focusColor: "{surface.500}",
                        activeColor: "{surface.500}"
                    }
                }
            },
            dark: {
                surface: {
                    0: "#ffffff",
                    50: "#fafafa",
                    100: "#f4f4f5",
                    200: "#e4e4e7",
                    300: "#d4d4d8",
                    400: "#a1a1aa",
                    500: "#71717a",
                    600: "#52525b",
                    700: "#3f3f46",
                    800: "#27272a",
                    900: "#18181b",
                    950: "#09090b"
                },
                primary: {
                    color: "{primary.400}",
                    contrastColor: "{surface.900}",
                    hoverColor: "{primary.300}",
                    activeColor: "{primary.200}"
                },
                highlight: {
                    background: "color-mix(in srgb, {primary.400}, transparent 84%)",
                    focusBackground: "color-mix(in srgb, {primary.400}, transparent 76%)",
                    color: "rgba(255,255,255,.87)",
                    focusColor: "rgba(255,255,255,.87)"
                },
                mask: {
                    background: "rgba(0,0,0,0.6)",
                    color: "{surface.200}"
                },
                formField: {
                    background: "{surface.950}",
                    disabledBackground: "{surface.700}",
                    filledBackground: "{surface.800}",
                    filledHoverBackground: "{surface.800}",
                    filledFocusBackground: "{surface.800}",
                    borderColor: "{surface.600}",
                    hoverBorderColor: "{surface.500}",
                    focusBorderColor: "{primary.color}",
                    invalidBorderColor: "{red.300}",
                    color: "{surface.0}",
                    disabledColor: "{surface.400}",
                    placeholderColor: "{surface.400}",
                    invalidPlaceholderColor: "{red.400}",
                    floatLabelColor: "{surface.400}",
                    floatLabelFocusColor: "{primary.color}",
                    floatLabelActiveColor: "{surface.400}",
                    floatLabelInvalidColor: "{form.field.invalid.placeholder.color}",
                    iconColor: "{surface.400}",
                    shadow: "0 0 #0000, 0 0 #0000, 0 1px 2px 0 rgba(18, 18, 23, 0.05)"
                },
                text: {
                    color: "{surface.0}",
                    hoverColor: "{surface.0}",
                    mutedColor: "{surface.400}",
                    hoverMutedColor: "{surface.300}"
                },
                content: {
                    background: "{surface.900}",
                    hoverBackground: "{surface.800}",
                    borderColor: "{surface.700}",
                    color: "{text.color}",
                    hoverColor: "{text.hover.color}"
                },
                overlay: {
                    select: {
                        background: "{surface.900}",
                        borderColor: "{surface.700}",
                        color: "{text.color}"
                    },
                    popover: {
                        background: "{surface.900}",
                        borderColor: "{surface.700}",
                        color: "{text.color}"
                    },
                    modal: {
                        background: "{surface.900}",
                        borderColor: "{surface.700}",
                        color: "{text.color}"
                    }
                },
                list: {
                    option: {
                        focusBackground: "{surface.800}",
                        selectedBackground: "{highlight.background}",
                        selectedFocusBackground: "{highlight.focus.background}",
                        color: "{text.color}",
                        focusColor: "{text.hover.color}",
                        selectedColor: "{highlight.color}",
                        selectedFocusColor: "{highlight.focus.color}",
                        icon: {
                            color: "{surface.500}",
                            focusColor: "{surface.400}"
                        }
                    },
                    optionGroup: {
                        background: "transparent",
                        color: "{text.muted.color}"
                    }
                },
                navigation: {
                    item: {
                        focusBackground: "{surface.800}",
                        activeBackground: "{surface.800}",
                        color: "{text.color}",
                        focusColor: "{text.hover.color}",
                        activeColor: "{text.hover.color}",
                        icon: {
                            color: "{surface.500}",
                            focusColor: "{surface.400}",
                            activeColor: "{surface.400}"
                        }
                    },
                    submenuLabel: {
                        background: "transparent",
                        color: "{text.muted.color}"
                    },
                    submenuIcon: {
                        color: "{surface.500}",
                        focusColor: "{surface.400}",
                        activeColor: "{surface.400}"
                    }
                }
            }
        }
    }
});

app.use(PrimeVue, {
    // ripple: true
    theme: {
        preset: NoezonPreset,
        options: {
            prefix: 'p',
            darkModeSelector: false || 'none',
            cssLayer: {
                name: 'primevue',
                order: 'tailwind-base, primevue, tailwind-utilities'
            }
        }
    }
});



app.use(ConfirmationService);
app.use(ToastService);

app.use(store);

const i18n = createI18n({
    locale: window.defaults.locale.current,
    messages: {
        sk: require('./locales/sk.json'),
        cs: require('./locales/cs.json'),
        en: require('./locales/en.json'),
        de: require('./locales/de.json')
    }
});

app.use(i18n);
app.use(VueClipboard);

app.directive('tooltip', Tooltip);
app.directive('ripple', Ripple);
app.directive('styleclass', StyleClass);
app.directive('focustrap', FocusTrap);
app.directive('animateonscroll', AnimateOnScroll);
app.directive('remember', Remember);
app.directive('focus-empty', FocusEmpty);
// app.directive('expendable', Expendable);
app.directive('country-change', CountryChange);
app.directive('vies-checker', ViesChecker);
app.directive('focus-next', FocusNext);
app.directive('scroll', Scroll);
app.directive('lazy-load', LazyLoad);
app.directive('open-keyboard', OpenKeyboard);
app.directive('ajax-action', AjaxAction);

app.component('Accordion', Accordion);
app.component('AccordionHeader', AccordionHeader);
app.component('AccordionContent', AccordionContent);
app.component('AutoComplete', AutoComplete);
app.component('Avatar', Avatar);
app.component('AvatarGroup', AvatarGroup);
app.component('Badge', Badge);
app.component('BlockUI', BlockUI);
app.component('Breadcrumb', Breadcrumb);
app.component('Button', Button);
app.component('p-button', Button);
app.component('ButtonGroup', ButtonGroup);
app.component('DatePicker', DatePicker);
app.component('Card', Card);
// app.component('Carousel', Carousel);
app.component('CascadeSelect', CascadeSelect);
app.component('Checkbox', Checkbox);
app.component('Chip', Chip);
app.component('ColorPicker', ColorPicker);
app.component('Column', Column);
app.component('ColumnGroup', ColumnGroup);
app.component('ConfirmDialog', ConfirmDialog);
app.component('ConfirmPopup', ConfirmPopup);
app.component('ContextMenu', ContextMenu);
app.component('DataTable', DataTable);
app.component('DataView', DataView);
app.component('DeferredContent', DeferredContent);
app.component('Dialog', Dialog);
app.component('Divider', Divider);
app.component('Dock', Dock);
app.component('Select', Select);
app.component('DynamicDialog', DynamicDialog);
app.component('Fieldset', Fieldset);
app.component('FileUpload', FileUpload);
app.component('FloatLabel', FloatLabel);
app.component('Galleria', Galleria);
app.component('IconField', IconField);
app.component('Image', Image);
app.component('Inplace', Inplace);
app.component('InputGroup', InputGroup);
app.component('InputGroupAddon', InputGroupAddon);
app.component('InputIcon', InputIcon);
app.component('InputMask', InputMask);
app.component('InputNumber', InputNumber);
app.component('InputOtp', InputOtp);
app.component('ToggleSwitch', ToggleSwitch);
app.component('InputText', InputText);
app.component('Knob', Knob);
app.component('Listbox', Listbox);
app.component('MegaMenu', MegaMenu);
app.component('Menu', Menu);
app.component('Menubar', Menubar);
app.component('Message', Message);
app.component('MeterGroup', MeterGroup);
app.component('MultiSelect', MultiSelect);
app.component('OrderList', OrderList);
app.component('OrganizationChart', OrganizationChart);
app.component('Popover', Popover);
app.component('Paginator', Paginator);
app.component('Panel', Panel);
app.component('PanelMenu', PanelMenu);
app.component('Password', Password);
app.component('PickList', PickList);
app.component('ProgressBar', ProgressBar);
app.component('ProgressSpinner', ProgressSpinner);
app.component('RadioButton', RadioButton);
app.component('Rating', Rating);
app.component('Row', Row);
app.component('SelectButton', SelectButton);
app.component('ScrollPanel', ScrollPanel);
app.component('ScrollTop', ScrollTop);
app.component('Slider', Slider);
app.component('Drawer', Drawer);
app.component('Skeleton', Skeleton);
app.component('SpeedDial', SpeedDial);
app.component('SplitButton', SplitButton);
app.component('Splitter', Splitter);
app.component('SplitterPanel', SplitterPanel);
app.component('Stepper', Stepper);
app.component('TabPanel', TabPanel);
app.component('Tag', Tag);
app.component('Textarea', Textarea);
app.component('Terminal', Terminal);
app.component('TieredMenu', TieredMenu);
app.component('Timeline', Timeline);
app.component('Toast', Toast);
app.component('Toolbar', Toolbar);
app.component('ToggleButton', ToggleButton);
app.component('Tree', Tree);
app.component('TreeSelect', TreeSelect);
app.component('TreeTable', TreeTable);
app.component('VirtualScroller', VirtualScroller);
app.component('app-header', AppHeader);
app.component('app-menu-top', AppMenuTop);
app.component('app-menu-panel', AppMenuPanel);
app.component('app-footer', AppFooter);
app.component('app-product-carousel', AppProductCarousel);
app.component('app-product-list', AppProductList);
app.component('app-product-list-item', AppProductListItem);
app.component('app-product-grid-item', AppProductGridItem);
app.component('app-cart-gift-carousel', AppCartGiftCarousel);
app.component('app-cart-gift-carousel-item', AppCartGiftCarouselItem);
app.component('app-blog-list', AppBlogList);
app.component('app-blog-carousel', AppBlogCarousel);
app.component('app-blog-grid-item', AppBlogGridItem);
app.component('app-blog-filter', AppBlogFilter);
app.component('app-breadcrumb', AppBreadcrumb);
app.component('app-theme-switcher', AppThemeSwitcher);
app.component('header-cart', HeaderCart);
app.component('product-filter', ProductFilter);
app.component('app-cart-button', AppCartButton);
app.component('app-cart-table', AppCartTable);
app.component('app-cart-form', AppCartForm);
app.component('app-cart-shipping', AppCartShipping);
app.component('app-cart-shipping-price', AppCartShippingPrice);
app.component('app-cart-navigation', AppCartNavigation);
app.component('app-cart-gift', AppCartGift);
app.component('app-cart-gift-motivation', AppCartGiftMotivation);
app.component('app-product-gallery', AppProductGallery);
app.component('app-shipping-table', AppShippingTable);
app.component('app-voucher-gift', AppVoucherGift);
app.component('app-signup-form', AppSignUpForm);
app.component('app-signin-form', AppSignInForm);
app.component('app-purchase-table', AppPurchaseTable);
app.component('app-banner', AppBanner);
app.component('app-purchase-tracker', AppPurchaseTracker);
app.component('app-product-set-contains', AppProductSetContains);
app.component('app-lazy-image', AppLazyImage);
app.component('purchase-tracker', PurchaseTracker);
app.component('tracker-finder', TrackerFinder);
app.component('show-more', ShowMore);
app.component('app-product-language-switcher', AppProductLanguageSwitcher);
app.component('app-watchdog', Watchdog);
app.component('app-heureka-review-list', AppHeurekaReviewList);
app.component('app-heureka-review-item', AppHeurekaReviewItem);
app.component('app-video', AppVideo);
app.component('app-vote', AppVote);
app.component('app-vote-chart', AppVoteChart);
app.component('claim-form', ClaimForm);
app.component('copy-button', require('./component/CopyButton.vue').default);
app.component('payment-qr-button', require('./component/PaymentQRButton.vue').default);

// app.config.errorHandler = (err, vm, info) => {
//     window.dataLayer.push({
//         'event': 'js_error',
//         'error': err,
//     });
// };

app.mount('#app');

window.onerror = function(message, source, lineno, colno, error) {
    window.dataLayer.push({
        'event': 'js_error',
        'error': message,
    });
}

window.addEventListener('unhandledrejection', function(event) {
    window.dataLayer.push({
        'event': 'js_error',
        'error': event.reason.message,
    });
});

function observeHashNavigation() {
    const id = location.hash.substring(1);
    if (!id) return;

    const observer = new MutationObserver(() => {
        const target = document.getElementById(id);
        if (target) {
            target.scrollIntoView();
            observer.disconnect(); // Zastavíme sledovanie
        }
    });

    observer.observe(document.body, { childList: true, subtree: true });
}

document.addEventListener("DOMContentLoaded", observeHashNavigation);